import './EditDepartmentPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { Department } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import { useFirebase } from '../../../components/FirebaseProvider'
import ICTab from '../../../components/departments/ICTab'

function EditDepartmentPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [deparment, setDepartment] = useState<Department>()
    const [currentIcdls, setCurrentIcdls] = useState<number[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !deparment || deparment.id === 0
    }

    const getTabNames = (): string[] => {
        return [
            "Inventory Categories"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <ICTab icdls={currentIcdls} onChange={onIcdlChange} showSkeleton={showSkeleton} />
        ]
    }

    const onIcdlChange = (ids: number[]) => {
        setCurrentIcdls(ids)
        setIsDirty(true)
    }

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const id = parseInt(params.id!, 10)
                const resp = await ersysDataApiServices.departmentsService.departmentsGet(id)
                setDepartment(resp.data)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting department data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setDepartment, ersysDataApiServices.departmentsService, params.id, isReady])

    useEffect(() => {
        setCurrentIcdls(deparment?.ics ?? [])
        setIsDirty(false)
    }, [deparment, setCurrentIcdls])

    const Save = async () => {
        setLoading(true)
        try {
            const id = parseInt(params.id!, 10)
            const resp = await ersysDataApiServices.departmentsService.departmentsSetIcs(id, {ics: currentIcdls})
            setDepartment(resp.data)
            setIsDirty(false)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while updating department. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Department">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Department: ${deparment?.name ?? ""}`}>
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!isDirty}
                        onClick={() =>  Save()}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditDepartmentPage