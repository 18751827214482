import { Button, Container, FormGroup, Paper } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { ReceiptSharp } from "@mui/icons-material"
import { PostTransactionRequest } from "../../apis/ersys-data"
import { useForm } from "react-hook-form"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputNumberIntToDollars from "../forms/FormInputNumberIntToDollars"
import FormInputText from "../forms/FormInputText"

const formDefaults: PostTransactionRequest = {
    accountId: 0,
    memo: "",
    paymentType: "",
    value: 0
}

const AdministrativeToolsAddTransactionTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [formSpacing] = useState(2)
    const { handleSubmit, control, reset} =
        useForm<PostTransactionRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const postTransaction = async (req: PostTransactionRequest) => {
        setLoading(true)
        try {
            req.accountId = parseInt(req.accountId.toString(), 10)
            req.value = parseInt(req.value.toString(), 10)
            await ersysDataApiServices.bulkService.bulkPostTransaction(req)
            reset()
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while posting transaction. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const paymentTypes = (): FormInputDropdownOption[] => {
        return [
            {label: 'Select Payment Type', value: undefined},
            {label: 'Credit Card', value: 'cc'},
            {label: 'Check', value: 'check'},
            {label: 'Cash', value: 'cash'},
        ]
    }  

    return (
        <Container>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <FormGroup>
                        <FormInputNumber
                            name="accountId"
                            control={control}
                            label="Account ID"
                            spacing={formSpacing}
                            rules={{required: true, min: 1}}
                        />
                        <FormInputNumberIntToDollars
                            name="value"
                            control={control}
                            label="Value"
                            spacing={formSpacing}
                            rules={{required: true}}
                        />
                        <FormInputText
                            name="memo"
                            control={control}
                            label="Memo"
                            spacing={formSpacing}
                            rules={{maxLength: 100}}
                        />
                        <FormInputDropdown
                            name="paymentType"
                            control={control}
                            label="Payment Type"
                            spacing={formSpacing} 
                            options={paymentTypes()}
                            rules={{required: true}}                    
                        />
                        
                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        onClick={handleSubmit(postTransaction)}
                        endIcon={<ReceiptSharp />}
                    >
                        Post
                    </Button>
                </div>
            </Paper>            
        </Container>
    )
}

export default AdministrativeToolsAddTransactionTab