import './OrderForm.scss'
import AppMenu from '../../components/AppMenu'
import { Container, Skeleton, Step, StepButton, Stepper } from '@mui/material'
import { useDispatch, useSelector } from "react-redux"
import { clearData, setOrderStepIndex } from "../../features/order-workflow/Order"
import { RootState } from '../../app/store'
import CurrentStep from '../../components/order-form/CurrentStep'
import { useFirebase } from '../../components/FirebaseProvider'
import { useEffect } from 'react'

function OrderForm() {
  const dispatch = useDispatch()
  const currentIndex = useSelector((state: RootState) => state.order.currentIndex)
  const stepNames = useSelector((state: RootState) => state.order.stepsNames)
  const { isReady } = useFirebase()

  useEffect(() => {
    dispatch(clearData(true))
  }, [dispatch])

  if (!isReady) {
    return <AppMenu title="Order Form">
      <Skeleton variant="rectangular" height={800} sx={{ width: '100%' }} />
    </AppMenu>
  }

  return (
    <AppMenu title='Order Form'>
      <Container maxWidth="sm">
        <Stepper activeStep={currentIndex} alternativeLabel>

          {stepNames.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton
                  onClick={() =>
                    dispatch(setOrderStepIndex(index))
                  }
                >
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>

      </Container>
      <CurrentStep index={currentIndex} />
    </AppMenu>
  )
}

export default OrderForm
