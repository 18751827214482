import './DriversDashboardPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Container, Skeleton } from '@mui/material'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { useFirebase } from '../../../components/FirebaseProvider'
import DriversRouteStopTab from '../../../components/drivers/DriversRouteStopTab'

function DriversDashboardPage() {
    const { isReady } = useFirebase()

    const getTabNames = (): string[] => {
        return [
            "Route Stops"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <DriversRouteStopTab />
        ]
    }

    if (!isReady) {
        return <AppMenu title="Drivers Dashboard">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title="Drivers Dashboard">
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
            </Container>
        </AppMenu>
    )
}

export default DriversDashboardPage