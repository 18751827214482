import { Box } from "@mui/material"

interface TabProps {
    children?: React.ReactNode
    index: number
    value: number
    name: string
}

const TabComponent: React.FC<TabProps> = ({ name, children, index, value }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${name}-tabpanel-${index}`}
            aria-labelledby={`${name}-tab-${index}`}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

export default TabComponent