import { Button, Container, FormGroup, MenuItem, Paper, styled, TextField, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { CloudUploadSharp, RotateLeftSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"
import { MinimalStore } from "../../apis/ersys-public"
import { useFirebase } from "../FirebaseProvider"
import { RollupRequest } from "../../apis/ersys-data"
import dayjs from "dayjs"
import { useForm, useFormState } from "react-hook-form"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"
import FormInputDatePicker from "../forms/FormInputDatePicker"

const formDefaults: RollupRequest = {
    endDate: dayjs().format(),
    startDate: dayjs().format(),
    storeId: 0
}

const AdministrativeToolsRollupAccountsTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [selectedStore, setSelectedStore] = useState<number>(0)
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [id, setId] = useState<string>("")
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<RollupRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty } = useFormState({ control })
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var storesResp = await ersysDataApiServices.storesService.storesList()
                setStores(storesResp.data.stores)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, ersysDataApiServices.storesService, isReady])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const downloadChecklist = async () => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkDownloadCreateAccountsTemplate({
                responseType: 'blob'
            })

            const csvBlob = new Blob([response.data], { type: "text/csv" })
            const url = window.URL.createObjectURL(csvBlob)

            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `create_accounts.csv`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const handleUpload = async (fs: FileList | null) => {
        if(!fs) {
            return
        }

        const files = Array.from(fs)
        if(files.length < 1) {
            return
        }

        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkBulkCreateAccounts(files[0], selectedStore.toString())
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while uploading data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }
    
    const storeOptions = (): FormInputDropdownOption[] => {
        if(!stores) {
            return []
        }
        return [
            {
                label: "Select Store",
                value: 0
            },
            ...stores.map(s => {
                return {
                    label: s.fullName,
                    value: s.id
                }
            })
        ]
    }

    const rollup = async (req: RollupRequest) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkRollupAccounts(req)
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while rolling up accounts. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal 
            id={id}  
            onClose={() => setShowWaitingModal(false)} 
            open={showWaitingModal} 
            title="Rolling up accounts"
            noCounts
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography>This action is irrevsersible. Please be careful.</Typography>
                <FormGroup>
                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"storeId"}
                            control={control}
                            label={"Store"}
                            options={storeOptions()}
                            rules={{ required: true, min: 1}}
                        />
                        <FormInputDatePicker 
                            name="startDate"
                            control={control} 
                            label="Start Date" 
                            spacing={formSpacing}                    
                        />
                        <FormInputDatePicker 
                            name="endDate"
                            control={control} 
                            label="End Date" 
                            spacing={formSpacing}                    
                        />
                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!isDirty}
                        onClick={handleSubmit(rollup)}
                        endIcon={<RotateLeftSharp />}
                    >
                        Rollup Accounts
                    </Button>
                </div>
            </Paper>            
        </Container>
    )
}

export default AdministrativeToolsRollupAccountsTab