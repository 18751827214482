import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { InventoryCategory } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import FormInputNumberIntToDollars from "../forms/FormInputNumberIntToDollars"

interface InventoryCategoryLinenTabProps {
    control: Control<InventoryCategory, any>
    showSkeleton: () => boolean
    formSpacing: number
}

const InventoryCategoryLinenTab: React.FC<InventoryCategoryLinenTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isRental"}
                            control={control}
                            label={"Is Rental"}
                        />
                        <FormInputNumberIntToDollars
                            spacing={formSpacing}
                            name="failureToReturnCost"
                            control={control}
                            label="Failure to Return Cost"
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default InventoryCategoryLinenTab