import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Skeleton, darken, Button, lighten, Grid } from '@mui/material'
import AppMenu from '../../components/AppMenu'
import { useEffect, useState } from 'react'
import { PrintQueueSortMethod, PrintQueueStore } from '../../apis/ersys-data'
import { showError } from '../../features/errors/Errors'
import { setIsLoading } from '../../features/menus/AppMenu'
import { useDispatch } from 'react-redux'
import { useErsysDataApi } from '../../components/ErsysDataApiProvider'
import { useFirebase } from '../../components/FirebaseProvider'
import NagModal from '../../components/NagModal'

function BagPrintQueue() {
    const ersysDataApiServices = useErsysDataApi()
    const [stores, setStores] = useState<PrintQueueStore[]>([])
    const [selectedStore, setSelectedStore] = useState<number>(0)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [deleteIsVisible, setDeleteIsVisible] = useState<boolean>(false)
    const [bypassDormCheck, setBypassDormCheck] = useState<boolean>(false)
    const [sortMethod, setSortMethod] = useState<PrintQueueSortMethod>(PrintQueueSortMethod.Name)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (!isReady) {
            return
        }
        const fetch = async () => {
            try {
                setIsLoaded(false)
                var resp = await ersysDataApiServices.bagsService.bagsListQueue()
                setStores(resp.data.stores)
                setIsLoaded(true)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting bag data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, setIsLoaded, ersysDataApiServices.bagsService, isReady])

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return stores.length < 1 && isLoaded
    }

    if (!isReady) {
        return <AppMenu title="Accounts">
            <Skeleton variant="rectangular" height={800} sx={{ width: '100%' }} />
        </AppMenu>
    }
    
    const handlePrint = async () => {
        setLoading(true)
        try {
            const resp = await ersysDataApiServices.bagsService.bagsPrintQueueRequest({
                sortMethod: sortMethod,
                storeId: selectedStore,
                overrideDormCheck: bypassDormCheck
            },{
                responseType: 'blob'
            })

            const pdfBlob = new Blob([resp.data], {type: "application/pdf"})
            const url = window.URL.createObjectURL(pdfBlob)
            
            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `baglabels_${selectedStore}`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)

            setDeleteIsVisible(true)
        }
        catch (ex: any) {
            if(ex.response && ex.response.status && ex.response.status === 404) {
                dispatch(
                    showError({
                        title: "No Labels to Print.",
                        message:
                            "Given your settings, none of the labels are printable.",
                    }),
                )
                return
            }
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while printing bags. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const onClear = async () => {
        setLoading(true)
        try {
            await ersysDataApiServices.bagsService.bagsClearQueue({
                sortMethod: sortMethod,
                storeId: selectedStore,
                overrideDormCheck: bypassDormCheck
            })
            setIsLoaded(false)
            var resp = await ersysDataApiServices.bagsService.bagsListQueue()
            setStores(resp.data.stores)
            setIsLoaded(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while clearing bags. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }

    }

    return (
        <AppMenu title="Bag Print Queue">
        <NagModal
            title='Clear the bags?'
            message="Check your file to ensure it's what you want, Press Ok to clear the bags from the queue, or cancel to leave them in the queue."
            onCancel={() => { setDeleteIsVisible(false) }}
            onOk={() => { 
                setDeleteIsVisible(false)
                onClear()
            }}
            open={deleteIsVisible}
        />
            <Container maxWidth="md">
                <Grid container>
                    <Grid item sm={6}>
                        <ButtonGroup variant="contained">
                            <Button
                                color="primary"
                                onClick={() => setSortMethod(PrintQueueSortMethod.Name)}
                                sx={{ ...(sortMethod !== PrintQueueSortMethod.Name) && { backgroundColor: (theme) => lighten(theme.palette.primary.main, .75) } }}
                            >Sort By Name</Button>
                            <Button
                                color="primary"
                                onClick={() => setSortMethod(PrintQueueSortMethod.Dorm)}
                                sx={{ ...(sortMethod !== PrintQueueSortMethod.Dorm) && { backgroundColor: (theme) => lighten(theme.palette.primary.main, .75) } }}
                            >Sort By Dorm</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item sm={6}>
                        <Grid container justifyContent="end">
                        <ButtonGroup variant="contained">
                            <Button
                                color={bypassDormCheck ? 'error' : 'success'}
                                onClick={() => setBypassDormCheck(!bypassDormCheck)}
                            >{bypassDormCheck ? 'Dorm Check is Disabled' : 'Only dormed bags will print'}</Button>
                        </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Faculty Bags</TableCell>
                                <TableCell>Student Bags</TableCell>
                                <TableCell>Total Bags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ? [...Array(6)].map((_, i) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                    <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                    <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Skeleton variant='circular' width={30} height={30} />
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )) :
                                stores.map((store) => (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(store.storeId === selectedStore) && { backgroundColor: darken("#FFF", .1) } }}
                                        key={store.storeId}
                                        onClick={() => setSelectedStore(store.storeId)}
                                    >
                                        <TableCell>{truncate(store.storeName, 100)}</TableCell>
                                        <TableCell>{store.facultyCount}</TableCell>
                                        <TableCell>{store.studentCount}</TableCell>
                                        <TableCell>{store.facultyCount + store.studentCount}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container sx={{ marginTop: 2}} justifyContent="end">
                    <ButtonGroup>
                        <Button color='primary' onClick={() => handlePrint()} disabled={selectedStore === 0}>Download</Button>
                    </ButtonGroup>
                </Grid>
            </Container>
        </AppMenu>
    )
}

export default BagPrintQueue