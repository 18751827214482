import { Button, ButtonGroup, Container, FormGroup } from "@mui/material"
import AppMenu from "../../components/AppMenu"
import { useForm } from "react-hook-form"
import FormInputText from "../../components/forms/FormInputText"
import { useErsysDataApi } from "../../components/ErsysDataApiProvider"
import { useState, useEffect } from "react"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"

interface ReprintBagForm {
    bagNumber: string
}

const defaults: ReprintBagForm = {
    bagNumber: ""
}

const BagReprint: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { handleSubmit, control } =
        useForm<ReprintBagForm>({
            defaultValues: defaults,
            reValidateMode: "onSubmit",
        })
    const ersysDataApiServices = useErsysDataApi()
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const handlePrint = async (form: ReprintBagForm) => {
        setLoading(true)
        try {
            const resp = await ersysDataApiServices.bagsService.bagsReprintBag(form.bagNumber, {
                responseType: 'blob'
            })

            const pdfBlob = new Blob([resp.data], {type: "application/pdf"})
            const url = window.URL.createObjectURL(pdfBlob)
            
            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `baglabel_${form.bagNumber}`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            if(ex.response && ex.response.status && ex.response.status === 404) {
                dispatch(
                    showError({
                        title: "Bag not found.",
                        message:
                            "Bag Number was not found.",
                    }),
                )
                return
            }
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while printing bag. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <AppMenu title='Bag Reprint'>
            <Container maxWidth="sm" sx={{ marginTop: 10 }}>
                <FormGroup>
                    <FormInputText
                        spacing={2}
                        name={"bagNumber"}
                        control={control}
                        label={"Bag Number"}
                        rules={{ required: true, }}
                    />
                    <ButtonGroup>
                        <Button onClick={handleSubmit(handlePrint)} autoFocus>Download</Button>
                    </ButtonGroup>
                </FormGroup>
            </Container>
        </AppMenu>
    )
}

export default BagReprint