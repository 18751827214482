import { Button, FormGroup, Grid, List, ListItem, Skeleton } from "@mui/material"
import { ChecklistEntry } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"

interface AccountChecklistTabProps {
    formSpacing: number
}

const AccountChecklistTab: React.FC<AccountChecklistTabProps> = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [checklistEntries, setChecklistEntries] = useState<ChecklistEntry[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.accountsService.accountsListChecklists(parseInt(params.id!, 10))
                setChecklistEntries(resp.data.checklists)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting checklist data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setChecklistEntries, ersysDataApiServices.accountsService, params.id])

    
    const formatCancelledDate = (d: string): string => {
        const date = dayjs(d)
        return date.format('MM/DD/YYYY')
    }
    
    const showSkeleton = () => {
        return checklistEntries.length < 1 && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <Skeleton variant='rectangular' width={200} height={100} key={`skele-${i}`} sx={{margin: 1}}/>
                ))}
            </FormGroup>
        )
    }

    const downloadChecklist = async (checklistId: number) => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            const response = await ersysDataApiServices.accountsService.accountsDownloadChecklist(accountId, checklistId, {
                responseType: 'blob'
            })

            const pdfBlob = new Blob([response.data], {type: "application/pdf"})
            const url = window.URL.createObjectURL(pdfBlob)
            
            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `checklist_${accountId}_${checklistId}`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting checklist data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            {showSkeleton() ? renderSkeleton() :
                <List sx={{height: 550, overflow: 'auto'}}>
                     {checklistEntries.map((checklist) => (
                        <ListItem key={checklist.id}>
                            <Button onClick={() => downloadChecklist(checklist.id??0)}>{formatCancelledDate(checklist.createdDate ?? "")}</Button>
                            {/* <a href={`${config.payrollApi.host}/api/v2/data/accounts/${parseInt(params.id!, 10)}/checklists/${checklist.id}/file`}>{formatCancelledDate(checklist.createdDate ?? "")}</a> */}
                        </ListItem>
                     ))}
                </List>
            }
        </Grid>
    )
}

export default AccountChecklistTab