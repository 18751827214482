import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import {
  Account,
  PaymentPlan,
  Proration,
  StorePlan,
  StorePlanAddon,
  OrderPlan,
} from "../../apis/ersys-public"

export interface OrderData {
  currentIndex: number
  stepsNames: string[]
  selectedAccount: Account | undefined
  selectedStore: number | undefined
  selectedStorePlan: StorePlan | undefined
  selectedAddons: StorePlanAddon[]
  selectedPaymentPlan: PaymentPlan | undefined
  accountNeedsCreation: boolean
  accountCreationType: string
  orderPlan: OrderPlan | undefined
  isUpgrade: boolean
  isRenewal: boolean
  proration: Proration
}

const initialState: OrderData = {
  currentIndex: 0,
  stepsNames: [
    "Select School",
    "Select Account",
    "Select Plan",
    "Select Extras",
    "Modify Customer",
    "Summary",
  ],
  selectedAccount: undefined,
  selectedStore: undefined,
  selectedStorePlan: undefined,
  selectedAddons: [],
  selectedPaymentPlan: undefined,
  accountNeedsCreation: false,
  accountCreationType: "",
  orderPlan: undefined,
  isUpgrade: false,
  isRenewal: false,
  proration: {
    cost: 100,
    id: -1,
  },
}

export const orderDataSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearData: (state, action: PayloadAction<boolean>) => {
      state.currentIndex = 0
    },
    setOrderStepIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload
    },
    setSelectedAccount: (state, account: PayloadAction<Account>) => {
      state.accountNeedsCreation = false
      state.selectedAccount = account.payload
    },
    setSelectedStore: (state, account: PayloadAction<number>) => {
      state.selectedStore = account.payload
    },
    setSelectedStorePlan: (state, plan: PayloadAction<StorePlan>) => {
      state.selectedStorePlan = plan.payload
    },
    setSelectedAddons: (state, addons: PayloadAction<StorePlanAddon[]>) => {
      state.selectedAddons = addons.payload
    },
    setSelectedPaymentPlan: (
      state,
      plan: PayloadAction<PaymentPlan | undefined>,
    ) => {
      state.selectedPaymentPlan = plan.payload
    },
    enableCreateAccount: (state, type: PayloadAction<string>) => {
      state.accountNeedsCreation = true
      state.selectedAccount = undefined
      state.accountCreationType = type.payload
    },
    setIsUpgrade: (state, isUpgrade: PayloadAction<boolean>) => {
      state.isUpgrade = isUpgrade.payload
    },
    setIsRenewal: (state, isRenewal: PayloadAction<boolean>) => {
      state.isRenewal = isRenewal.payload
    },
    setProration: (state, proration: PayloadAction<Proration>) => {
      state.proration = proration.payload
    },
    setOrderPlan(state, orderPlan: PayloadAction<OrderPlan>) {
      state.orderPlan = orderPlan.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  clearData,
  setOrderStepIndex,
  setSelectedAccount,
  setSelectedStore,
  setSelectedStorePlan,
  setSelectedAddons,
  setSelectedPaymentPlan,
  enableCreateAccount,
  setIsUpgrade,
  setIsRenewal,
  setProration,
  setOrderPlan,
} = orderDataSlice.actions

export default orderDataSlice.reducer
