// auth-service.jsx
import React from "react"
import axiosClient from "../apis/client"
import { config } from "../config"
import { useFirebase } from "./FirebaseProvider"
import { 
    AccountsApi,
    AccountsApiInterface,
    BagsApi,
    BagsApiInterface,
    Configuration, 
    GlobalPlansApi, 
    GlobalPlansApiInterface, 
    StoresApi, 
    StoresApiInterface, 
    UsersApi,
    UsersApiInterface,
    OrdersApi,
    OrdersApiInterface,
    InventoryCategoriesApiInterface,
    InventoryCategoriesApi,
    DepartmentsApiInterface,
    DepartmentsApi,
    DiscountsApiInterface,
    DiscountsApi,
    PaymentPlansApiInterface,
    PaymentPlansApi,
    BulkApiInterface,
    BulkApi,
} from "../apis/ersys-data"

const { createContext, useContext } = React

interface ErsysDataApiProviderProps {
    usersService?: UsersApiInterface
    storesService?: StoresApiInterface
    accountsService?: AccountsApiInterface
    globalPlansService?: GlobalPlansApiInterface
    bagsService?: BagsApiInterface
    ordersService?: OrdersApiInterface
    inventoryCategoryService?: InventoryCategoriesApiInterface
    departmentsService?: DepartmentsApiInterface
    discountService?: DiscountsApiInterface
    paymentPlansService?: PaymentPlansApiInterface
    bulkService?: BulkApiInterface
    children?: React.ReactNode
    host: string
    getToken:
    | string
    | Promise<string>
    | ((name?: string | undefined, scopes?: string[] | undefined) => string)
    | ((
        name?: string | undefined,
        scopes?: string[] | undefined
    ) => Promise<string>)
    | undefined
}

export interface ErsysDataApiServices {
    usersService: UsersApiInterface
    storesService: StoresApiInterface
    accountsService: AccountsApiInterface
    globalPlansService: GlobalPlansApiInterface
    bagsService: BagsApiInterface
    ordersService: OrdersApiInterface
    inventoryCategoryService: InventoryCategoriesApiInterface
    departmentsService: DepartmentsApiInterface
    discountService: DiscountsApiInterface
    paymentPlansService: PaymentPlansApiInterface
    bulkService: BulkApiInterface
}

const ErsysDataApiContext = createContext<ErsysDataApiServices>({} as any)

export const ErsysDataApiProvider: React.FC<ErsysDataApiProviderProps> = ({
    usersService,
    storesService,
    accountsService,
    globalPlansService,
    bagsService,
    ordersService,
    inventoryCategoryService,
    departmentsService,
    discountService,
    paymentPlansService,
    bulkService,
    children,
    host,
    getToken,
}) => {
    const config = new Configuration({
        basePath: host,
        accessToken: getToken,
    })
    const value: ErsysDataApiServices = {
        usersService: usersService || new UsersApi(config, undefined, axiosClient),
        storesService: storesService || new StoresApi(config, undefined, axiosClient),
        accountsService: accountsService || new AccountsApi(config, undefined, axiosClient),
        globalPlansService: globalPlansService || new GlobalPlansApi(config, undefined, axiosClient),
        bagsService: bagsService || new BagsApi(config, undefined, axiosClient),
        ordersService: ordersService || new OrdersApi(config, undefined, axiosClient),
        inventoryCategoryService: inventoryCategoryService || new InventoryCategoriesApi(config, undefined, axiosClient),
        departmentsService: departmentsService || new DepartmentsApi(config, undefined, axiosClient),
        discountService: discountService || new DiscountsApi(config, undefined, axiosClient),
        paymentPlansService: paymentPlansService || new PaymentPlansApi(config, undefined, axiosClient),
        bulkService: bulkService || new BulkApi(config, undefined, axiosClient),
    }

    return (
        <ErsysDataApiContext.Provider value={value}>
            {children}
        </ErsysDataApiContext.Provider>
    )
}

export const useErsysDataApi = () => {
    return useContext(ErsysDataApiContext)
}

interface ErsysDataApiProviderWrapperProps {
    children?: React.ReactNode;
}

export const ErsysDataApiProviderWrapper: React.FC<
    ErsysDataApiProviderWrapperProps
> = ({ children }) => {
    const { user } = useFirebase()

    return (
        <ErsysDataApiProvider
            getToken={() => {
                return user?.getIdToken() ?? Promise.resolve("")
            }}
            host={config.payrollApi.host}
        >
            {children}
        </ErsysDataApiProvider>
    )
}
