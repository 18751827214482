import { Button, Container, Paper, styled, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { CloudUploadSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"

const AdministrativeToolsUpdateStorePlansTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [id, setId] = useState<string>("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const downloadChecklist = async () => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkDownloadUpdateStorePlansTemplate({
                responseType: 'blob'
            })

            const csvBlob = new Blob([response.data], { type: "text/csv" })
            const url = window.URL.createObjectURL(csvBlob)

            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `store_plans.csv`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const handleUpload = async (fs: FileList | null) => {
        if(!fs) {
            return
        }

        const files = Array.from(fs)
        if(files.length < 1) {
            return
        }

        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkUpdateStorePlans(files[0])
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while uploading data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal id={id}  onClose={() => setShowWaitingModal(false)} open={showWaitingModal} title="Updating Store Plans"/>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 1:</Typography>
                <Button sx={{ margin: 2}} onClick={() => downloadChecklist()}>Download Plan Data</Button>
            </Paper>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 2:</Typography>
                <Typography sx={{ margin: 2}}>Modify Costs</Typography>
                <Typography color="error">Warning! DO NOT modify the format, copy rows, or modify IDs. This is a bulk modification to the data directly and is dangerous.</Typography>
            </Paper>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 3:</Typography>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadSharp />} 
                    sx={{ margin: 2}}
                >
                    Upload file
                    <VisuallyHiddenInput  accept="text/csv" type="file" onClick={e => (e.target as any).value = null} onChange={(e) => handleUpload(e.target.files)}/>
                </Button>
            </Paper>
        </Container>
    )
}

export default AdministrativeToolsUpdateStorePlansTab