// auth-service.jsx
import React from "react"
import axiosClient from "../apis/client"
import { config } from "../config"
import { useFirebase } from "./FirebaseProvider"
import { AccountsApi, AccountsApiInterface, Configuration, CountriesApi, CountriesApiInterface, DiscountsApi, DiscountsApiInterface, LookupsApi, LookupsApiInterface, OrdersApi, OrdersApiInterface, PlansApi, PlansApiInterface, PostalApi, PostalApiInterface, SessionsApi, SessionsApiInterface, StoresApi, StoresApiInterface } from "../apis/ersys-public"

const { createContext, useContext } = React

interface ErsysPublicApiProviderProps {
    accountsService?: AccountsApiInterface
    ordersService?: OrdersApiInterface
    plansService?: PlansApiInterface
    sessionsService?: SessionsApiInterface
    storesService?: StoresApiInterface
    countriesService?: CountriesApiInterface
    lookupsService?: LookupsApiInterface
    discountsService?: DiscountsApiInterface
    postalService?: PostalApiInterface
    children?: React.ReactNode
    host: string
    getToken:
    | string
    | Promise<string>
    | ((name?: string | undefined, scopes?: string[] | undefined) => string)
    | ((
        name?: string | undefined,
        scopes?: string[] | undefined
    ) => Promise<string>)
    | undefined
}

export interface ErsysPublicApiServices {
    accountsService: AccountsApiInterface
    ordersService: OrdersApiInterface
    plansService: PlansApiInterface
    sessionsService: SessionsApiInterface
    storesService: StoresApiInterface
    countriesService: CountriesApiInterface
    lookupsService: LookupsApiInterface
    discountsService: DiscountsApiInterface
    postalService: PostalApiInterface
}

const ErsysPublicApiContext = createContext<ErsysPublicApiServices>({} as any)

export const ErsysPublicApiProvider: React.FC<ErsysPublicApiProviderProps> = ({
    accountsService,
    ordersService,
    plansService,
    sessionsService,
    storesService,
    countriesService,
    lookupsService,
    discountsService,
    postalService,
    children,
    host,
    getToken,
}) => {
    const config = new Configuration({
        basePath: host,
        accessToken: getToken,
    })
    const value: ErsysPublicApiServices = {
        accountsService: accountsService || new AccountsApi(config, undefined, axiosClient),
        ordersService: ordersService || new OrdersApi(config, undefined, axiosClient),
        plansService: plansService || new PlansApi(config, undefined, axiosClient),
        sessionsService: sessionsService || new SessionsApi(config, undefined, axiosClient),
        storesService: storesService || new StoresApi(config, undefined, axiosClient),
        countriesService: countriesService || new CountriesApi(config, undefined, axiosClient),
        lookupsService: lookupsService || new LookupsApi(config, undefined, axiosClient),
        discountsService: discountsService || new DiscountsApi(config, undefined, axiosClient),
        postalService: postalService || new PostalApi(config, undefined, axiosClient)
    }

    return (
        <ErsysPublicApiContext.Provider value={value}>
            {children}
        </ErsysPublicApiContext.Provider>
    )
}

export const useErsysPublicApi = () => {
    return useContext(ErsysPublicApiContext)
}

interface ErsysPublicApiProviderWrapperProps {
    children?: React.ReactNode;
}

export const ErsysPublicApiProviderWrapper: React.FC<
    ErsysPublicApiProviderWrapperProps
> = ({ children }) => {
    const { user } = useFirebase()

    return (
        <ErsysPublicApiProvider
            getToken={() => {
                return user?.getIdToken() ?? Promise.resolve("")
            }}
            host={config.payrollApi.host}
        >
            {children}
        </ErsysPublicApiProvider>
    )
}
