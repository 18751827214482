// src/form-component/FormInputDropdown.tsx

import { FormControl, MenuItem, TextField, useTheme } from "@mui/material"
import { Controller, FieldValues, RegisterOptions } from "react-hook-form"

export interface FormInputDropdownOption {
  label: string
  value: any
}

export interface FormInputDropdownProps {
  name: string
  control: any
  label: string
  options: FormInputDropdownOption[]
  spacing: number
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined
}

export const FormInputDropdown: React.FC<FormInputDropdownProps> = ({
  name,
  control,
  label,
  options,
  spacing,
  rules,
}) => {
  const theme = useTheme()

  const generateSingleOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    })
  }
  return (
    <div
      style={{
        marginTop: theme.spacing(spacing),
        marginBottom: theme.spacing(spacing),
        maxWidth: "100%",
      }}
    >
      <FormControl size={"small"} sx={{ width: "100%" }}>
        {/* <InputLabel>{label}</InputLabel> */}
        <Controller
          rules={rules}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              select
              onChange={onChange}
              value={value}
              label={label}
              size="small"
              helperText={error ? error.message : null}
              error={!!error}
            >
              {generateSingleOptions()}
            </TextField>
          )}
          control={control}
          name={name}
        />
      </FormControl>
    </div>
  )
}
