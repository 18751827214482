// src/form-component/FormInputText.tsx
import { Controller, FieldValues, RegisterOptions } from "react-hook-form"
import { useTheme } from "@mui/material/styles"
import { Checkbox, FormControlLabel } from "@mui/material"

export interface FormInputCheckboxProps {
    name: string
    control: any
    label: string
    spacing: number
    customOnChange?: (event: any) => void | undefined
    rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >
    | undefined
}

const FormInputCheckbox: React.FC<FormInputCheckboxProps> = ({
    name,
    control,
    label,
    spacing,
    customOnChange,
    rules,
}) => {
    const theme = useTheme()

    return (
        <div
            style={{
                marginTop: theme.spacing(spacing),
                marginBottom: theme.spacing(spacing),
                maxWidth: "100%",
            }}
        >
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControlLabel
                        control={<Checkbox />}
                        label={label}
                        checked={value ?? false}
                        onChange={(e) => {
                            if (customOnChange) {
                                customOnChange(e)
                            }
                            onChange(e)
                        }}
                    />
                )}
            />
        </div>
    )
}

export default FormInputCheckbox
