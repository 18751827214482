// src/form-component/FormInputText.tsx
import { Controller, FieldValues, RegisterOptions } from "react-hook-form"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"

export interface FormInputTextAreaProps {
  name: string
  control: any
  label: string
  spacing: number
  rows: number
  whitespace?: "normal" | "nowrap" | "pre" | "pre-wrap" | "pre-line" | "break-spaces",
  customOnChange?: (event: any) => void | undefined
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined
}

const FormInputTextArea: React.FC<FormInputTextAreaProps> = ({
  name,
  control,
  label,
  spacing,
  rows,
  whitespace,
  customOnChange,
  rules,
}) => {
  const theme = useTheme()

  return (
    <div
      style={{
        marginTop: theme.spacing(spacing),
        marginBottom: theme.spacing(spacing),
        maxWidth: "100%",
      }}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            helperText={error ? error.message : null}
            multiline
            rows={rows}
            margin="dense"
            sx= {{ whiteSpace: whitespace }}
            error={!!error}
            onChange={(e) => {
              if (customOnChange) {
                customOnChange(e)
              }
              onChange(e)
            }}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
          />
        )}
      />
    </div>
  )
}

export default FormInputTextArea
