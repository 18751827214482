import { createBrowserRouter } from "react-router-dom"
import OrderForm from "./pages/OrderForm/OrderForm"
import UsersPage from "./pages/Users/UsersPage/UsersPage"
import Login from "./pages/Login/Login"
import Logout from "./pages/Logout/Logout"
import EditUsersPage from "./pages/Users/EditUserPage/EditUsersPage"
import StoresPage from "./pages/Stores/StoresPage/StoresPage"
import EditStorePage from "./pages/Stores/EditStorePage/EditStorePage"
import AccountsPage from "./pages/Accounts/AccountsPage/AccountsPage"
import EditAccountPage from "./pages/Accounts/EditAccountPage/EditAccountPage"
import Dashboard from "./pages/Dashboard/Dashboard"
import BagReprint from "./pages/Bags/BagReprint"
import BagPrintQueue from "./pages/Bags/BagPrintQueue"
import InventoryCategoriesPage from "./pages/InventoryCategories/InventoryCategoriesPage/InventoryCategoriesPage"
import EditInventoryCategoryPage from "./pages/InventoryCategories/EditInventoryCategoryPage/EditInventoryCategoryPage"
import DepartmentsPage from "./pages/Departments/DepartmentsPage/DepartmentsPage"
import EditDepartmentPage from "./pages/Departments/EditDepartmentPage/EditDepartmentPage"
import DiscountsPage from "./pages/Discounts/DiscountsPage/DiscountsPage"
import EditDiscountPage from "./pages/Discounts/EditDiscountPage/EditDiscountPage"
import PaymentPlansPage from "./pages/PaymentPlans/PaymentPlansPage/PaymentPlansPage"
import EditPaymentPlanPage from "./pages/PaymentPlans/EditPaymentPlanPage/EditPaymentPlanPage"
import GlobalPlansPage from "./pages/GlobalPlans/GlobalPlansPage/GlobalPlansPage"
import EditGlobalPlansPage from "./pages/GlobalPlans/EditGlobalPlansPage/EditGlobalPlansPage"
import DriversDashboardPage from "./pages/DriversDashboard/DriversDashboardPage/DriversDashboardPage"
import AdministrativeToolsPage from "./pages/AdministrativeToolsPage/AdministrativeToolsPage"

// todo: remove spinner replacing with skeletons

export const router = createBrowserRouter([

  // Dashboard
  {
    path: "/",
    element: <Dashboard />
  },

  // Orders
  {
    path: "/orderForm",
    element: <OrderForm />,
  },

  // Users
  {
    path: "/users",
    element: <UsersPage />,
  },
  {
    path: "/users/:id",
    element: <EditUsersPage />
  },

  // Stores
  {
    path: "/stores",
    element: <StoresPage />,
  },
  {
    path: "/stores/:id",
    element: <EditStorePage />,
  },

  // Accounts
  {
    path: "/accounts",
    element: <AccountsPage />
  },
  {
    path: "/accounts/:id",
    element: <EditAccountPage />,
  },

  // Bags
  {
    path: "/bags/reprint",
    element: <BagReprint />,
  },
  {
    path: "/bags/queue",
    element: <BagPrintQueue />,
  },

  // ICS
  {
    path: "/ics",
    element: <InventoryCategoriesPage />,
  },
  {
    path: "/ics/:id",
    element: <EditInventoryCategoryPage />,
  },

  // Departments
  {
    path: "/depts",
    element: <DepartmentsPage />,
  },
  {
    path: "/depts/:id",
    element: <EditDepartmentPage />,
  },

  // Discounts
  {
    path: "/discounts",
    element: <DiscountsPage />
  },
  {
    path: "/discounts/:id",
    element: <EditDiscountPage />
  },

  // Payment Plans
  {
    path: "/paymentPlans",
    element: <PaymentPlansPage />
  },
  {
    path: "/paymentPlans/:id",
    element: <EditPaymentPlanPage />
  },

  // Global Plans
  {
    path: "/globalPlans",
    element: <GlobalPlansPage />
  },
  {
    path: "/globalPlans/:id",
    element: <EditGlobalPlansPage />
  },

  // Drivers Dashboard
  {
    path: "/drivers",
    element: <DriversDashboardPage />
  },

  {
    path: "/adminTools",
    element: <AdministrativeToolsPage />
  },

  // Login/out
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
])