import { ButtonGroup, Fab, FormControlLabel, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import { PaymentPlanDate } from "../../apis/ersys-data/api"
import { AddSharp, DeleteSharp } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

interface PaymentPlanDatesTabProps {
    dates: PaymentPlanDate[],
    showSkeleton: () => boolean
    onChange: (dates: PaymentPlanDate[]) => void
}

const PaymentPlanDatesTab: React.FC<PaymentPlanDatesTabProps> = ({ dates, showSkeleton, onChange }) => {
    const [ sortedSet, setSortedSet ] = useState<PaymentPlanDate[]>([])
    const [ nextId, setNextId ] = useState<number>(-1)

    useEffect(() => {
        const newDates = [...dates]
        newDates.sort((a: PaymentPlanDate, b: PaymentPlanDate) => {
            const dayA = dayjs(a.payDate)
            const dayB = dayjs(b.payDate)
            return dayA.isAfter(dayB)
                ? 1
                : dayA.isSame(dayB)
                    ? 0
                    : -1
        })
        setSortedSet(newDates)
    }, [dates])

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const onChangeDate = (id: number, date: string, percent: number) => {
        const d = dates.find(d => d.id === id)
        if(!d) {
            return
        }
        const newDates = [...dates].filter(d => d.id !== id)
        d!.payDate = date
        d!.percentage = percent
        newDates.push(d)
        onChange(newDates)
    }

    const onCreate = () => {
        onChange([...dates, {id: nextId, payDate: dayjs().format(), percentage: 1}])
        setNextId(nextId-1)
    }

    const onRemove = (id: number) => {
        onChange([...dates].filter(d => d.id !== id))
    }

    return (
        <Grid container spacing={2}>
            <Tooltip title="Add Date">
                <Fab
                    onClick={() => onCreate()}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (
                        <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Cost (%)</TableCell>
                                        <TableCell align="right">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedSet.map((d, i) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`${i}-${d.id}`}>
                                            <TableCell>
                                                <DatePicker
                                                    value={dayjs(d.payDate)}
                                                    onChange={(e) => onChangeDate(d.id, e?.format() ?? '', d.percentage)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={d.percentage}
                                                    onChange={(e) => onChangeDate(d.id, d.payDate, parseInt(e.target.value, 10))}
                                                    InputProps={{inputProps: {min: 1, max: 100}}}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <ButtonGroup>
                                                    <Tooltip title="Is Included">
                                                        <IconButton onClick={() => onRemove(d.id)} color="success">
                                                            <DeleteSharp></DeleteSharp>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default PaymentPlanDatesTab