import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { AccountType, CreateAccountRequest, MinimalStore } from "../../../apis/ersys-data"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import FormInputText from "../../../components/forms/FormInputText"
import { useForm } from "react-hook-form"
import { FormInputDropdown, FormInputDropdownOption } from "../../../components/forms/FormInputDropdown"

const defaults: CreateAccountRequest = {
  email: "",
  first: "",
  last: "",
  store: 0,
  type: AccountType.Student,
}

interface AccountCreationModalProps {
  open: boolean;
  stores: MinimalStore[],
  onClose: () => void;
  onCreate: (req: CreateAccountRequest) => Promise<void>
}

const AccountCreationModal: React.FC<AccountCreationModalProps> = ({
  open,
  stores,
  onCreate,
  onClose,
}) => {
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control } =
    useForm<CreateAccountRequest>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })

  const handleCreate = (input: CreateAccountRequest) => {
    onCreate(input)
  }

  const accountTypeOptions = () => {
    return (Object.keys(AccountType) as Array<keyof typeof AccountType>).map(k => {
      return {
        label: k,
        value: AccountType[k].toString()
      } as FormInputDropdownOption
    })
  }

  const storeOptions = (): FormInputDropdownOption[] => {
    return [
      {
        label: "Select Store",
        value: 0
      },
      ...stores.map(s => {
        return {
          label: s.fullName,
          value: s.id
        }
      })
    ]
  }

  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Create a new store</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormInputDropdown
                spacing={formSpacing}
                name={"store"}
                control={control}
                label={"Store"}
                options={storeOptions()}
              />

              <FormInputText
                spacing={formSpacing}
                name={"email"}
                control={control}
                label={"Email"}
                rules={{ required: "Required Field", maxLength: 100 }}
              />

              <FormInputText
                spacing={formSpacing}
                name={"first"}
                control={control}
                label={"First Name"}
                rules={{ required: "Required Field", maxLength: 50 }}
              />

              <FormInputText
                spacing={formSpacing}
                name={"last"}
                control={control}
                label={"Last Name"}
                rules={{ required: "Required Field", maxLength: 50 }}
              />

              <FormInputDropdown
                spacing={formSpacing}
                name={"type"}
                control={control}
                label={"Account Type"}
                options={accountTypeOptions()}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleCreate)} autoFocus>
          Ok
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AccountCreationModal
