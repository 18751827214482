import { AddSharp, Edit } from '@mui/icons-material'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, ButtonGroup, IconButton, TextField, Skeleton, Fab } from '@mui/material'
import AppMenu from '../../../components/AppMenu'
import './StoresPage.scss'
import { useEffect, useState } from 'react'
import { CreateStoreRequest, MinimalStore } from '../../../apis/ersys-data'
import { showError } from '../../../features/errors/Errors'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { useDispatch } from 'react-redux'
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { Link, useNavigate } from 'react-router-dom'
import StoreCreationModal from './StoreCreationModal'
import { useDebounce } from '../../../components/useDebounce'
import { usePermissions } from '../../../components/usePermissions'
import { useFirebase } from '../../../components/FirebaseProvider'

function StoresPage() {
    const ersysDataApiServices = useErsysDataApi()
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [filteredStores, setFilteredStores] = useState<MinimalStore[]>([])
    const [filter, setFilter] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { hasPermissions } = usePermissions()
    const navigate = useNavigate();
    const debouncedFilter: string = useDebounce(filter, 250)
    const { isReady } = useFirebase()

    useEffect(() => {
        if (!debouncedFilter) {
            setFilteredStores(stores.slice(0, 50))
        }
        setFilteredStores(
            stores
                .filter(s => s.prefix.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase()) || s.fullName.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                .slice(0, 50)
        )
    }, [stores, debouncedFilter])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.storesService.storesList()
                setStores(resp.data.stores)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, ersysDataApiServices.storesService, isReady])

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return stores.length < 1 || (filteredStores.length < 1 && debouncedFilter === '')
    }

    const onCreate = async (req: CreateStoreRequest) => {
        setLoading(true)
        try {
            setShowCreation(false)
            var resp = await ersysDataApiServices.storesService.storesCreate(req)
            navigate(`/stores/${resp.data.id}`)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    if (!isReady) {
      return <AppMenu title="Stores">
        <Skeleton variant="rectangular" height={800} sx={{ width: '100%' }} />
      </AppMenu>
    }

    return (
        <AppMenu title="Stores">
            {hasPermissions("AP:SP+") ?
                <Tooltip title="Add Store">
                    <Fab
                        onClick={() => setShowCreation(true)}
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                        <AddSharp />
                    </Fab>
                </Tooltip>
                :
                <></>
            }
            <StoreCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <Container maxWidth="md">
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Prefix</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        size="small"
                                        value={filter}
                                        placeholder='Search'
                                        onChange={(e) => setFilter(e.target.value)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ? [...Array(6)].map((_, i) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                    <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                    <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Skeleton variant='circular' width={30} height={30} />
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )) :
                                filteredStores.map((store) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={store.id}>
                                        <TableCell>{store.prefix}</TableCell>
                                        <TableCell>{truncate(store.fullName, 100)}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Edit">
                                                    <Link to={`/stores/${encodeURIComponent(store.id)}`}>
                                                        <IconButton>
                                                            <Edit></Edit>
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </AppMenu>
    )
}

export default StoresPage