import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Store, DispatchSortOrder } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"

interface StoreProductionTabProps {
    control: Control<Store, any>
    showSkeleton: () => boolean,
    formSpacing: number
}

const dispatchSortOrderOptions = (Object.keys(DispatchSortOrder) as Array<keyof typeof DispatchSortOrder>).map(k => {
    return {
        label: k,
        value: DispatchSortOrder[k].toString()
    } as FormInputDropdownOption
})

const StoreProductionTab: React.FC<StoreProductionTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputDropdown
                            name="dispatchSortOrder"
                            control={control}
                            label="Dispatch Sort Order"
                            options={dispatchSortOrderOptions}
                            spacing={formSpacing}
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputCheckbox name="useLocations" control={control} label="Use locations for sorting" spacing={formSpacing} />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default StoreProductionTab