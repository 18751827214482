import { Box, Tooltip } from '@mui/material'
import Cards from 'react-credit-cards-2'

interface CreditCardDisplayComponentProps {
    lastFour: number
    expiryMonth: number
    expiryYear: number
    brand: string
    name: string
}

const CreditCardDisplayComponent: React.FC<CreditCardDisplayComponentProps> = ({ lastFour, expiryMonth, expiryYear, brand, name }) => {

    return (
        <Tooltip title={brand}>
            <Box>
            <Cards
                expiry={`${String(expiryMonth).padStart(2, '0')}/${String(expiryYear).padStart(4, '0')}`}
                number={`000000000000${lastFour}`}
                cvc={''}
                name={name}
                issuer={brand}
                preview={true}
            /></Box>
        </Tooltip>
    )
}

export default CreditCardDisplayComponent