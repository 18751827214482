import './EditInventoryCategoryPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { InventoryCategory, PatchAction } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import { useForm, useFormState } from 'react-hook-form'
import { useFirebase } from '../../../components/FirebaseProvider'
import InventoryCategoryLinenTab from '../../../components/ics/InventoryCategoryLinentTab'

const formDefaults: InventoryCategory = {
    failureToReturnCost: 0,
    id: 0,
    isRental: false,
    name: ''
}

function EditInventoryCategoryPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [inventoryCategory, setInventoryCategory] = useState<InventoryCategory>()
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<InventoryCategory>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !inventoryCategory || inventoryCategory.id === 0
    }

    const allowSave = () => {
        return isDirty
    }

    const getTabNames = (): string[] => {
        return [
            "Linen"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <InventoryCategoryLinenTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />
        ]
    }

    useEffect(() => {
        const resetFunc = async () => {
            if (inventoryCategory == null) {
                return
            }
            reset(inventoryCategory)
            await trigger()
        }

        resetFunc()
    }, [inventoryCategory, reset, trigger])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const id = parseInt(params.id!, 10)
                const icResp = await ersysDataApiServices.inventoryCategoryService.inventoryCategoriesGet(id)
                setInventoryCategory(icResp.data)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting ic data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setInventoryCategory, ersysDataApiServices.inventoryCategoryService, params.id, isReady])

    const Save = async (s: InventoryCategory) => {
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof InventoryCategory>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }

        try {
            // account update
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.inventoryCategoryService.inventoryCategoriesUpdate(s.id, { actions: patchSet })
                setInventoryCategory(resp.data)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while updating ic. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Inventory Category">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Inventory Category: ${inventoryCategory?.name ?? ""}`}>
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditInventoryCategoryPage