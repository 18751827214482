import './EditStorePage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { MinimalGlobalPlan, MinimalStorePlan, PatchAction, Proration, Store } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import NotificationModal from '../../../components/NotificationModal'
import { useForm, useFormState } from 'react-hook-form'
import StoresGeneralTab from '../../../components/stores/StoreGeneralTab'
import StoresKnowledgeTab from '../../../components/stores/StoreKnowledgeTab'
import StoreNotesTab from '../../../components/stores/StoreNotesTab'
import StoreSpecificsTab from '../../../components/stores/StoreSpecificsTab'
import StoreTermsTab from '../../../components/stores/StoreTermsTab'
import StoreProductionTab from '../../../components/stores/StoreProductionTab'
import StoreAccountingTab from '../../../components/stores/StoreAccountingTab'
import StoreOrdersTab from '../../../components/stores/StoreOrdersTab'
import { usePermissions } from '../../../components/usePermissions'
import StoreLocationsTab from '../../../components/stores/StoreLocationsTab'
import StoreProrationsTab from '../../../components/stores/StoreProrationsTab'
import dayjs from 'dayjs'
import StorePlansTab from '../../../components/stores/StorePlansTab'
import { useFirebase } from '../../../components/FirebaseProvider'

const formDefaults: Store = {
    addressLineOne: '',
    addressLineTwo: '',
    bagsPerWeek: 0,
    city: '',
    commercialRollupAccount: 0,
    commercialRollupEnabled: false,
    custom1: '',
    custom1Type: 'string',
    dispatchSortOrder: 'route',
    facultyRollupAccount: 0,
    facultyRollupEnabled: false,
    fullName: '',
    generalKnowledge: '',
    hidePricing: false,
    id: 0,
    isActive: false,
    isCustom1Enabled: false,
    isLocationEnabledOnOrder: false,
    isLocationMandatoryOnOrder: false,
    isPassphraseRequired: false,
    isRoomEnabledOnOrder: false,
    notes: '',
    passphrase: '',
    postal: '',
    prefix: '',
    prorationEnabled: false,
    rollupAccount: 0,
    rollupEnabled: false,
    shortName: '',
    showTOS: false,
    specifics: '',
    state: '',
    storeType: 'school',
    taxRate: 0,
    termsOfService: '',
    useLocations: false
}

function EditStorePage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [selectedStore, setSelectedStore] = useState<Store>()
    const [loading, setLoading] = useState<boolean>(false)
    const [nextId, setNextId] = useState<number>(-1)
    const [prorationsDirty, setProrationsDirty] = useState<boolean>(false)
    const [prorations, setProrations] = useState<Proration[]>([])
    const [storePlans, setStorePlans] = useState<MinimalStorePlan[]>([])
    const [globalPlans, setGlobalPlans] = useState<MinimalGlobalPlan[]>([])
    const [notificationIsVisible, setNotificationIsVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<Store>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { hasPermissions } = usePermissions()
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !selectedStore || selectedStore.id === 0
    }

    const allowSave = () => {
        return isDirty || prorationsDirty
    }

    const getTabNames = (): string[] => {
        const tabs = [
            "General"
        ]

        if (hasPermissions("AP:SP:G")) {
            tabs.push("Knowledge")
        }

        tabs.push("Notes")
        tabs.push("Specifics")
        tabs.push("Locations")
        tabs.push("Plans")
        tabs.push("Terms")

        if (hasPermissions("AP:SP:P")) {
            tabs.push("Production")
        }

        if (hasPermissions("AP:Acct")) {
            tabs.push("Accounting")
        }

        tabs.push("Proration")
        tabs.push("Orders")

        return tabs
    }

    const getTabComponents = (): JSX.Element[] => {
        const tabs = [
            <StoresGeneralTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />
        ]

        if (hasPermissions("AP:SP:G")) {
            tabs.push(<StoresKnowledgeTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)
        }

        tabs.push(<StoreNotesTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)
        tabs.push(<StoreSpecificsTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)
        tabs.push(<StoreLocationsTab formSpacing={formSpacing} />)
        tabs.push(<StorePlansTab formSpacing={formSpacing} showSkeleton={showSkeleton} storePlans={storePlans} globalPlans={globalPlans} setStorePlans={setStorePlans} />)
        tabs.push(<StoreTermsTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)

        if (hasPermissions("AP:SP:P")) {
            tabs.push(<StoreProductionTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)
        }

        if (hasPermissions("AP:Acct")) {
            tabs.push(<StoreAccountingTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)
        }

        tabs.push(<StoreProrationsTab formSpacing={formSpacing} control={control} store={selectedStore} prorations={prorations} createProration={createProration} deleteProration={deleteProrations} updateProration={updateProration} />)
        tabs.push(<StoreOrdersTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />)

        return tabs
    }

    useEffect(() => {
        const resetFunc = async () => {
            if (selectedStore == null) {
                return
            }
            reset(selectedStore)
            await trigger()
        }

        resetFunc()
    }, [selectedStore, reset, trigger])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const storeId = parseInt(params.id!, 10)
                const storeResp = await ersysDataApiServices.storesService.storesGet(storeId)
                setSelectedStore(storeResp.data)

                const prorationResp = await ersysDataApiServices.storesService.storesListProrations(storeId)
                setProrations(prorationResp.data.prorations)
                setProrationsDirty(false)

                const storePlanResp = await ersysDataApiServices.storesService.storesListPlans(storeId)
                setStorePlans(storePlanResp.data.plans)

                const globalPlanResp = await ersysDataApiServices.globalPlansService.globalPlansList()
                setGlobalPlans(globalPlanResp.data.plans)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setSelectedStore, ersysDataApiServices.storesService, ersysDataApiServices.globalPlansService, params.id, isReady])

    const createProration = () => {
        const newProration: Proration = {
            cost: 0,
            date: dayjs().format(),
            id: nextId
        }
        setNextId(nextId - 1)

        const newProrations = [...prorations]
        newProrations.push(newProration)
        setProrations(newProrations)
        setProrationsDirty(true)
    }

    const deleteProrations = (id: number) => {
        const newProrations = prorations.filter(p => p.id !== id)
        setProrations(newProrations)
        setProrationsDirty(true)
    }

    const updateProration = (np: Proration) => {
        const newProrations = prorations.map(p => {
            if (p.id === np.id) {
                p.cost = np.cost
                p.date = np.date
                return p
            }
            return p
        })
        setProrations(newProrations)
        setProrationsDirty(true)
    }

    const Save = async (s: Store) => {
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof Store>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }

        try {
            // store update
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.storesService.storesUpdate(s.id, { actions: patchSet })
                setSelectedStore(resp.data)
            }

            // prorations update
            if (prorationsDirty) {
                await ersysDataApiServices.storesService.storesOverwriteProrations(s.id, {
                    prorations: prorations.map(p => {
                        return {
                            cost: p.cost,
                            date: p.date
                        }
                    })
                })
                setProrationsDirty(false)
                const prorationResp = await ersysDataApiServices.storesService.storesListProrations(s.id)
                setProrations(prorationResp.data.prorations)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Store">
            <Skeleton variant="rectangular" height={800} sx={{ width: '100%' }} />
        </AppMenu>
    }

    return (
        <AppMenu title={`Store: ${selectedStore?.fullName ?? ""}`}>
            <NotificationModal
                message="User changes have been saved."
                open={notificationIsVisible}
                title="Save Successful"
                onClose={() => setNotificationIsVisible(false)}
            />
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditStorePage