import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"

import TextField from "@mui/material/TextField"
import { Container } from "@mui/system"
import { setPersistence, sendPasswordResetEmail, browserSessionPersistence } from "firebase/auth"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { RootState } from "../../app/store"
import ErrorModal from "../../components/ErrorModal"
import { useFirebase } from "../../components/FirebaseProvider"
import { resetVisible, showError } from "../../features/errors/Errors"
import "./Login.scss"
import { setIsLoading } from "../../features/menus/AppMenu"

const Login: React.FC = () => {
  const theme = createTheme()
  const errorIsOpen = useSelector((state: RootState) => state.errors.isVisible)
  const errorMessage = useSelector((state: RootState) => state.errors.message)
  const errorTitle = useSelector((state: RootState) => state.errors.title)
  const [showPassword, setShowPassword] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [isWiggling, setIsWiggling] = useState(false)
  const { auth, user, isReady, login } = useFirebase()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isReady) {
      return
    }
    if (user) {
      navigate(location?.state?.redirectUrl ?? "/")
      return
    }
  }, [user, isReady, navigate, location])

  const clickShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const wiggleEnd = () => {
    setIsWiggling(false)
  }

  const animationClass = () => {
    return isWiggling
      ? "wiggling"
      : ""
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (!rememberMe) {
      await setPersistence(auth, browserSessionPersistence)
    }
    try {
      await login(username, password)
      navigate(location?.state?.redirectUrl ?? "/")
    } catch (ex: any) {
      if (ex.code === "auth/too-many-requests") {
        dispatch(
          showError({
            title: "Account Locked",
            message:
              "There have been too many failed attempts to login to this account. Please wait a few minutes, or reset the password for this account.",
          })
        )
      }
      setPassword("")
      setIsWiggling(true)
    }
  }

  const resetPassword = async () => {
    if (username === "") {
      dispatch(
        showError({
          title: "No Email",
          message:
            "Please enter an email address to reset the password.",
        })
      )
      return
    }
    try {
      dispatch(setIsLoading(true))
      await sendPasswordResetEmail(auth, username)
      dispatch(
        showError({
          title: "Password Reset",
          message:
            "Password reset email has been sent.",
        })
      )
    } catch (ex: any) {
      if (ex.code === "auth/user-not-found") {
        dispatch(
          showError({
            title: "Not found",
            message:
              "User not found with this email address.",
          })
        )
        return
      }
      dispatch(
        showError({
          title: "Unexpected Error",
          message:
            "There was an unexpected error resetting this password. Please contact IT.",
        })
      )
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorModal
        message={errorMessage}
        open={errorIsOpen}
        title={errorTitle}
        onClose={() => dispatch(resetVisible())}
      />
      <Box
        sx={{
          backgroundColor: "primary.light",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
        }}
      >
        <Container component="main" className={animationClass()} maxWidth="xs" sx={{ mt: "5rem" }}
          onAnimationEnd={wiggleEnd}>
          <Paper sx={{ pl: 1, pr: 1, pt: 1 }}>
            <Box
              sx={{
                m: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <FormControl
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={clickShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" onChange={e => setRememberMe(e.target.checked)} />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                >
                  Sign In
                </Button>
                <Grid container mb="1rem">
                  <Grid item xs></Grid>
                  <Grid item xs display="flex" justifyContent="right">
                    <Link href="#" onClick={resetPassword} variant="body2" textAlign="right">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Login
