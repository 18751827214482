import { Button, ButtonGroup, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import { Location } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { useFirebase } from "../FirebaseProvider"
import { MinimalStore } from "../../apis/ersys-public"
import { showError } from "../../features/errors/Errors"
import { ExpandLessSharp, ExpandMoreSharp, SaveSharp } from "@mui/icons-material"
import { setIsLoading } from "../../features/menus/AppMenu"

const DriversRouteStopTab: React.FC = () => {
    const dispatch = useDispatch()
    const ersysDataApiServices = useErsysDataApi()
    const [locations, setLocations] = useState<Location[]>([])
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedStore, setSelectedStore] = useState<number>(0)
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])
    
    useEffect(() => {
        if (!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var storesResp = await ersysDataApiServices.storesService.storesList()
                setStores(storesResp.data.stores)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Plan data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, ersysDataApiServices.storesService, isReady])

    useEffect(() => {
        if (!isReady || selectedStore === 0) {
            return
        }
        const fetch = async () => {
            try {
                setLoading(true)
                var resp = await ersysDataApiServices.storesService.storesListLocations(selectedStore)
                const ls = resp.data.locations
                ls.sort((a,b) => (a.routeStop > b.routeStop) ? 1 : ((b.routeStop > a.routeStop) ? -1 : 0))
                setLocations(ls)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting locatgion data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }
        fetch()
    }, [dispatch, setLocations, setLoading, ersysDataApiServices.storesService, isReady, selectedStore])

    const showSkeleton = () => {
        return !isReady || stores.length < 1
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const moveRouteUp = (id: number) => {
        const idx = locations.findIndex(l => l.id === id)
        if(idx === 0) {
            return
        }
        const set = [...locations]
        const above = set[idx-1]
        const current = set[idx]
        set[idx-1] = current
        set[idx] = above
        setLocations(set)
    }

    const moveRouteDown = (id: number) => {
        const idx = locations.findIndex(l => l.id === id)
        if(idx === locations.length - 1) {
            return
        }
        const set = [...locations]
        const below = set[idx+1]
        const current = set[idx]
        set[idx+1] = current
        set[idx] = below
        setLocations(set)
    }

    const saveChanges = async () => {
        try {
            setLoading(true)
            let currentRtStop = 1
            const ls = locations.map(l => {
                l.routeStop = currentRtStop
                currentRtStop+=1
                return l
            })
            await ersysDataApiServices.storesService.storesUpdateRouteStops(selectedStore, ls)
            var resp = await ersysDataApiServices.storesService.storesListLocations(selectedStore)
            resp.data.locations.sort((a,b) => (a.routeStop > b.routeStop) ? 1 : ((b.routeStop > a.routeStop) ? -1 : 0))
            setLocations(resp.data.locations)
            setLoading(false)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting location data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
    }

    return (
        <Grid container spacing={2}>
            {showSkeleton() ? renderSkeleton() :
                <TextField
                    select
                    sx={{ width: 500, marginBottom: 2, marginTop: 2}}
                    onChange={e => setSelectedStore(parseInt(e.target.value))}
                    value={selectedStore}
                    label="Select School"
                    size="small">
                    <MenuItem key={0} value={0}>
                        Select Store
                    </MenuItem>
                    {
                        stores && stores.map(
                            s => <MenuItem key={s.id} value={s.id}>
                                {s.fullName}
                            </MenuItem>
                        )
                    }
                </TextField>
            }
            {selectedStore !== 0 && <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">
                            </TableCell>
                            <TableCell align="right">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showSkeleton() ? [...Array(6)].map((_, i) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                <TableCell align="right">
                                    <ButtonGroup>
                                        <Skeleton variant='circular' width={30} height={30} />
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        )) :
                            locations.map((location) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={location.id}>
                                    <TableCell>{location.name}</TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Tooltip title="Move Up">
                                                <IconButton onClick={() => moveRouteUp(location.id)}>
                                                    <ExpandLessSharp></ExpandLessSharp>
                                                </IconButton>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Tooltip title="Move Down">
                                                <IconButton onClick={() => moveRouteDown(location.id)}>
                                                    <ExpandMoreSharp></ExpandMoreSharp>
                                                </IconButton>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>}
            { selectedStore !== 0 && <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        onClick={() => saveChanges()}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div> }
        </Grid>
    )
}

export default DriversRouteStopTab