import { Container, FormGroup, Skeleton } from "@mui/material"
import { Store } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputTextArea from "../forms/FormInputTextArea"

interface StoreNotesTabProps {
    control: Control<Store, any>
    showSkeleton: () => boolean,
    formSpacing: number
}

const StoreNotesTab: React.FC<StoreNotesTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup sx={{width: "100%"}}>
                <Skeleton variant='rectangular' width={800} height={200} sx={{ margin: 2 }} />
            </FormGroup>
        )
    }

    return (
        <Container>
            {showSkeleton() ? renderSkeleton() :
                <FormGroup>
                    <FormInputTextArea 
                        rows={12}
                        whitespace="pre-wrap"
                        control={control}
                        label="Notes"
                        name="notes"
                        spacing={formSpacing}
                    />
                </FormGroup>}
                </Container>
    )
}

export default StoreNotesTab