import { AddSharp, Edit } from '@mui/icons-material'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, ButtonGroup, IconButton, TextField, Skeleton, Fab } from '@mui/material'
import AppMenu from '../../../components/AppMenu'
import './PaymentPlansPage.scss'
import { useEffect, useState } from 'react'
import { MinimalPaymentPlan, PaymentPlanCreationRequest } from '../../../apis/ersys-data'
import { showError } from '../../../features/errors/Errors'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { useDispatch } from 'react-redux'
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from '../../../components/useDebounce'
import { useFirebase } from '../../../components/FirebaseProvider'
import PaymentPlanCreationModal from './PaymentPlanCreationModal'

function PaymentPlansPage() {
    const ersysDataApiServices = useErsysDataApi()
    const [paymentPlans, setPaymentPlans] = useState<MinimalPaymentPlan[]>([])
    const [visiblePaymentPlans, setVisiblePaymentPlans] = useState<MinimalPaymentPlan[]>([])
    const [filter, setFilter] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const debouncedFilter: string = useDebounce(filter, 500)
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        if(debouncedFilter === "") {
            setVisiblePaymentPlans(paymentPlans.slice(0, 50))
        }
        setVisiblePaymentPlans(paymentPlans.filter(i => i.name.toLowerCase().includes(debouncedFilter.toLowerCase())).slice(0, 50))
    }, [paymentPlans, setVisiblePaymentPlans, isReady, debouncedFilter])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.paymentPlansService.paymentPlansList()
                setPaymentPlans(resp.data.plans)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Payment Plan data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setPaymentPlans, ersysDataApiServices.paymentPlansService, isReady])

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return visiblePaymentPlans.length < 1 && debouncedFilter === ''
    }

    const onCreate = async (req: PaymentPlanCreationRequest) => {
        setLoading(true)
        try {
            setShowCreation(false)
            var resp = await ersysDataApiServices.paymentPlansService.paymentPlansCreate(req)
            navigate(`/paymentPlans/${resp.data.id}`)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while creating Payment Plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    if (!isReady) {
        return <AppMenu title="Payment Plans">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title="Payment Plans">
            <Tooltip title="Add Payment Plan">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <PaymentPlanCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <Container maxWidth="md">
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        size="small"
                                        value={filter}
                                        placeholder='Search'
                                        onChange={(e) => setFilter(e.target.value)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ? [...Array(6)].map((_, i) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                    <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                    <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Skeleton variant='circular' width={30} height={30} />
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )) :
                                visiblePaymentPlans.map((plan) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={plan.id}>
                                        <TableCell>{plan.name}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Edit">
                                                    <Link to={`/paymentPlans/${plan.id}`}>
                                                        <IconButton>
                                                            <Edit></Edit>
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </AppMenu>
    )
}

export default PaymentPlansPage