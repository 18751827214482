import { AccountBox } from "@mui/icons-material"
import {
  FormControl,
  Button,
  Container,
  Divider,
  ToggleButton,
} from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "../../../features/errors/Errors"
import {
  setSelectedAccount,
  setOrderStepIndex,
  enableCreateAccount,
  setIsRenewal,
} from "../../../features/order-workflow/Order"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import "./SelectAccountStep.scss"
import { setIsLoading } from "../../../features/menus/AppMenu"
import AsyncSelect from "react-select/async"
import { debounce } from "lodash"
import React from "react"
import { RootState } from "../../../app/store"

interface SelectAccountProps {
  currentIndex: number
}

const SelectAccount: React.FC<SelectAccountProps> = ({ currentIndex }) => {
  const ersysPublicApiServices = useErsysPublicApi()
  const dispatch = useDispatch()
  const currentStore = useSelector(
    (state: RootState) => state.order.selectedStore,
  )
  const [accountId, setAccountId] = useState<number>(0)
  const [renewal, setRenewal] = useState<boolean>(false)

  const createAccount = async (type: string) => {
    dispatch(enableCreateAccount(type))
    dispatch(setIsRenewal(false))
    dispatch(setOrderStepIndex(currentIndex + 1))
  }

  const selectAccount = async () => {
    try {
      dispatch(setIsLoading(true))
      const accountData =
        await ersysPublicApiServices.accountsService.accountsGet(accountId)
      dispatch(setIsRenewal(renewal))
      dispatch(setSelectedAccount(accountData.data))
      dispatch(setOrderStepIndex(currentIndex + 1))
    } catch (ex: any) {
      if (ex?.response?.status === 404) {
        dispatch(
          showError({
            title: "Account Not Found",
            message: "The given account number was not found.",
          }),
        )
      } else if (ex?.response?.status === 500) {
        dispatch(
          showError({
            title: "Internal Server Error",
            message:
              "An unexpected error occurred while getting account. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      } else {
        dispatch(
          showError({
            title: "Unexpected Error occurred",
            message:
              "An unexpected error occurred while getting account. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      }

      dispatch(setIsLoading(false))
    }
  }

  const disableSelectAccount = () => {
    return accountId < 1
  }

  const loadAccountOptions = async (filter: string) => {
    if (!filter) {
      return []
    }
    const resp = await ersysPublicApiServices.lookupsService.lookupsAccounts(
      filter,
      currentStore!,
    )
    return resp.data.accounts.map((a) => {
      return {
        value: a.id,
        label: `${a.id}: ${a.primaryLastName}, ${a.primaryFirstName}`,
      }
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = React.useCallback(
    debounce((input, callback) => {
      loadAccountOptions(input).then((result) => callback(result))
    }, 500),
    [],
  )

  return (
    <Container maxWidth="sm">
      <h1>Select Account</h1>
      <FormControl sx={{ width: "100%" }}>
        <AsyncSelect
          loadOptions={debouncedFetch}
          defaultOptions={true}
          onChange={(e: any) => setAccountId(e.value)}
        />
      </FormControl>
      <div className="sa-select-area-wrapper">
        <div className="sa-renewal-toggle-wrapper">
          <ToggleButton
            value="check"
            color={renewal ? "success" : "error"}
            selected={renewal}
            onChange={() => setRenewal(!renewal)}
          >
            Renewal
          </ToggleButton>
        </div>
        <div className="sa-select-account-btn-wrapper">
          <Button
            variant="contained"
            disabled={disableSelectAccount()}
            onClick={selectAccount}
            endIcon={<AccountBox />}
          >
            Select
          </Button>
        </div>
      </div>

      <Divider>
        <h1>or</h1>
      </Divider>
      <div className="sa-create-account-btn-wrapper ">
        <Button
          variant="contained"
          onClick={() => createAccount("student")}
          endIcon={<AccountBox />}
        >
          Create New Student Account
        </Button>
      </div>
      <div className="sa-create-account-btn-wrapper ">
        <Button
          variant="contained"
          onClick={() => createAccount("faculty")}
          endIcon={<AccountBox />}
        >
          Create New Faculty Account
        </Button>
      </div>
    </Container>
  )
}

export default SelectAccount
