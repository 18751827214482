import AddonsStep from "./AddonsStep/AddonsStep";
import ModifyCustomerStep from "./ModifyCustomerStep/ModifyCustomerStep";
import PlansStep from "./PlansStep/PlansStep";
import SelectAccount from "./SelectAccountStep/SelectAccountStep"
import SelectStore from "./SelectStoreStep.scss/SelectStoreStep";
import SummaryStep from "./SummaryStep/SummaryStep";

interface CurrentStepProps {
    index: number;
}

const CurrentStep: React.FC<CurrentStepProps> = ({ index }) => {

    const indexToComponent = (index: number) => {
        switch (index) {
            case 0:
                return <SelectStore currentIndex={index} />
            case 1:
                return <SelectAccount currentIndex={index} />
            case 2:
                return <PlansStep currentIndex={index} />
            case 3:
                return <AddonsStep currentIndex={index} />
            case 4:
                return <ModifyCustomerStep currentIndex={index} />
            case 5:
                return <SummaryStep currentIndex={index} />
        }
    }

    return (<>{indexToComponent(index)}</>)
}

export default CurrentStep