import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { config } from "./config"
import { store } from "./app/store"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import { router } from "./router"

// Font imports for MUI
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { FirebaseProvider } from "./components/FirebaseProvider"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { ThemeProvider } from "@emotion/react"
import { createTheme } from "@mui/material"
import { ErsysPublicApiProviderWrapper } from "./components/ErsysPublicApiProvider"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ErsysDataApiProviderWrapper } from "./components/ErsysDataApiProvider"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const theme = createTheme({})
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <FirebaseProvider firebase={config.firebase}>
          <Elements stripe={loadStripe(config.stripe.publicKey)}>
            <ErsysPublicApiProviderWrapper>
              <ErsysDataApiProviderWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <RouterProvider router={router} />
                </LocalizationProvider>
              </ErsysDataApiProviderWrapper>
            </ErsysPublicApiProviderWrapper>
          </Elements>
        </FirebaseProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
