import './UsersPage.scss'
import AppMenu from '../../../components/AppMenu'
import { ButtonGroup, Container, Fab, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import { useDispatch } from "react-redux"
import { AddSharp, CheckCircle, DeleteSharp, DoNotDisturbOn, Edit, Password } from '@mui/icons-material'
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { User, UserCreationRequest } from '../../../apis/ersys-data'
import NagModal from '../../../components/NagModal'
import NotificationModal from '../../../components/NotificationModal'
import { Link, useNavigate } from 'react-router-dom'
import UserCreationModal from './UserCreationModal'

function UsersPage() {
    const ersysDataApiServices = useErsysDataApi()
    const [users, setUsers] = useState<User[]>([])
    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const [filter, setFilter] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [nagIsVisible, setNagIsVisible] = useState<boolean>(false)
    const [nagTitle, setNagTitle] = useState<string>("")
    const [nagOkFunc, setNagOkFunc] = useState<() => Promise<void>>(async () => { })
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const [notificationIsVisible, setNotificationIsVisible] = useState<boolean>(false)
    const [notificationTitle, setNotificationTitle] = useState<string>("")
    const [notificationMessage, setNotificationMessage] = useState<string>("")
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (!filter) {
            setFilteredUsers(users.slice(0, 50))
        }
        setFilteredUsers(users.filter(u =>
            u.displayName.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            || u.email.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        ).slice(0, 50))
    }, [users, filter])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.usersService.usersList()
                setUsers(resp.data.users)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting user data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setUsers, ersysDataApiServices.usersService])

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }

    const requestDeleteUser = (user: User) => {
        setNagTitle("Delete User")
        setNagOkFunc(() => onDeleteUser(user))
        setNagIsVisible(true)
    }

    const requestResetPassword = (user: User) => {
        setNagTitle("Reset Password")
        setNagOkFunc(() => onResetPassword(user))
        setNagIsVisible(true)
    }

    const onDeleteUser = (user: User) => {
        return async () => {
            try {
                setLoading(true)
                setNagIsVisible(false)
                await ersysDataApiServices.usersService.usersDelete(user!.id)
                const resp = await ersysDataApiServices.usersService.usersList()
                setUsers(resp.data.users)
            }
            catch (ex: any) {
                dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while deleting user. If problem persists please contact IT.",
                }))
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }
    }

    const onResetPassword = (user: User) => {
        return async () => {
            try {
                setLoading(true)
                setNagIsVisible(false)
                await ersysDataApiServices.usersService.usersResendPasswordEmail(user!.id)
                setNotificationMessage("Successfully sent reset password link to user email.")
                setNotificationTitle("Reset Password Success")
                setNotificationIsVisible(true)
            }
            catch (ex: any) {
                dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while resetting password. If problem persists please contact IT.",
                }))
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }
    }

    const onCreate = async (req: UserCreationRequest) => {
        setLoading(true)
        try {
            setShowCreation(false)
            var resp = await ersysDataApiServices.usersService.usersCreate(req)
            navigate(`/users/${resp.data.id}`)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return users.length < 1 || (filteredUsers.length < 1 && filter === '')
    }

    return (
        <AppMenu title="Users">
            <Tooltip title="Add User">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <UserCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <NagModal
                message='Are you sure?'
                title={nagTitle}
                onCancel={() => { setNagIsVisible(false) }}
                onOk={nagOkFunc!}
                open={nagIsVisible}
            />
            <NotificationModal
                message={notificationMessage}
                open={notificationIsVisible}
                title={notificationTitle}
                onClose={() => setNotificationIsVisible(false)}
            />
            <Container maxWidth="md">
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'></TableCell>
                                <TableCell>Display Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        size="small"
                                        value={filter}
                                        placeholder='Search'
                                        onChange={(e) => setFilter(e.target.value)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ?
                                [...Array(6)].map((_, i) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                        <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                        <TableCell><Skeleton variant='rectangular' width={200} height={20} /></TableCell>
                                        <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Skeleton variant='circular' width={30} height={30} sx={{ marginRight: 1 }} />
                                                <Skeleton variant='circular' width={30} height={30} sx={{ marginRight: 1 }} />
                                                <Skeleton variant='circular' width={30} height={30} sx={{ marginRight: 1 }} />
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : filteredUsers.map((user) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={user.id}>
                                        <TableCell align='center'>
                                            {user.loggedInRecently ?

                                                <Tooltip title="Logged In Recently">
                                                    <CheckCircle color="success"></CheckCircle>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Absent more than 7 days">
                                                    <DoNotDisturbOn color="error"></DoNotDisturbOn>
                                                </Tooltip>}
                                        </TableCell>
                                        <TableCell>{truncate(user.displayName, 30)}</TableCell>
                                        <TableCell>{truncate(user.email, 40)}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Edit">
                                                    <Link to={`/users/${encodeURIComponent(user.id)}`}>
                                                        <IconButton>
                                                            <Edit></Edit>
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title="Password Reset">
                                                    <IconButton onClick={() => requestResetPassword(user)}>
                                                        <Password></Password>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => requestDeleteUser(user)}>
                                                        <DeleteSharp></DeleteSharp>
                                                    </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </AppMenu>
    )
}

export default UsersPage