import { AddSharp, Edit } from '@mui/icons-material'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, ButtonGroup, IconButton, Skeleton, Fab } from '@mui/material'
import AppMenu from '../../../components/AppMenu'
import './GlobalPlansPage.scss'
import { useEffect, useState } from 'react'
import { GlobalPlanCreationRequest, MinimalGlobalPlan } from '../../../apis/ersys-data'
import { showError } from '../../../features/errors/Errors'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { useDispatch } from 'react-redux'
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { Link, useNavigate } from 'react-router-dom'
import { useFirebase } from '../../../components/FirebaseProvider'
import GlobalPlanCreationModal from './GlobalPlanCreationModal'

function GlobalPlansPage() {
    const ersysDataApiServices = useErsysDataApi()
    const [plans, setPlans] = useState<MinimalGlobalPlan[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.globalPlansService.globalPlansList()
                setPlans(resp.data.plans)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Plan data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setPlans, ersysDataApiServices.globalPlansService, isReady])

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return plans.length < 1
    }

    const onCreate = async (req: GlobalPlanCreationRequest) => {
        setLoading(true)
        try {
            setShowCreation(false)
            var resp = await ersysDataApiServices.globalPlansService.globalPlansCreate(req)
            navigate(`/globalPlans/${resp.data.id}`)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while creating Global Plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    if (!isReady) {
        return <AppMenu title="Global Plans">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title="Global Plans">
            <Tooltip title="Add Global Plan">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <GlobalPlanCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <Container maxWidth="md">
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ? [...Array(6)].map((_, i) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                    <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                    <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Skeleton variant='circular' width={30} height={30} />
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )) :
                                plans.map((plan) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={plan.id}>
                                        <TableCell>{plan.name}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Edit">
                                                    <Link to={`/globalPlans/${plan.id}`}>
                                                        <IconButton>
                                                            <Edit></Edit>
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </AppMenu>
    )
}

export default GlobalPlansPage