import { Box, ButtonGroup, Fab, FormControlLabel, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import { Proration, Store } from "../../apis/ersys-data/api"
import { DeleteSharp, AddSharp } from "@mui/icons-material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import { Control } from "react-hook-form"

interface StoreProrationsTabProps {
    formSpacing: number,
    control: Control<Store, any>
    store?: Store,
    prorations: Proration[]
    updateProration(p: Proration): void
    createProration(): void
    deleteProration(id: number): void
}

const StoreProrationsTab: React.FC<StoreProrationsTabProps> = ({
    formSpacing,
    control,
    store,
    prorations,
    updateProration,
    createProration,
    deleteProration
}) => {

    const showSkeleton = () => {
        return prorations.length < 1 && (!store || !store.id)
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const onCostChange = (p: Proration, v: number) => {
        if (isNaN(v)) {
            v = 0
        }
        p.cost = Math.max(Math.min(v, 100), 0)
        updateProration(p)
    }

    const onDateChange = (p: Proration, v: Dayjs | null) => {
        p.date = v?.format() ?? ""
        updateProration(p)
    }

    const orderedProrations = () => {
        const newArray = [...prorations]
        newArray.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
        return newArray
    }


    return (
        <Box>

            <FormGroup>
                <FormInputCheckbox
                    control={control}
                    label="Enable Prorations"
                    name="prorationEnabled"
                    spacing={formSpacing}
                />
            </FormGroup>
            <Grid container alignItems="center" justifyContent="center">
                <Tooltip title="Add Proration">
                    <Fab
                        onClick={() => createProration()}
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                        <AddSharp />
                    </Fab>
                </Tooltip>
                {showSkeleton() ? renderSkeleton() :
                    <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell align="right">
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderedProrations().map((proration) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={proration.id}>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                type="number"
                                                fullWidth
                                                inputProps={{
                                                    step: 0.05,
                                                    min: 0,
                                                    max: 100
                                                }}
                                                value={proration.cost ?? 0}
                                                onChange={(e) => onCostChange(proration, parseFloat(e.target.value))}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <DatePicker
                                                value={dayjs(proration.date)}
                                                onChange={(e) => onDateChange(proration, e)}
                                                defaultValue={dayjs()}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => deleteProration(proration.id)}>
                                                        <DeleteSharp></DeleteSharp>
                                                    </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>
        </Box>
    )
}

export default StoreProrationsTab