import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppMenuState {
    isOpen: boolean
    isLoading: boolean
}

const initialState: AppMenuState = {
    isOpen: false,
    isLoading: false
}

export const appMenuSlice = createSlice({
  name: 'appMenu',
  initialState,
  reducers: {
    openAppMenu: (state) => { 
        state.isOpen = true
    },
    closeAppMenu: (state) => { 
        state.isOpen = false
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { openAppMenu, closeAppMenu, setIsLoading } = appMenuSlice.actions

export default appMenuSlice.reducer