import { RentalCount } from "../../../../apis/ersys-data"
import { FormGroup, FormControlLabel, Skeleton, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"

interface StorePlanRentalsTabProps {
    rentalCounts: RentalCount[],
    onChange: (rc: RentalCount) => void
    formSpacing: number
}

const StorePlanRentalsTab: React.FC<StorePlanRentalsTabProps> = ({ rentalCounts, onChange }) => {
    const onValueChange = (rc: RentalCount, v: number) => {
        if (isNaN(v)) {
            v = 0
        }

        rc.value = Math.max(v, 0)
        onChange(rc)
    }

    
    
    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const showSkeleton = () => {
        return Boolean(!rentalCounts || rentalCounts.length < 1)
    }
    
    return(
    <Grid container alignItems="center" justifyContent="center" sx={{ height: 385}}>
        {showSkeleton() ? renderSkeleton() :
            <TableContainer component={Paper} sx={{ height: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rentalCounts.map((rc) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={rc.categoryId}>
                                <TableCell>
                                    {rc.name}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                            step: 0.05,
                                            min: 0,
                                            max: 100
                                        }}
                                        value={rc.value ?? 0}
                                        onChange={(e) => onValueChange(rc, parseInt(e.target.value, 10))}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </Grid>)
}

export default StorePlanRentalsTab