import './EditPaymentPlanPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { PatchAction, PaymentPlan, PaymentPlanDate } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import { useForm, useFormState } from 'react-hook-form'
import { useFirebase } from '../../../components/FirebaseProvider'
import PaymentPlanGeneralTab from '../../../components/payment-plans/PaymentPlanGeneralTab'
import PaymentPlanDatesTab from '../../../components/payment-plans/PaymentPlanDatesTab'
import PaymentPlansStoresTab from '../../../components/payment-plans/PaymentPlanStoresTab'

const formDefaults: PaymentPlan = {
    adminFee: 0,
    dates: [],
    firstPaymentPercentage: 0,
    id: 0,
    isPublic: false,
    isRenewalOnly: false,
    isUpgradeOnly: false,
    name: '',
    publicName: '',
    validUntil: ''
}

function EditPaymentPlanPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>()
    const [paymentDates, setPaymentDates] = useState<PaymentPlanDate[]>([])
    const [paymentDatesDirty, setPaymentDatesDirty] = useState<boolean>(false)
    const [paymentStores, setPaymentStores] = useState<number[]>([])
    const [paymentStoresDirty, setPaymentStoresDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<PaymentPlan>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !paymentPlan || paymentPlan.id === 0
    }

    const allowSave = () => {
        return isDirty || paymentDatesDirty || paymentStoresDirty
    }

    const getTabNames = (): string[] => {
        return [
            "General",
            "Dates",
            "Stores"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <PaymentPlanGeneralTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton}/>,
            <PaymentPlanDatesTab onChange={onDateChange} dates={paymentDates} showSkeleton={showSkeleton}/>,
            <PaymentPlansStoresTab ids={paymentStores} onChange={onStoreChange} showSkeleton={showSkeleton} />,
        ]
    }

    useEffect(() => {
        const resetFunc = async () => {
            if (paymentPlan == null) {
                return
            }
            reset(paymentPlan)
            await trigger()
        }

        resetFunc()
    }, [paymentPlan, reset, trigger])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const id = parseInt(params.id!, 10)
                const resp = await ersysDataApiServices.paymentPlansService.paymentPlansGet(id)
                setPaymentPlan(resp.data)
                reset(resp.data)

                setPaymentDates(resp.data.dates)
                setPaymentDatesDirty(false)

                const storesResp = await ersysDataApiServices.paymentPlansService.paymentPlansListStores(id)
                setPaymentStores(storesResp.data.stores)
                const newStoresResp = await ersysDataApiServices.paymentPlansService.paymentPlansListStores(id)
                setPaymentStores(newStoresResp.data.stores)
                setPaymentStoresDirty(false)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting payment plan data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setPaymentPlan, ersysDataApiServices.paymentPlansService, params.id, reset, isReady])

    const onDateChange = (ds: PaymentPlanDate[]) => {
        setPaymentDates(ds)
        setPaymentDatesDirty(true)
    }

    const onStoreChange = (ss: number[]) => {
        setPaymentStores(ss)
        setPaymentStoresDirty(true)
    }

    const Save = async (s: PaymentPlan) => {
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof PaymentPlan>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }

        try {
            var plan: PaymentPlan | null = null
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.paymentPlansService.paymentPlansUpdate(s.id, { actions: patchSet })
                plan = resp.data
            }

            if (paymentDatesDirty) {
                const dtos = paymentDates.map(d => {
                    return {...d, 'id': undefined}
                })
                const datesResp = await ersysDataApiServices.paymentPlansService.paymentPlansSetDateList(s.id, { dates: dtos })
                plan = datesResp.data
                setPaymentDatesDirty(false)
            }

            if (paymentStoresDirty) {
                const storesResp = await ersysDataApiServices.paymentPlansService.paymentPlansSetStoreList(s.id, { stores: paymentStores })
                plan = storesResp.data
                setPaymentStoresDirty(false)
            }

            if(plan) {
                setPaymentPlan(plan)
                reset(plan)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while updating payment plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Payment Plan">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Payment Plan: ${paymentPlan?.name ?? ""}`}>
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditPaymentPlanPage