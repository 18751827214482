import { Backdrop, CircularProgress } from "@mui/material"

interface LoadingOveralyProps {
    isOpen: boolean
    color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
    size?: string
}

const LoadingOverlay: React.FC<LoadingOveralyProps> = ({isOpen, color, size}) => {
    return (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
        >
        <CircularProgress color={color ?? "inherit"} size={size ?? '5rem'}/>
        </Backdrop>
    )
}

export default LoadingOverlay