import { School } from "@mui/icons-material"
import { FormControl, Button, Container } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import {
  setIsRenewal,
  setOrderStepIndex,
  setSelectedStore,
} from "../../../features/order-workflow/Order"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import "./SelectStoreStep.scss"
import AsyncSelect from "react-select/async"
import { debounce } from "lodash"
import React from "react"

interface SelectStoreProps {
  currentIndex: number
}

const SelectStore: React.FC<SelectStoreProps> = ({ currentIndex }) => {
  const ersysPublicApiServices = useErsysPublicApi()
  const dispatch = useDispatch()
  const [storeId, setStoreId] = useState<number>(0)

  const SelectStore = async () => {
    dispatch(setSelectedStore(storeId))
    dispatch(setIsRenewal(false))
    dispatch(setOrderStepIndex(currentIndex + 1))
  }

  const disableSelectStore = () => {
    return storeId < 1
  }

  const loadAccountOptions = async (filter: string) => {
    if (!filter) {
      return []
    }
    const resp =
      await ersysPublicApiServices.lookupsService.lookupsStores(filter)
    return resp.data.stores.map((s) => {
      return {
        value: s.id,
        label: `${s.prefix}: ${s.fullName}`,
      }
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = React.useCallback(
    debounce((input, callback) => {
      loadAccountOptions(input).then((result) => callback(result))
    }, 500),
    [],
  )

  return (
    <Container maxWidth="sm">
      <h1>Select School</h1>
      <FormControl sx={{ width: "100%" }}>
        <AsyncSelect
          loadOptions={debouncedFetch}
          defaultOptions={true}
          onChange={(e: any) => setStoreId(e.value)}
        />
      </FormControl>
      <div className="select-account-btn-wrapper">
        <Button
          variant="contained"
          disabled={disableSelectStore()}
          onClick={SelectStore}
          endIcon={<School />}
        >
          Select
        </Button>
      </div>
    </Container>
  )
}

export default SelectStore
