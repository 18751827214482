import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { CustomTypes, Store } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"

interface StoreOrdersTabProps {
    control: Control<Store, any>
    showSkeleton: () => boolean,
    formSpacing: number
}

const customFieldTypeOptions = (Object.keys(CustomTypes) as Array<keyof typeof CustomTypes>).map(k => {
    return {
        label: k,
        value: CustomTypes[k].toString()
    } as FormInputDropdownOption
})

const StoreOrdersTab: React.FC<StoreOrdersTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name="custom1"
                            control={control}
                            label="Field 1 Name"
                            rules={{ maxLength: 50 }}
                        />
                        <FormInputDropdown 
                        name="custom1Type"
                        control={control} 
                        label="Field 1 Type"
                        options={customFieldTypeOptions} 
                        spacing={formSpacing}
                        />
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="isPassphraseRequired"
                            control={control}
                            label="Order Passphrase Enabled"
                        />
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="isLocationEnabledOnOrder"
                            control={control}
                            label="Location Entry Enabled"
                        />
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="isRoomEnabledOnOrder"
                            control={control}
                            label="Room Entry Enabled"
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="isCustom1Enabled"
                            control={control}
                            label="Field 1 Enabled"
                        />
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="hidePricing"
                            control={control}
                            label="Hide Pricing"
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"passphrase"}
                            control={control}
                            label={"Order Passphrase"}
                            rules={{ maxLength: 50 }}
                        />
                        <FormInputCheckbox 
                            spacing={formSpacing}
                            name="isLocationMandatoryOnOrder"
                            control={control}
                            label="Location Entry Mandatory"
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default StoreOrdersTab