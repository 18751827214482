import { Box, Button } from "@mui/material"
import { MinimalStorePlan, PatchAction, RentalCount, StorePlan, StorePlanAddon } from "../../../apis/ersys-data"
import TabViewComponent from "../../tabs/TabViewComponent"
import { SaveSharp } from "@mui/icons-material"
import { useForm, useFormState } from "react-hook-form"
import { useEffect, useState } from "react"
import "./StorePlanComponent.scss"
import StorePlanPlanTab from "./tabs/StorePlanPlanTab"
import { useErsysDataApi } from "../../ErsysDataApiProvider"
import { useParams } from "react-router-dom"
import { showError } from "../../../features/errors/Errors"
import { useDispatch } from "react-redux"
import StorePlanRentalsTab from "./tabs/StorePlanRentalsTab"
import StorePlanAddonsTab from "./tabs/StorePlanAddonsTab"

interface StorePlanComponentProps {
    formSpacing: number
    storePlanId?: number
    setStorePlans: (plans: MinimalStorePlan[]) => void
    setLoading: (b: boolean) => void
}

const defaults: StorePlan = {
    currentCost: 0,
    id: 0,
    isAvailableAsAddon: false,
    isFaculty: false,
    isProrationShutoff: false,
    isRenewal: false,
    isVisible: false,
    publicName: "",
    referenceName: "",
    storeCost: 0,
    visualOrder: 0,
    weekCount: 0
}

const StorePlanComponent: React.FC<StorePlanComponentProps> = ({ formSpacing, storePlanId, setStorePlans, setLoading }) => {
    const ersysDataApiServices = useErsysDataApi()
    const [storePlan, setStorePlan] = useState<StorePlan>()
    const [rentalCounts, setRentalCounts] = useState<RentalCount[]>([])
    const [storePlanAddons, setStorePlanAddons] = useState<StorePlanAddon[]>([])
    const [addonsDirty, setAddonsDirty] = useState<boolean>(false)
    const [rcDirty, setRcDirty] = useState<boolean>(false)
    const params = useParams()
    const dispatch = useDispatch()
    const { handleSubmit, reset, control} =
        useForm<StorePlan>({
            defaultValues: defaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const [currentId, setCurrentId] = useState<number>(0)

    useEffect(() => {
        if (!storePlanId) {
            return
        }
        setLoading(true)
        const fetch = async () => {
            try {
                const storeId = parseInt(params.id!, 10)
                const storePlanResp = await ersysDataApiServices.storesService.storesGetPlan(storeId, storePlanId)
                setStorePlan(storePlanResp.data)
                const rentalCountsResp = await ersysDataApiServices.storesService.storesGetRentalCounts(storeId, storePlanId)
                setRentalCounts(rentalCountsResp.data.counts)
                setRcDirty(false)
                const addonsResp = await ersysDataApiServices.storesService.storesGetPlanAddons(storeId, storePlanId)
                setStorePlanAddons(addonsResp.data.addons)
                setAddonsDirty(false)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }

        fetch()
    }, [setLoading, storePlanId, setStorePlan, setRentalCounts, setStorePlanAddons, dispatch, ersysDataApiServices.storesService, params.id])
    
    useEffect(() => {
        if (!storePlan) {
            return
        }
        if (storePlan.id !== currentId) {
            setCurrentId(storePlan.id)
            reset(storePlan)
        }
    }, [storePlan, currentId, reset, setCurrentId])

    const getTabNames = (): string[] => {
        return [
            "Plan",
            "Addons",
            "Linen Rental"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            (<StorePlanPlanTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />),
            (<StorePlanAddonsTab formSpacing={formSpacing} onChange={onAddonsChange} addons={storePlanAddons}/>),
            (<StorePlanRentalsTab formSpacing={formSpacing} onChange={onRentalCountsChange} rentalCounts={rentalCounts}/>),
        ]
    }

    const onRentalCountsChange = (rc: RentalCount) => {
        const newRentalCounts = rentalCounts.map(r => {
            if (r.categoryId === rc.categoryId) {
                r.value = rc.value
                return r
            }
            return r
        })
        setRentalCounts(newRentalCounts)
        setRcDirty(true)
    }

    const onAddonsChange = (na: StorePlanAddon) => {
        const newAddons = storePlanAddons.map(a => {
            if (a.planId === na.planId) {
                a.isEnabled = na.isEnabled
                a.overrideType = na.overrideType
                a.overrideValue = na.overrideValue
                a.storeOverrideType = na.storeOverrideType
                a.storeOverrideValue = na.storeOverrideValue
                return a
            }
            return a
        })
        setStorePlanAddons(newAddons)
        setAddonsDirty(true)
    }

    const allowSave = () => {
        return isDirty || rcDirty || addonsDirty
    }

    const showSkeleton = () => {
        return Boolean(storePlanId && !storePlan)
    }

    const save = async (s: StorePlan) => {
        const storeId = parseInt(params.id!, 10)
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof StorePlan>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }
        try {
            if (patchSet.length > 0) {
                await ersysDataApiServices.storesService.storesUpdatePlan(storeId, storePlanId!, { actions: patchSet })    
            }

            if(rcDirty) {
                await ersysDataApiServices.storesService.storesOverwriteRentalCounts(storeId, storePlanId!, { counts: rentalCounts })                
            }

            if(addonsDirty) {
                await ersysDataApiServices.storesService.storesOverwritePlanAddons(storeId, storePlanId!, { addons: storePlanAddons})
            }

            //always reset to clean after a save
            // Plan
            const storeplanResp = await ersysDataApiServices.storesService.storesGetPlan(storeId, storePlanId!)
            setStorePlan(storeplanResp.data)
            reset(storeplanResp.data)
            const listResp = await ersysDataApiServices.storesService.storesListPlans(storeId)
            setStorePlans(listResp.data.plans)

            // Rentals
            const rentalResp = await ersysDataApiServices.storesService.storesGetRentalCounts(storeId, storePlanId!)
            setRentalCounts(rentalResp.data.counts)
            setRcDirty(false)

            // Addons
            const addonsResp = await ersysDataApiServices.storesService.storesGetPlanAddons(storeId, storePlanId!)
            setStorePlanAddons(addonsResp.data.addons)
            setAddonsDirty(false)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while saving store plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={{ height: "100%" }}>
            <TabViewComponent name='plan' tabNames={getTabNames()} tabs={getTabComponents()} />
            <div className="store-plan-save-btn">
                <Button
                    variant="contained"
                    disabled={!allowSave()}
                    onClick={handleSubmit(save)}
                    endIcon={<SaveSharp />}
                >
                    Save Plan Changes
                </Button>
            </div>
        </Box>
    )
}

export default StorePlanComponent