import { Box, Fab, FormGroup, Grid, Skeleton, Tooltip, Typography } from "@mui/material"
import { Account, CardOnFile } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { AddCardSharp } from "@mui/icons-material"
import CreditCardDisplayComponent from "../CreditCardDisplayComponent"
import "react-credit-cards-2/dist/lib/styles.scss"
import CreditCardComponent, { CreditCardToken } from "../order-form/payments/CreditCardComponent/CreditCardComponent"

interface AccountCardOnFileTabProps {
    formSpacing: number
    account?: Account
}

const AccountCardOnFileTab: React.FC<AccountCardOnFileTabProps> = ({account}) => {
    const ersysDataApiServices = useErsysDataApi()
    const [card, setCard] = useState<CardOnFile>()
    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const accountId = parseInt(params.id!, 10)
                var resp = await ersysDataApiServices.accountsService.accountsGetCardOnFle(accountId)
                setCard(resp.data)
            }
            catch (ex: any) {
                if(ex.response && ex.response.status && ex.response.status === 404) {
                    // not having a card is a legal state
                    return
                }
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting card data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setCard, ersysDataApiServices.accountsService, params.id])

    const showSkeleton = () => {
        return card && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <Skeleton variant='rectangular' width={200} height={100} key={`skele-${i}`} sx={{ margin: 1 }} />
                ))}
            </FormGroup>
        )
    }

    const replaceCreditCard = async (token: CreditCardToken | undefined, error: string) => {
        setIsCreateVisible(false)
        if(!error && !token) {
            return
        }
        if(error) {
            dispatch(
                showError({
                    title: "Error Saving Card",
                    message: error,
                }),
            )
        }

        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            var resp = await ersysDataApiServices.accountsService.accountsReplaceCardOnFile(accountId,
                {
                    brand: token!.brand,
                    expirationMonth: token!.expMonth,
                    expirationYear: token!.expYear,
                    lastFour: token!.lastFour,
                    token: token!.token
                }
            )
            setCard(resp.data)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while saving card. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <CreditCardComponent onClose={replaceCreditCard} open={isCreateVisible} />
            {showSkeleton() ? renderSkeleton() :
                <Grid container alignItems="center" justifyContent="center">
                    <Tooltip title="Add Card">
                        <Fab
                            onClick={() => setIsCreateVisible(true)}
                            color="primary"
                            aria-label="add"
                            sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                            <AddCardSharp />
                        </Fab>
                    </Tooltip>
                    {(!card || !account) && <Typography>No Card On File</Typography>}
                    {card && account &&
                        <CreditCardDisplayComponent 
                            brand={card.brand}
                            expiryMonth={card.expirationMonth}
                            expiryYear={card.expirationYear}
                            lastFour={card.lastFour}
                            name={`${account.billingFirstName} ${account.billingLastName}`}                       
                        />
                    }
                </Grid>
            }
        </Box>
    )
}

export default AccountCardOnFileTab