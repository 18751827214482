import { ParsedToken } from "firebase/auth"
import { useEffect, useState } from "react"
import { useFirebase } from "./FirebaseProvider"

export function usePermissions() {
    const [claims, setClaims] = useState<ParsedToken>()
    const { user } = useFirebase()

    useEffect(() => {
        const fetch = async () => {
            if (!user) {
                return
            }
            const idToken = await user?.getIdTokenResult()
            setClaims(idToken.claims)
        }
        fetch()
    }, [user])

    const hasPermissions = (requiredClaim: string): boolean => {
        if (!requiredClaim) {
            return true
        }

        if (!claims) {
            return false
        }

        return Boolean(claims[requiredClaim])
    }

    return { hasPermissions }
}