import { Button, Container, MenuItem, Paper, styled, TextField, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { CloudUploadSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"
import { MinimalStore } from "../../apis/ersys-public"
import { useFirebase } from "../FirebaseProvider"
import NotificationModal from "../NotificationModal"

const AdministrativeToolsCreateAccountsTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [notificationIsVisible, setNotificationIsVisible] = useState<boolean>(false)
    const [notificationMessage, setNotificationMessage] = useState<string>("")
    const [selectedStore, setSelectedStore] = useState<number>(0)
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [id, setId] = useState<string>("")
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var storesResp = await ersysDataApiServices.storesService.storesList()
                setStores(storesResp.data.stores)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting Store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setStores, ersysDataApiServices.storesService, isReady])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const downloadChecklist = async () => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkDownloadCreateAccountsTemplate({
                responseType: 'blob'
            })

            const csvBlob = new Blob([response.data], { type: "text/csv" })
            const url = window.URL.createObjectURL(csvBlob)

            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `create_accounts.csv`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }
    
    const downloadResults = async (id: string) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkGetCreateAccountsResults(id)
            setNotificationMessage(`${response.data.start} - ${response.data.end}`)
            setNotificationIsVisible(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting results. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const handleUpload = async (fs: FileList | null) => {
        if(!fs) {
            return
        }

        const files = Array.from(fs)
        if(files.length < 1) {
            return
        }

        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkBulkCreateAccounts(files[0], selectedStore.toString())
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while uploading data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <NotificationModal
                message={notificationMessage}
                open={notificationIsVisible}
                title="Accounts created"
                onClose={() => setNotificationIsVisible(false)}
            />
            <AdministrativeToolsWaitOnProcessModal 
            id={id}  
            onCompleted={() => downloadResults(id)}
            onClose={() => setShowWaitingModal(false)} 
            open={showWaitingModal} 
            title="Creating Accounts"
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 1:</Typography>
                <Button sx={{ margin: 2}} onClick={() => downloadChecklist()}>Download Template</Button>
            </Paper>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 2:</Typography>
                <Typography sx={{ margin: 2}}>Fill out template</Typography>
            </Paper>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 3:</Typography>
                <Typography sx={{ margin: 2}}>Select Store</Typography>
                { isReady && <TextField
                    select
                    sx={{ width: 500, marginBottom: 2, marginTop: 2}}
                    onChange={e => setSelectedStore(parseInt(e.target.value))}
                    value={selectedStore}
                    label="Select School"
                    size="small">
                    <MenuItem key={0} value={0}>
                        Select Store
                    </MenuItem>
                    {
                        stores && stores.map(
                            s => <MenuItem key={s.id} value={s.id}>
                                {s.fullName}
                            </MenuItem>
                        )
                    }
                </TextField> }
            </Paper>
            <Paper sx={{ padding: 2, margin: 2 }}>
                <Typography variant="h5">Step 4:</Typography>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadSharp />} 
                    sx={{ margin: 2}}
                    disabled={!selectedStore || selectedStore === 0}
                >
                    Upload file
                    <VisuallyHiddenInput  accept="text/csv" type="file" onClick={e => (e.target as any).value = null} onChange={(e) => handleUpload(e.target.files)}/>
                </Button>
            </Paper>
        </Container>
    )
}

export default AdministrativeToolsCreateAccountsTab