
import axios from 'axios';

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if (error.response.status === 401) {
        // we've outlived our login, logout
        window.location.replace("/logout")
    }
    return Promise.reject(error);
});

export default axios;