import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { PaymentPlan } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import FormInputNumberIntToDollars from "../forms/FormInputNumberIntToDollars"
import FormInputDatePicker from "../forms/FormInputDatePicker"

interface PaymentPlanGeneralTabProps {
    control: Control<PaymentPlan, any>
    showSkeleton: () => boolean
    formSpacing: number
}

const PaymentPlanGeneralTab: React.FC<PaymentPlanGeneralTabProps> = ({ control, showSkeleton, formSpacing }) => {
    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name="name"
                            control={control}
                            label="Name"
                            rules={{ required: "Required Field", maxLength: 25 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name="publicName"
                            label="Public Name"
                            control={control}
                            rules={{ required: "Required Field", maxLength: 25 }}
                        />
                        <FormInputNumberIntToDollars
                            spacing={formSpacing}
                            name={"adminFee"}
                            control={control}
                            label={"Admin Fee"}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"firstPaymentPercentage"}
                            control={control}
                            label={"First Payment (%)"}
                            rules={{ min: 0, max: 100 }}
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputDatePicker
                            spacing={formSpacing}
                            name="validUntil"
                            control={control}
                            label="Valid Until"
                            rules={{ required: "Required Field", maxLength: 25 }}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name="isPublic"
                            control={control}
                            label="Public"
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name="isRenewalOnly"
                            control={control}
                            label="Renewal Only"
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name="isUpgradeOnly"
                            control={control}
                            label="Upgrade Only"
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default PaymentPlanGeneralTab