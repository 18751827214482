import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useFirebase } from "../../components/FirebaseProvider"

export default function Logout() {
    const { logout } = useFirebase()
    const navigate = useNavigate()
    useEffect(() => {
        logout().then(() => {
            navigate("/login")
        })
    })
    return (<></>)
}