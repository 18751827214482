// src/form-component/FormInputText.tsx
import { Controller, FieldValues, RegisterOptions } from "react-hook-form"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"

export interface FormInputNumberIntToFloatProps {
  name: string
  control: any
  label: string
  spacing: number
  customOnChange?: (event: any) => void | undefined
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined
}

const FormInputNumberIntToFloat: React.FC<FormInputNumberIntToFloatProps> = ({
  name,
  control,
  label,
  spacing,
  customOnChange,
  rules,
}) => {
  const theme = useTheme()
  const [currentValue, setCurrentValue] = useState<number>(0)
  const [waitingForBlur, setWaitingForBlur] = useState<boolean>(false)

  const transformInput = (n: number) =>{
    return n/100
  }

  const transformOutput = (n: number) =>{
    return Math.round(n*100)
  }

  const transformOnChange = (value: number) => {
    return transformOutput(value).toString()
  }
  
  const handleChange = (value: number) => {
    setCurrentValue(value)
    setWaitingForBlur(true)
  }

  return (
    <div
      style={{
        marginTop: theme.spacing(spacing),
        marginBottom: theme.spacing(spacing),
        maxWidth: "100%",
      }}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            helperText={error ? error.message : null}
            
            size="small"
            error={!!error}
            type="number"
            onChange={(e) => { handleChange(parseFloat(e.target.value)) }}
            onBlur={() => {
              if (customOnChange) {
                customOnChange(transformOnChange(currentValue))
              }
              onChange(transformOnChange(currentValue))
              setWaitingForBlur(false)
            }}
            value={waitingForBlur ? currentValue : transformInput(value)}
            fullWidth
            label={label}
            variant="outlined"
          />
        )}
      />
    </div>
  )
}

export default FormInputNumberIntToFloat
