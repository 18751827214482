import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Account } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputCheckbox from "../forms/FormInputCheckbox"

interface AccountAccountingTabProps {
    control: Control<Account, any>
    showSkeleton: () => boolean
    formSpacing: number
}

const AccountAccountingTab: React.FC<AccountAccountingTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"discount"}
                            control={control}
                            label={"Piece Price Discount"}
                            rules={{ min: 0, max: 100}}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isFlaggedNoMarking"}
                            control={control}
                            label={"Disable Marking In"}
                        />   
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isSuspended"}
                            control={control}
                            label={"Is Suspended"}
                        />                     
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default AccountAccountingTab