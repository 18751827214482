import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PermissionsSet {
  permissions: Record<string, boolean>
  permissionsLoaded: boolean
}

const initialState: PermissionsSet = {
  permissions: {},
  permissionsLoaded: false
}

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<string[]>) => { 
        state.permissions = action.payload.reduce((obj, item) => {
            return {
                ...obj,
                [item]: true,
            }
        }, {})
        state.permissionsLoaded = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPermissions } = permissionSlice.actions

export default permissionSlice.reducer