import { Configuration } from "./interfaces"
// NOTE: This file is to be replaced upon deployment. Any changes here do not propagate to deployments, these are entirely dev values.

export const config: Configuration = process.env.REACT_APP_DEV_MODE  === "true" ? {
    firebase: {
        apiKey: process.env.REACT_APP_FIRE_BASE_API_KEY ?? "",      
        authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN ?? "",      
        projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID ?? "",      
        storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET ?? "",      
        messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID ?? "",      
        appId: process.env.REACT_APP_FIRE_BASE_APP_ID ?? "",      
        measurementId: process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID ?? ""
    },
    payrollApi: {
        host: process.env.REACT_APP_ERSYS_API_HOST ?? ""
    },
    stripe: {
        publicKey: process.env.REACT_APP_PUBLIC_STRIPE_KEY ?? ""
    }
} : (window as any).prodConfig