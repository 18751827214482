import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Store } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import FormInputNumberIntToFloat from "../forms/FormInputNumberIntToFloat"

interface StoreAccountingTabProps {
    control: Control<Store, any>
    showSkeleton: () => boolean,
    formSpacing: number
}

const StoreAccountingTab: React.FC<StoreAccountingTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"rollupAccount"}
                            control={control}
                            label={"Student Rollup Account"}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"facultyRollupAccount"}
                            control={control}
                            label={"Faculty Rollup Account"}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"commercialRollupAccount"}
                            control={control}
                            label={"Commercial Rollup Account"}
                        />
                        <FormInputNumberIntToFloat
                            spacing={formSpacing}
                            name={"taxRate"}
                            control={control}
                            label={"Tax Rate"}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"bagsPerWeek"}
                            control={control}
                            label={"Bags Per Week"}
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputCheckbox 
                        name="rollupEnabled"
                        control={control} 
                        label="Student Rollup Enabled"
                        spacing={formSpacing}                       
                        />
                        <FormInputCheckbox 
                        name="facultyRollupEnabled"
                        control={control} 
                        label="Faculty Rollup Enabled"
                        spacing={formSpacing}                       
                        />
                        <FormInputCheckbox 
                        name="commercialRollupEnabled"
                        control={control} 
                        label="Commercial Rollup Enabled"
                        spacing={formSpacing}                       
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default StoreAccountingTab