import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Discount, MinimalGlobalPlan, MinimalStore } from "../../apis/ersys-data/api"
import { Control, useWatch } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputCheckbox from "../forms/FormInputCheckbox"
import FormInputNumberIntToDollars from "../forms/FormInputNumberIntToDollars"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"

interface DiscountGeneralTabProps {
    control: Control<Discount, any>
    stores?: MinimalStore[]
    plans?: MinimalGlobalPlan[],
    showSkeleton: () => boolean
    formSpacing: number
}

const DiscountGeneralTab: React.FC<DiscountGeneralTabProps> = ({ control, stores, plans, showSkeleton, formSpacing }) => {
    const isPercentage = useWatch({ control, name: "isPercentage" })


    const storeOptions = (): FormInputDropdownOption[] => {
        if (!stores) {
            return []
        }
        return [
            {
                label: "Select Store",
                value: 0
            },
            ...stores.map(s => {
                return {
                    label: s.fullName,
                    value: s.id
                }
            })
        ]
    }

    const planOptions = (): FormInputDropdownOption[] => {
        if (!plans) {
            return []
        }
        return [
            {
                label: "Select Plan",
                value: 0
            },
            ...plans.map(s => {
                return {
                    label: s.name,
                    value: s.id
                }
            })
        ]
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name="description"
                            control={control}
                            label="Description"
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name="isPercentage"
                            label="Percentage"
                            control={control}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isEnabled"}
                            control={control}
                            label={"Enabled"}
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        {isPercentage ?
                            <FormInputNumber
                                spacing={formSpacing}
                                name="value"
                                label="Value (%)"
                                control={control}
                                rules={{ min: 0, max: 100 }}
                            />
                            : <FormInputNumberIntToDollars
                                spacing={formSpacing}
                                name="value"
                                label="Value"
                                control={control}
                            />
                        }
                        {isPercentage &&
                            <FormInputCheckbox
                                spacing={formSpacing}
                                name={"isPercentOfTotalOrder"}
                                control={control}
                                label={"Percent Of Total Order"}
                            />
                        }

                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"restrictedToStore"}
                            control={control}
                            label={"Restrict To Store"}
                            options={storeOptions()}
                        />

                        <FormInputDropdown
                            spacing={formSpacing}
                            name={"restrictedToPlan"}
                            control={control}
                            label={"Restrict to Plan"}
                            options={planOptions()}
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default DiscountGeneralTab