import { alpha, CSSObject, styled, Theme, useTheme } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Typography from "@mui/material/Typography"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import IconButton from "@mui/material/IconButton"
import * as React from "react"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import Divider from "@mui/material/Divider"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { MenuItem, menuSet } from "../config/menu"
import PermissionsGatherer from "./PermissionsGatherer"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../app/store"
import { Link } from "react-router-dom"
import { closeAppMenu, openAppMenu } from "../features/menus/AppMenu"
import LoadingOverlay from "./LoadingOverlay"
import { Fade, InputBase, Tooltip } from "@mui/material"
import { Search } from "@mui/icons-material"
import { useDebounce } from "./useDebounce"
import { useEffect, useState } from "react"
import ErrorModal from "./ErrorModal"
import { resetVisible } from "../features/errors/Errors"
import { usePermissions } from "./usePermissions"

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}))

interface AppMenuProps {
  title: string
  searchSetter?: (searchValue: string) => void
  children?: React.ReactNode
}

const AppMenu: React.FC<AppMenuProps> = ({
  title,
  children,
  searchSetter,
}) => {
  const errorIsOpen = useSelector((state: RootState) => state.errors.isVisible)
  const errorMessage = useSelector((state: RootState) => state.errors.message)
  const errorTitle = useSelector((state: RootState) => state.errors.title)
  const isLoading = useSelector((state: RootState) => state.appMenu.isLoading)
  const isOpen = useSelector((state: RootState) => state.appMenu.isOpen)
  const dispatch = useDispatch()
  const theme = useTheme()
  const [search, setSearch] = useState("")
  const debouncedSearch: string = useDebounce(search, 500)
  const { hasPermissions } = usePermissions()

  useEffect(() => {
    if (searchSetter) {
      searchSetter(debouncedSearch)
    }
  }, [debouncedSearch, searchSetter])

  const handleDrawerOpen = () => {
    dispatch(openAppMenu())
  }

  const handleDrawerClose = () => {
    dispatch(closeAppMenu())
  }

  const renderMenuItem = (menuItem: MenuItem) => {
    return (
      ( hasPermissions(menuItem.permissionRequired) ?
        <ListItem
          key={menuItem.title}
          disablePadding
          sx={{ display: "block" }}
          component={Link}
          to={menuItem.path}
          style={{ textDecoration: "none", color: theme.palette.primary.dark }}
        >
          <Tooltip 
            title={menuItem.title}   
            disableHoverListener={isOpen} 
            arrow 
            TransitionComponent={Fade}
            placement="right">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {menuItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={menuItem.title}
                sx={{ opacity: isOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        : <Box key={menuItem.title}></Box>
      )
    )
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <PermissionsGatherer />
      <LoadingOverlay isOpen={isLoading ?? false} color="secondary" />
      <ErrorModal
        message={errorMessage}
        open={errorIsOpen}
        title={errorTitle}
        onClose={() => dispatch(resetVisible())}
      />
      <AppBar position="fixed" open={isOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(isOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            {title}
          </Typography>
          {searchSetter && (
            <SearchBar>
              <SearchIconWrapper>
                <Search />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </SearchBar>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={isOpen}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>{menuSet.userOperations.map((o) => renderMenuItem(o))}</List>
        {menuSet.userOperations.length > 0 ? <Divider /> : <Box></Box>}
        
        <List>
          {menuSet.administrationOperations.map((o) => renderMenuItem(o))}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "grey.300",
          minHeight: "100vh",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}

export default AppMenu
