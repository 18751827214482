import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { AccountPlanEnableRequest, MinimalStorePlan } from "../../../apis/ersys-data"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, darken } from "@mui/material"
import { useState } from "react"

interface PlanAddModalProps {
  open: boolean
  plans: MinimalStorePlan[]
  onClose: () => void
  onCreate: (req: AccountPlanEnableRequest) => Promise<void>
}

const PlanAddModal: React.FC<PlanAddModalProps> = ({
  open,
  plans,
  onCreate,
  onClose,
}) => {
  const [currentId, setCurrentId] = useState<number>(0)

  const handleCreate = () => {
    onCreate({ id: currentId })
  }

  const truncate = (str: string, len: number): string => {
    return str.length > len ? str.substring(0, len - 3) + "..." : str;
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Add Plan</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ height: 450, width: 500 }} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(plan.id === currentId) && { backgroundColor: darken("#FFF", .1) } }}
                  key={plan.id}
                  onClick={() => setCurrentId(plan.id)}>
                  <TableCell>{truncate(plan.publicName, 75)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={e => handleCreate()} disabled={currentId === 0} autoFocus>
          Ok
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanAddModal
