import { Container, Fab, FormControlLabel, FormGroup, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, darken } from "@mui/material"
import { CreatePlanRequest, MinimalGlobalPlan, MinimalStorePlan } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { AddSharp, DeleteSharp } from "@mui/icons-material"
import { useDebounce } from "../useDebounce"
import { showError } from "../../features/errors/Errors"
import StorePlanComponent from "./plans/StorePlanComponent"
import StorePlanCreationModal from "./modals/StorePlanCreationModal"
import NagModal from "../NagModal"

interface StorePlansTabProps {
    formSpacing: number
    storePlans: MinimalStorePlan[]
    globalPlans: MinimalGlobalPlan[]
    setStorePlans: (plans: MinimalStorePlan[]) => void
    showSkeleton: () => boolean
}

const StorePlansTab: React.FC<StorePlansTabProps> = ({ formSpacing, storePlans, globalPlans, setStorePlans, showSkeleton }) => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [filteredStorePlans, setFilteredStorePlans] = useState<MinimalStorePlan[]>([])
    const [filter, setFilter] = useState<string>("")
    const [storePlanId, setStorePlanId] = useState<number>(0)
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const [nagIsVisible, setNagIsVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()
    const debouncedFilter: string = useDebounce(filter, 250)

    useEffect(() => {
        if (!debouncedFilter) {
            setFilteredStorePlans(storePlans.slice(0, 50))
        }
        setFilteredStorePlans(
            storePlans
                .filter(l => l.publicName.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase()) || l.referenceName.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                .slice(0, 50)
        )
    }, [storePlans, debouncedFilter])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }

    const createPlan = async (req: CreatePlanRequest) => {
        setLoading(true)
        try {
            const storeId = parseInt(params.id!, 10)
            setShowCreation(false)
            await ersysDataApiServices.storesService.storesCreatePlan(storeId, req)
            const resp = await ersysDataApiServices.storesService.storesListPlans(storeId)
            setStorePlans(resp.data.plans)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while creating plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    const archivePlan = async (id: number) => {
        setLoading(true)
        try {
            const storeId = parseInt(params.id!, 10)
            setNagIsVisible(false)
            await ersysDataApiServices.storesService.storesArchivePlan(storeId, id)
            const resp = await ersysDataApiServices.storesService.storesListPlans(storeId)
            setStorePlans(resp.data.plans)
            setStorePlanId(0)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while archiving plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={4}>
                {showSkeleton() ? renderSkeleton() :
                    <TableContainer sx={{ height: 550 }} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <p style={{ display: "inline-block" }}>Name</p>
                                        <TextField
                                            size="small"
                                            sx={{ display: "inline-block", width: "75%", marginLeft: 2 }}
                                            value={filter}
                                            placeholder='Search'
                                            onChange={(e) => setFilter(e.target.value)} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredStorePlans.map((plan) => (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(plan.id === storePlanId) && { backgroundColor: darken("#FFF", .1) } }}
                                        key={plan.id}
                                        onClick={() => setStorePlanId(plan.id)}>
                                        <TableCell>{truncate(plan.publicName, 50)} ({truncate(plan.referenceName, 50)})</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>

            <Grid item xs={8}>
                <Container component={Paper} sx={{ height: 550, marginLeft: 1 }}>
                    {storePlanId > 0 && (
                        <StorePlanComponent formSpacing={formSpacing} storePlanId={storePlanId} setLoading={setLoading} setStorePlans={setStorePlans} />
                    )}
                </Container>
            </Grid>

            <Tooltip title="Add Plan">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            { storePlanId > 0 && 
            <Tooltip title="Archive Plan">
                <Fab
                    onClick={() => setNagIsVisible(true)}
                    color="warning"
                    aria-label="delete"
                    sx={{ position: 'absolute', bottom: 25, right: 100 }}>
                    <DeleteSharp />
                </Fab>
            </Tooltip>}
            
            <StorePlanCreationModal            
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={createPlan}
                plans={globalPlans}
            />
            <NagModal
                message='Are you sure?'
                title="Archive Plan"
                onCancel={() => { setNagIsVisible(false) }}
                onOk={() => archivePlan(storePlanId)}
                open={nagIsVisible}
            />

        </Grid>
    )
}

export default StorePlansTab