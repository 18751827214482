import { Box, ButtonGroup, Fab, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { AccountPlan, AccountPlanEnableRequest, MinimalStorePlan } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { AddSharp, HideSource } from "@mui/icons-material"
import { usePermissions } from "../usePermissions"
import NagModal from "../NagModal"
import PlanAddModal from "./modals/PlanAddModal"

interface AccountPlansTabProps {
    formSpacing: number
}

const AccountPlansTab: React.FC<AccountPlansTabProps> = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [accountPlans, setAccountPlans] = useState<AccountPlan[]>([])
    const [storePlans, setStorePlans] = useState<MinimalStorePlan[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [accountPlan, setAccountPlan] = useState<number>(0)
    const dispatch = useDispatch()
    const [nagIsVisible, setNagIsVisible] = useState<boolean>(false)
    const [createIsVisible, setCreateIsVisible] = useState<boolean>(false)
    const { hasPermissions } = usePermissions()
    const params = useParams()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const accountId = parseInt(params.id!, 10)
                var accountResp = await ersysDataApiServices.accountsService.accountsGet(accountId)
                var accountPlansResp = await ersysDataApiServices.accountsService.accountsListPlans(accountId)
                setAccountPlans(accountPlansResp.data.plans)
                var storePlansResp = await ersysDataApiServices.storesService.storesListPlans(accountResp.data.storeId)
                setStorePlans(storePlansResp.data.plans)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting plans data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setAccountPlans, ersysDataApiServices.accountsService, ersysDataApiServices.storesService, params.id])

    const showSkeleton = () => {
        return accountPlans.length < 1 && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <Skeleton variant='rectangular' width={200} height={100} key={`skele-${i}`} sx={{ margin: 1 }} />
                ))}
            </FormGroup>
        )
    }

    const disablePlan = async () => {
        if(accountPlan === 0) {
            return
        }
        setNagIsVisible(false)
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsDisablePlan(accountId, accountPlan)
            const resp = await ersysDataApiServices.accountsService.accountsListPlans(accountId)
            setAccountPlans(resp.data.plans)
        } catch(ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while disabling plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
            setAccountPlan(0)
        }
    }

    const addPlan = async (req: AccountPlanEnableRequest) => {
        if(req.id === 0) {
            return
        }
        setCreateIsVisible(false)
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsEnablePlan(accountId, req)
            const resp = await ersysDataApiServices.accountsService.accountsListPlans(accountId)
            setAccountPlans(resp.data.plans)
        } catch(ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while adding plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <NagModal
                message='Are you sure?'
                title="Disable Plan"
                onCancel={() => { setNagIsVisible(false) }}
                onOk={() => disablePlan()}
                open={nagIsVisible}
            />
            <PlanAddModal onClose={() => setCreateIsVisible(false)} onCreate={addPlan} open={createIsVisible} plans={storePlans}/>
            {showSkeleton() ? renderSkeleton() :
                <Grid container alignItems="center" justifyContent="center">
                    {hasPermissions("AP:AP:MP") && <Tooltip title="Add Plan">
                        <Fab
                            onClick={() => setCreateIsVisible(true)}
                            color="primary"
                            aria-label="add"
                            sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                            <AddSharp />
                        </Fab>
                    </Tooltip>}
                    <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accountPlans.map((plan, idx) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`${idx}-${plan.id}`}>
                                        <TableCell>
                                            <Typography>{plan.name}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                {hasPermissions("AP:AP:MP") && <Tooltip title="Disable">
                                                    <IconButton onClick={() => {
                                                        setNagIsVisible(true)
                                                        setAccountPlan(plan.id)
                                                    }}>
                                                        <HideSource></HideSource>
                                                    </IconButton>
                                                </Tooltip>}
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Box>
    )
}

export default AccountPlansTab