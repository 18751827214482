import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { GlobalPlan } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import FormInputNumber from "../forms/FormInputNumber"
import FormInputDatePicker from "../forms/FormInputDatePicker"
import FormInputTextArea from "../forms/FormInputTextArea"

interface GlobalPlanGeneralTabProps {
    control: Control<GlobalPlan, any>
    showSkeleton: () => boolean
    formSpacing: number
}

const GlobalPlanGeneralTab: React.FC<GlobalPlanGeneralTabProps> = ({ control, showSkeleton, formSpacing }) => {
    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name="name"
                            control={control}
                            label="Name"
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name="productionName"
                            control={control}
                            label="Production Name"
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputTextArea 
                            name="description"
                            control={control} 
                            label="Description"
                            spacing={formSpacing} 
                            rows={4} 
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputDatePicker
                            spacing={formSpacing} 
                            name="expiration"
                            control={control} 
                            label="Expiration"                       
                        />
                        <FormInputNumber 
                            name="tier"
                            control={control} 
                            label="Upgrade Tier"
                            spacing={formSpacing}                            
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default GlobalPlanGeneralTab