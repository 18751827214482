import { Container } from "@mui/material"
import AppMenu from "../../components/AppMenu"
import TabViewComponent from "../../components/tabs/TabViewComponent"
import { usePermissions } from "../../components/usePermissions"
import AdministrativeToolsUpdateStorePlansTab from "../../components/administrative-tools/AdministrativeToolsUpdateStorePlansTab"
import AdministrativeToolsCreateAccountsTab from "../../components/administrative-tools/AdministrativeToolsCreateAccountsTab"
import AdministrativeToolsRollupAccountsTab from "../../components/administrative-tools/AdministrativeToolsRollupAccountsTab"
import AdministrativeToolsAssignPlansTab from "../../components/administrative-tools/AdministrativeToolsAssignPlansTab"
import AdministrativeToolsCalcBalancesTab from "../../components/administrative-tools/AdministrativeToolsCalcBalancesTab"
import AdministrativeToolsMergeAccountsTab from "../../components/administrative-tools/AdministrativeToolsMergeAccountsTab"
import AdministrativeToolsAddTransactionTab from "../../components/administrative-tools/AdministrativeToolsAddTransactionTab"
import AdministrativeOnAccountChargingTab from "../../components/administrative-tools/AdministrativeOnAccountChargingTab"

function AdministrativeToolsPage() {
    const { hasPermissions } = usePermissions()

    const getTabNames = (): string[] => {
        const tabs = []
        
        if (hasPermissions("AP:ATP:USP")) {
            tabs.push("Update Store Plans")
        }
        
        if (hasPermissions("AP:ATP:CA")) {
            tabs.push("Create Accounts")
        }
        
        if (hasPermissions("AP:ATP:RA")) {
            tabs.push("Rollup Accounts")
        }
        
        if (hasPermissions("AP:ATP:AP")) {
            tabs.push("Assign Plans")
        }
        
        if (hasPermissions("AP:ATP:MA")) {
            tabs.push("Merge Accounts")
        }
        
        if (hasPermissions("AP:ATP:CS")) {
            tabs.push("Calc Balances")
        }
        
        if (hasPermissions("AP:ATP:PT")) {
            tabs.push("Post Transactions")
        }
        
        if (hasPermissions("AP:ATP:OAC")) {
            tabs.push("On Account Charging")
        }

        return tabs
    }

    const getTabComponents = (): JSX.Element[] => {
        const tabs = []

        if (hasPermissions("AP:ATP:USP")) {
            tabs.push(<AdministrativeToolsUpdateStorePlansTab />)
        }

        if (hasPermissions("AP:ATP:CA")) {
            tabs.push(<AdministrativeToolsCreateAccountsTab />)
        }
        
        if (hasPermissions("AP:ATP:RA")) {
            tabs.push(<AdministrativeToolsRollupAccountsTab />)
        }
        
        if (hasPermissions("AP:ATP:AP")) {
            tabs.push(<AdministrativeToolsAssignPlansTab />)
        }
        
        if (hasPermissions("AP:ATP:MA")) {
            tabs.push(<AdministrativeToolsMergeAccountsTab />)
        }
        
        if (hasPermissions("AP:ATP:CS")) {
            tabs.push(<AdministrativeToolsCalcBalancesTab />)
        }
        
        if (hasPermissions("AP:ATP:PT")) {
            tabs.push(<AdministrativeToolsAddTransactionTab />)
        }
        
        if (hasPermissions("AP:ATP:OAC")) {
            tabs.push(<AdministrativeOnAccountChargingTab />)
        }

        return tabs
    }

    return (
        <AppMenu title="Administrative Tools">
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()}/>
            </Container>
        </AppMenu>
    )
}

export default AdministrativeToolsPage