import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ErrorsState {
    title: string
    message: string
    isVisible: boolean
}

const initialState: ErrorsState = {
    title: "",
    message: "",
    isVisible: false
}

export interface ErrorRequest {
    title: string
    message: string
}

export const ErrorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<ErrorRequest>) => {
        state.title = action.payload.title
        state.message = action.payload.message
        state.isVisible = true
    },
    resetVisible: (state) => {
        state.isVisible = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { showError, resetVisible } = ErrorsSlice.actions

export default ErrorsSlice.reducer