import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { useEffect, useState } from "react"
import {
  setOrderStepIndex,
  setSelectedAccount,
} from "../../../features/order-workflow/Order"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import { showError } from "../../../features/errors/Errors"
import {
  PatchAction,
  Store,
  Location,
  Country,
  AccountCreationRequest,
  AccountType,
  PublicUserLinkCreationRequest,
} from "../../../apis/ersys-public"
import { setIsLoading } from "../../../features/menus/AppMenu"
import { useForm } from "react-hook-form"
import FormInputText from "../../forms/FormInputText"
import { Button, Container, Grid } from "@mui/material"
import dayjs from "dayjs"
import { AccountBox } from "@mui/icons-material"
import {
  FormInputDropdown,
  FormInputDropdownOption,
} from "../../forms/FormInputDropdown"
import { debounce } from "lodash"
import React from "react"

interface FormInput {
  // Student Info
  primaryFirstName: string
  primaryLastName: string
  primaryEmail: string
  primaryPhone: string
  graduationYear: number | string
  locationID: number | string
  room: string
  custom1: string

  // Billing Info
  billingFirstName: string
  billingLastName: string
  billingEmail: string
  billingPhone: string
  billingPostal: string
  billingCountry: string
  billingAddressLineOne: string
  billingAddressLineTwo: string
  billingCity: string
  billingState: string
}

const defaults: FormInput = {
  // Student Info
  primaryFirstName: "",
  primaryLastName: "",
  primaryEmail: "",
  primaryPhone: "",
  graduationYear: dayjs().year(),
  locationID: "",
  room: "",
  custom1: "",

  // Billing Info
  billingFirstName: "",
  billingLastName: "",
  billingEmail: "",
  billingPhone: "",
  billingPostal: "",
  billingCountry: "",
  billingAddressLineOne: "",
  billingAddressLineTwo: "",
  billingCity: "",
  billingState: "",
}

interface ModifyCustomerStepProps {
  currentIndex: number
}

const ModifyCustomerStep: React.FC<ModifyCustomerStepProps> = ({
  currentIndex,
}) => {
  const ersysPublicApiServices = useErsysPublicApi()
  const currentAccount = useSelector(
    (state: RootState) => state.order.selectedAccount,
  )
  const accountNeedsCreation = useSelector(
    (state: RootState) => state.order.accountNeedsCreation,
  )
  const accountCreationType = useSelector(
    (state: RootState) => state.order.accountCreationType,
  )
  const selectedStore = useSelector(
    (state: RootState) => state.order.selectedStore,
  )
  const [store, setStore] = useState<Store | undefined>(undefined)
  const [locations, setLocations] = useState<Location[]>([])
  const [countries, setCountries] = useState<Country[]>([])
  const [resetValues, setResetValues] = useState<FormInput>(defaults)
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control, trigger, setValue } =
    useForm<FormInput>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsLoading(true))
    const fetch = async () => {
      if (currentAccount === undefined && !accountNeedsCreation) {
        // return to select account step
        dispatch(setOrderStepIndex(0))
      }

      try {
        // Get school name
        const storeResponse =
          await ersysPublicApiServices.storesService.storesGet(
            selectedStore!,
          )
        setStore(storeResponse.data)

        // Get Dorms
        const locationResponse =
          await ersysPublicApiServices.storesService.storesGetLocations(
            selectedStore!,
          )
        setLocations(locationResponse.data.locations)

        // Get Countries
        const countryResponse =
          await ersysPublicApiServices.countriesService.countriesList()
        setCountries(countryResponse.data.countries ?? [])

        // reset form
        const vals = {
          // Student Info
          primaryFirstName: currentAccount?.primaryFirstName ?? "",
          primaryLastName: currentAccount?.primaryLastName ?? "",
          primaryEmail: currentAccount?.primaryEmail ?? "",
          primaryPhone: currentAccount?.primaryPhone ?? "",
          graduationYear: currentAccount?.graduationYear ?? "",
          locationID: currentAccount?.locationId ?? "",
          room: currentAccount?.room ?? "",
          custom1: currentAccount?.custom1 ?? "",

          // Billing Info
          billingFirstName: currentAccount?.billingFirstName ?? "",
          billingLastName: currentAccount?.billingLastName ?? "",
          billingEmail: currentAccount?.billingEmail ?? "",
          billingPhone: currentAccount?.billingPhone ?? "",
          billingPostal: currentAccount?.billingPostal ?? "",
          billingCountry: currentAccount?.billingCountry ?? "",
          billingAddressLineOne: currentAccount?.billingAddressLineOne ?? "",
          billingAddressLineTwo: currentAccount?.billingAddressLineTwo ?? "",
          billingCity: currentAccount?.billingCity ?? "",
          billingState: currentAccount?.billingState ?? "",
        }
        setResetValues(vals)
        reset(vals)
        await trigger()
      } catch (ex: any) {
        dispatch(
          showError({
            title: "Unexpected Error occurred",
            message:
              "An unexpected error occurred while getting accountdata. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      } finally {
        dispatch(setIsLoading(false))
      }
    }

    fetch()
  }, [
    trigger,
    currentAccount,
    accountNeedsCreation,
    selectedStore,
    dispatch,
    ersysPublicApiServices.storesService,
    ersysPublicApiServices.plansService,
    ersysPublicApiServices.countriesService,
    reset,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPostalChanged = React.useCallback(
    debounce((input, callback) => {
      postalChanged(input).then((result) => callback(result))
    }, 500),
    [],
  )

  const yearOptions = (): FormInputDropdownOption[] => {
    const years = Array.from(Array(10).keys()).map((x) => x + dayjs().year())
    return years.map((y) => {
      return {
        label: y.toString(),
        value: y,
      }
    })
  }

  const locationOptions = (): FormInputDropdownOption[] => {
    return locations.map((l) => {
      return {
        label: l.name,
        value: l.id,
      }
    })
  }

  const countryOptions = (): FormInputDropdownOption[] => {
    return countries.map((c) => {
      return {
        label: c.name,
        value: c.name,
      }
    })
  }

  const buildAccountCreationDto = (
    formInput: FormInput,
  ): AccountCreationRequest => {
    const usersToLink: PublicUserLinkCreationRequest[] = [
      {
        email: (formInput as any).billingEmail,
        isNew: true,
        isPasswordAutoGenerated: true,
      },
    ]
    return {
      accountType:
        accountCreationType === "faculty"
          ? AccountType.Faculty
          : AccountType.Student,
      primaryFirstName: (formInput as any).primaryFirstName,
      primaryLastName: (formInput as any).primaryLastName,
      primaryPhone: (formInput as any).primaryPhone,
      primaryEmail: (formInput as any).primaryEmail,
      storeId: selectedStore!,

      billingFirstName: (formInput as any).billingFirstName,
      billingLastName: (formInput as any).billingLastName,
      billingPhone: (formInput as any).billingPhone,
      billingEmail: (formInput as any).billingEmail,
      billingAddressLineOne: (formInput as any).billingAddressLineOne,
      billingAddressLineTwo: (formInput as any).billingAddressLineTwo,
      billingCity: (formInput as any).billingCity,
      billingState: (formInput as any).billingState,
      billingPostal: (formInput as any).billingPostal,
      billingCountry: (formInput as any).billingCountry,

      graduationYear: (formInput as any).graduationYear,
      custom1: (formInput as any).custom1,
      locationId:
        (formInput as any).locationID === ""
          ? null
          : (formInput as any).locationID,
      room: (formInput as any).room,

      usersToLink: usersToLink,
    }
  }

  const onCreate = async (formInput: FormInput) => {
    try {
      dispatch(setIsLoading(true))
      const accountCreationResp =
        await ersysPublicApiServices.accountsService.accountsCreate(
          buildAccountCreationDto(formInput),
        )
      dispatch(setSelectedAccount(accountCreationResp.data.account))
      dispatch(setOrderStepIndex(currentIndex + 1))
    } catch (ex: any) {
      dispatch(
        showError({
          title: "Unexpected Error occurred",
          message:
            "An unexpected error occurred while creating account. If problem persists please contact IT.",
        }),
      )
      console.log(ex)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const postalChanged = async (e: any) => {
    const postal = e.target.value
    try {
      const resp =
        await ersysPublicApiServices.postalService.postalLookup(postal)
      if (resp.status === 200) {
        setValue("billingCity", resp.data.city)
        setValue("billingState", resp.data.state)
      }
    } catch (ex: any) {
      // This was a good faith attempt at filling in postal, if it doesn't work it doesn't work
    }
  }

  const onUpdate = async (formInput: FormInput) => {
    // build patch set
    dispatch(setIsLoading(true))
    const patchSet: PatchAction[] = []

    for (const propName in formInput) {
      if ((resetValues as any)[propName] !== (formInput as any)[propName]) {
        patchSet.push({
          operationType: "Replace",
          to: propName,
          value: (formInput as any)[propName],
        })
      }
    }

    try {
      await ersysPublicApiServices.accountsService.accountsUpdate(
        currentAccount!.id,
        {
          actions: patchSet,
        },
      )

      const accountData =
        await ersysPublicApiServices.accountsService.accountsGet(
          currentAccount!.id,
        )
      dispatch(setSelectedAccount(accountData.data))
      dispatch(setOrderStepIndex(currentIndex + 1))
    } catch (ex: any) {
      dispatch(
        showError({
          title: "Unexpected Error occurred",
          message:
            "An unexpected error occurred while updating. If problem persists please contact IT.",
        }),
      )
      console.log(ex)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <Container maxWidth="md">
      <form>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <h2>Customer Info</h2>
            <FormInputText
              spacing={formSpacing}
              name={"primaryFirstName"}
              control={control}
              label={"First Name*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"primaryLastName"}
              control={control}
              label={"Last Name*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"primaryEmail"}
              control={control}
              label={"Email*"}
              rules={{ required: "Required Field", pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"primaryPhone"}
              control={control}
              label={"Phone Number*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputDropdown
              spacing={formSpacing}
              name={"graduationYear"}
              control={control}
              label={"Completion Year*"}
              options={yearOptions()}
              rules={{ required: "Required Field" }}
            />
            {store?.isLocationEnabledOnOrder && (
              <FormInputDropdown
                spacing={formSpacing}
                name={"locationID"}
                control={control}
                label={`Dorm${store.isLocationMandatoryOnOrder ? "*" : ""}`}
                options={locationOptions()}
                rules={{
                  required: store.isLocationMandatoryOnOrder
                    ? "Required Field"
                    : false,
                }}
              />
            )}
            {store?.isRoomEnabledOnOrder && (
              <FormInputText
                spacing={formSpacing}
                name={"room"}
                control={control}
                label={"Room Number"}
              />
            )}
            {store?.isCustomOneEnabled && (
              <FormInputText
                spacing={formSpacing}
                name={"custom1"}
                control={control}
                label={store?.customOne ?? ""}
              />
            )}
          </Grid>
          <Grid xs={6} item>
            <h2>Billing Info</h2>
            <FormInputText
              spacing={formSpacing}
              name={"billingFirstName"}
              control={control}
              label={"First Name*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingLastName"}
              control={control}
              label={"Last Name*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingEmail"}
              control={control}
              label={"Email*"}
              rules={{ required: "Required Field" , pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingPhone"}
              control={control}
              label={"Phone Number*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingPostal"}
              control={control}
              label={"Postal Code*"}
              customOnChange={(e) => debouncedPostalChanged(e, () => {})}
              rules={{ required: "Required Field" }}
            />
            <FormInputDropdown
              spacing={formSpacing}
              name={"billingCountry"}
              control={control}
              label={"Country*"}
              options={countryOptions()}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingAddressLineOne"}
              control={control}
              label={"Address Line One*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingAddressLineTwo"}
              control={control}
              label={"Address Line Two"}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingCity"}
              control={control}
              label={"City*"}
              rules={{ required: "Required Field" }}
            />
            <FormInputText
              spacing={formSpacing}
              name={"billingState"}
              control={control}
              label={"State/Proivince*"}
              rules={{ required: "Required Field" }}
            />
          </Grid>
        </Grid>
        <div className="select-account-btn-wrapper">
          {!accountNeedsCreation && (
            <Button
              variant="contained"
              onClick={handleSubmit(onUpdate)}
              endIcon={<AccountBox />}
            >
              Continue
            </Button>
          )}
          {accountNeedsCreation && (
            <Button
              variant="contained"
              onClick={handleSubmit(onCreate)}
              endIcon={<AccountBox />}
            >
              Create Account
            </Button>
          )}
        </div>
      </form>
    </Container>
  )
}

export default ModifyCustomerStep
