import { useEffect, useState } from "react"
import { Link, MinimalDepartment, MinimalInventoryCategory } from "../../apis/ersys-data"
import { FormGroup, FormControlLabel, Skeleton, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, darken, ButtonGroup, IconButton, Tooltip, Fab } from "@mui/material"
import { useDispatch } from "react-redux"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useParams } from "react-router-dom"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { useDebounce } from "../useDebounce"
import { CheckCircleSharp, CancelSharp, AddSharp, RemoveSharp } from "@mui/icons-material"

interface GlobalPlanDiscountsTabProps {
    depts: MinimalDepartment[],
    activeIcdls: Link[],
    onChange: (set: Link[]) => void
    formSpacing: number
}

const GlobalPlanDiscountsTab: React.FC<GlobalPlanDiscountsTabProps> = ({ depts, activeIcdls, onChange }) => {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [selectedDepartment, setSelectedDepartment] = useState<MinimalDepartment>()
    const [icdls, setIcdls] = useState<MinimalInventoryCategory[]>([])
    const [icdlLookup, setIcdlLookup] = useState<Record<number, Number[]>>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>("")
    const [visibleIcs, setVisibleIcs] = useState<MinimalInventoryCategory[]>([])
    const debouncedFilter: string = useDebounce(filter, 500)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if (debouncedFilter === "") {
            setVisibleIcs(icdls.slice(0, 50))
        }
        setVisibleIcs(icdls.filter(i =>
            i.name.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase())
        ).slice(0, 50))
    }, [icdls, debouncedFilter, setVisibleIcs])

    useEffect(() => {
        if (!selectedDepartment) {
            return
        }
        setLoading(true)
        const fetch = async () => {
            try {
                const resp = await ersysDataApiServices.departmentsService.departmentsListIcs(selectedDepartment!.id)
                setIcdls(resp.data.items)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }
        fetch()
    }, [selectedDepartment, dispatch, ersysDataApiServices.departmentsService, params.id])

    useEffect(() => {
        const set: Record<number, number[]> = {}
        for (const icdl of activeIcdls) {
            if (!(icdl.icId in set)) {
                set[icdl.icId] = []
            }
            if(icdl.icId == 258) {
                console.log(icdl)
            }
            set[icdl.icId].push(icdl.deptId)
        }
        setIcdlLookup(set)
        console.log(icdlLookup)
    }, [activeIcdls])

    const checkIsLinked = (icdlId: number, deptId: number): boolean => {
        if (!(icdlId in icdlLookup)) {
            return false
        }

        return icdlLookup[icdlId].includes(deptId)
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const onRemoveIc = (id: number) => {
        onChange([...activeIcdls.filter(l => l.icId !== id || l.deptId !== selectedDepartment?.id)])
    }

    const onAddIc = (id: number) => {
        const newSet: Link[] = [...activeIcdls, { deptId: selectedDepartment?.id ?? 0, icId: id, name: '' }]
        onChange(newSet)
    }

    const addAll = () => {
        onChange([
            ...activeIcdls.filter(l => l.deptId !== selectedDepartment?.id),
            ...icdls.map((i: MinimalInventoryCategory) => {
                return { icId: i.id, deptId: selectedDepartment?.id ?? 0, name: i.name }
            })
        ])
    }

    const removeAll = () => {
        onChange([...activeIcdls.filter(l => l.deptId !== selectedDepartment?.id)])
    }

    const canRemoveAll = () => {
        return activeIcdls.some(i => i.deptId === selectedDepartment?.id)
    }

    const showSkeleton = () => {
        return !Boolean(depts)
    }

    return (
        <Grid container alignItems="center" justifyContent="center" sx={{ height: 385 }}>
            { !canRemoveAll() && <Tooltip title="Add All From Department">
                <Fab
                    onClick={() => addAll()}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip> }
            { canRemoveAll() && <Tooltip title="Remove All From Department">
                <Fab
                    onClick={() => removeAll()}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <RemoveSharp />
                </Fab>
            </Tooltip> }
            {showSkeleton() ? renderSkeleton() :
                <>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} sx={{ height: 400 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {depts.map((d) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(selectedDepartment && d.id === selectedDepartment!.id) && { backgroundColor: darken("#FFF", .1) } }}
                                            key={d.id}
                                            onClick={() => setSelectedDepartment(d)}>
                                            <TableCell>
                                                {d.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: 3 }}>
                        {selectedDepartment &&
                            <TableContainer sx={{ height: 400 }} component={Paper} >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Prefix</TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    size="small"
                                                    sx={{ width: '120px' }}
                                                    value={filter}
                                                    placeholder='Search'
                                                    onChange={(e) => setFilter(e.target.value)} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {visibleIcs.map((ic) => (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`${selectedDepartment.id}-${ic.id}`}>
                                                <TableCell>{ic.name}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup>
                                                        {checkIsLinked(ic.id, selectedDepartment.id) &&
                                                            <Tooltip title="Is Included">
                                                                <IconButton onClick={() => onRemoveIc(ic.id)} color="success">
                                                                    <CheckCircleSharp></CheckCircleSharp>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {!checkIsLinked(ic.id, selectedDepartment.id) &&
                                                            <Tooltip title="Is Not Included">
                                                                <IconButton onClick={() => onAddIc(ic.id)} color="error">
                                                                    <CancelSharp></CancelSharp>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                </>
            }
        </Grid>)
}

export default GlobalPlanDiscountsTab