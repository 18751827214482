import { AddSharp, Edit } from '@mui/icons-material'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, ButtonGroup, IconButton, TextField, Skeleton, Fab } from '@mui/material'
import AppMenu from '../../../components/AppMenu'
import './DiscountsPage.scss'
import { useEffect, useState } from 'react'
import { DiscountCreationRequest, MinimalDiscount, MinimalGlobalPlan } from '../../../apis/ersys-data'
import { showError } from '../../../features/errors/Errors'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { useDispatch } from 'react-redux'
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from '../../../components/useDebounce'
import { useFirebase } from '../../../components/FirebaseProvider'
import DiscountCreationModal from './DiscountCreationModal'
import { MinimalStore } from '../../../apis/ersys-public'

function DiscountsPage() {
    const ersysDataApiServices = useErsysDataApi()
    const [discounts, setDiscounts] = useState<MinimalDiscount[]>([])
    const [storeLookup, setStoreLookup] = useState<Record<number,MinimalStore>>({})
    const [planLookup, setPlanLookup] = useState<Record<number, MinimalGlobalPlan>>({})
    const [visibleDiscounts, setVisibleDiscounts] = useState<MinimalDiscount[]>([])
    const [filter, setFilter] = useState<string>("")
    const debouncedFilter: string = useDebounce(filter, 500)
    const [loading, setLoading] = useState<boolean>(false)
    const [isExtraDataLoaded, setIsExtraDataLoaded] = useState<boolean>(false)
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { isReady } = useFirebase()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        if(debouncedFilter === "") {
            setVisibleDiscounts(discounts.slice(0, 50))
        }
        setVisibleDiscounts(discounts.filter(i => i.code.toLowerCase().includes(debouncedFilter.toLowerCase())).slice(0, 50))
    }, [discounts, setVisibleDiscounts, isReady, debouncedFilter])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                const storesResp = await ersysDataApiServices.storesService.storesList()
                const storeSet: Record<number, MinimalStore> = {}
                for(const store of storesResp.data.stores) {
                    storeSet[store.id] = store
                }
                setStoreLookup(storeSet)

                const plansResp = await ersysDataApiServices.globalPlansService.globalPlansList()
                const planSet: Record<number, MinimalGlobalPlan> = {}
                for(const plan of plansResp.data.plans) {
                    planSet[plan.id] = plan
                }
                setPlanLookup(planSet)
                setIsExtraDataLoaded(true)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store/plans data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [isReady, dispatch, ersysDataApiServices.storesService, ersysDataApiServices.globalPlansService, setStoreLookup, setPlanLookup])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.discountService.discountsList()
                setDiscounts(resp.data.discounts)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting discount data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [dispatch, setDiscounts, ersysDataApiServices.discountService, isReady])

    const showSkeleton = () => {
        // stores shouldn't be empty, and if filter is empty then the filter shouldn't be.
        return (visibleDiscounts.length < 1 && debouncedFilter === '') || !isExtraDataLoaded
    }

    const onCreate = async (req: DiscountCreationRequest) => {
        setLoading(true)
        try {
            setShowCreation(false)
            var resp = await ersysDataApiServices.discountService.discountsCreate(req)
            navigate(`/discounts/${encodeURIComponent(resp.data.code)}`)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while creating discount. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }

    const renderStoreString = (id: number | null | undefined) => {
        if(!id) {
            return "Any"
        }
        const store = storeLookup[id!]
        if(!store) {
            return "Store Not Found"
        }
        return truncate(store.fullName, 25)
    }

    const renderPlanString = (id: number | null | undefined) => {
        if(!id) {
            return "Any"
        }
        const plan = planLookup[id!]
        if(!plan) {
            return "Plan Not Found"
        }
        return truncate(plan.name, 25)
    }

    if (!isReady) {
        return <AppMenu title="Discounts">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title="Discounts">
            <Tooltip title="Add Discount">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <DiscountCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <Container maxWidth="md">
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Store</TableCell>
                                <TableCell>Plan</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        size="small"
                                        value={filter}
                                        placeholder='Search'
                                        onChange={(e) => setFilter(e.target.value)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showSkeleton() ? [...Array(6)].map((_, i) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skele-${i}`}>
                                    <TableCell><Skeleton variant='rectangular' width={40} height={20} /></TableCell>
                                    <TableCell><Skeleton variant='rectangular' width={300} height={20} /></TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Skeleton variant='circular' width={30} height={30} />
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )) :
                                visibleDiscounts.map((disc) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={disc.code}>
                                        <TableCell>{disc.code}</TableCell>
                                        <TableCell>{truncate(disc.description, 25)}</TableCell>
                                        <TableCell>{renderStoreString(disc.restrictedToStore)}</TableCell>
                                        <TableCell>{renderPlanString(disc.restrictedToPlan)}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Edit">
                                                    <Link to={`/discounts/${encodeURIComponent(disc.code)}`}>
                                                        <IconButton>
                                                            <Edit></Edit>
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </AppMenu>
    )
}

export default DiscountsPage