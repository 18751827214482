import { Card, CardContent, CardHeader, Radio, Typography } from "@mui/material";
import { PaymentPlan } from "../../../apis/ersys-public";

import "./PaymentPlanComponent.scss"
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

interface PaymentPlanComponentProps {
    paymentPlan: PaymentPlan;
}

const PaymentPlanComponent: React.FC<PaymentPlanComponentProps> = ({ paymentPlan }) => {
    const theme = useTheme()

    const readableDate = (dateStr: string) => {
        const date = dayjs(dateStr)
        if(!date.isValid()) {
            return "invalid date"
        }
        return date.format("MMM D, YYYY")
    }

    return (
        <Card>
            <CardHeader
                title={`${paymentPlan.publicName}`}
                sx={{
                    backgroundColor: theme.palette.primary.main, 
                    color: theme.palette.primary.contrastText,
                }}
                titleTypographyProps={{fontWeight: theme.typography.fontWeightBold}}
                avatar={
                    <Radio value={paymentPlan.id} sx={{
                        color: theme.palette.primary.contrastText, 
                        "&.Mui-checked": { color: theme.palette.primary.contrastText }
                    }} />
                }
            />
            <CardContent>
                <Typography>Schedule:</Typography>
                <ul>
                    <li><Typography>Due Today: {paymentPlan.firstPaymentPercentage}%</Typography></li>
                    {paymentPlan.dates.map(d => <li key={d.id}><Typography>Due {readableDate(d.payDate)}: {d.percent}%</Typography></li>)}
                </ul>
            </CardContent>
        </Card>
    )
}

export default PaymentPlanComponent