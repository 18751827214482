import './EditGlobalPlansPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { GlobalPlan, Link, MinimalDepartment, PatchAction } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import { useForm, useFormState } from 'react-hook-form'
import { useFirebase } from '../../../components/FirebaseProvider'
import GlobalPlanGeneralTab from '../../../components/global-plans/GlobalPlanGeneralTab'
import GlobalPlanDiscountsTab from '../../../components/global-plans/GlobalPlanDiscountsTab'

const formDefaults: GlobalPlan = {
    description: '',
    expiration: '',
    id: 0,
    name: '',
    productionName: '',
    tier: 0
}

function EditGlobalPlansPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [globalPlan, setGlobalPlan] = useState<GlobalPlan>()
    const [departments, setDepartments] = useState<MinimalDepartment[]>()
    const [links, setLinks] = useState<Link[]>()
    const [linksDirty, setLinksDirty] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<GlobalPlan>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !globalPlan || globalPlan.id === 0
    }

    const allowSave = () => {
        return isDirty || linksDirty
    }

    const getTabNames = (): string[] => {
        return [
            "General",
            "Discount Rules"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <GlobalPlanGeneralTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton}/>,
            <GlobalPlanDiscountsTab depts={departments ?? []} formSpacing={formSpacing} onChange={onChange} activeIcdls={links ?? []}/>,
        ]
    }

    useEffect(() => {
        const resetFunc = async () => {
            if (globalPlan == null) {
                return
            }
            reset(globalPlan)
            await trigger()
        }

        resetFunc()
    }, [globalPlan, reset, trigger])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const id = parseInt(params.id!, 10)
                const resp = await ersysDataApiServices.globalPlansService.globalPlansGet(id)
                setGlobalPlan(resp.data)

                const deptResp = await ersysDataApiServices.departmentsService.departmentsList()
                setDepartments(deptResp.data.departments)

                const linkResp = await ersysDataApiServices.globalPlansService.globalPlansListLinks(id)
                setLinks(linkResp.data.links)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting plan data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setGlobalPlan, ersysDataApiServices.globalPlansService, ersysDataApiServices.departmentsService, params.id, isReady])

    const onChange = (ls: Link[]) => {
        setLinks(ls)
        setLinksDirty(true)
    }

    const Save = async (s: GlobalPlan) => {
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof GlobalPlan>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }

        try {
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.globalPlansService.globalPlansUpdate(s.id, { actions: patchSet })
                setGlobalPlan(resp.data)
            }

            if(linksDirty) {
                await ersysDataApiServices.globalPlansService.globalPlansSetLinks(s.id, {ics: links ?? []})
                const linkResp = await ersysDataApiServices.globalPlansService.globalPlansListLinks(s.id)
                setLinks(linkResp.data.links)
                setLinksDirty(false)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while updating plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Global Plan">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Global Plan: ${globalPlan?.name ?? ""}`}>
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditGlobalPlansPage