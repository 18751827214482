import { Box, Grid, IconButton, List, ListItem, Paper, Tooltip, Typography } from "@mui/material"
import { AccountOrder } from "../../apis/ersys-data/api"
import { Cancel } from "@mui/icons-material"
import dayjs from 'dayjs'
import "./AccountOrderComponent.scss"
import NagModal from "../NagModal"
import { useState } from "react"
import { usePermissions } from "../usePermissions"

interface AccountOrderComponentProps {
    order: AccountOrder,
    onCancel: (order: AccountOrder) => void
}

const AccountOrderComponent: React.FC<AccountOrderComponentProps> = ({ order, onCancel }) => {
    const [nagIsVisible, setNagIsVisible] = useState<boolean>(false)
    const { hasPermissions } = usePermissions()

    const formatCancelledDate = (d: string): string => {
        const date = dayjs(d)
        return date.format('MM/DD/YYYY')
    }

    const toDollars = (v: number): string => {
        return `$${(v / 100).toFixed(2)}`
    }

    return (
        <Grid container component={Paper} sx={{ padding: 3, minWidth: 600 }}>
            <NagModal
                message='Are you sure?'
                title="Cancel Order"
                onCancel={() => { setNagIsVisible(false) }}
                onOk={() => { 
                    setNagIsVisible(false)
                    onCancel(order)
                }}
                open={nagIsVisible}
            />
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6" className="no-margin" color="primary">Order: {order.id}</Typography>
                    {order.paymentPlanName && order.paymentPlanName !== '' && <h4>Payment Plan: {order.paymentPlanName}</h4>}
                </Grid>
                <Grid item xs={6} justifyContent={"right"}>
                    {order.isCancelled ? <Typography variant="h6" className="align-text-right" color="error">Cancelled on: {formatCancelledDate(order.cancelledOn ?? "")}</Typography>
                        : <Box className="align-text-right">                          
                            {hasPermissions("AP:O-") && <Tooltip title="Cancel">
                                <IconButton onClick={() => setNagIsVisible(true)}><Cancel></Cancel></IconButton>
                            </Tooltip>}
                        </Box>}
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={3}>
                    <Typography sx={{ mt: 2, mb: 0 }} variant="h6" component="div">
                        Plans
                    </Typography>
                    <List>
                        {order.plans.filter(p => !p.isAddon).map(plan =>
                            <ListItem key={`${order.id}-${plan.name}`} sx={{ paddingLeft: 0 }}>
                                <Typography>{plan.name} @ {toDollars(plan.value)}</Typography>
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={{ mt: 2, mb: 0 }} variant="h6" component="div">
                        Addons
                    </Typography>
                    <List>
                        {order.plans.filter(p => p.isAddon).map(plan =>
                            <ListItem key={`${order.id}-${plan.name}`} sx={{ paddingLeft: 0 }}>
                                <Typography>{plan.name} @ {toDollars(plan.value)}</Typography>
                            </ListItem>
                        )}
                    </List>
                </Grid>

                {order.discounts && order.discounts.length > 0 &&
                    <Grid item xs={3}>
                        <Typography sx={{ mt: 2, mb: 0 }} variant="h6" component="div">
                            Discounts
                        </Typography>
                        <List>
                            {order.discounts.map(discount =>
                                <ListItem key={`${order.id}-${discount.notes}`} sx={{ paddingLeft: 0 }}>
                                    <Typography>{discount.notes}</Typography>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                }

                {order.remainingPayments && order.remainingPayments.length > 0 &&
                    <Grid item xs={3}>
                        <Typography sx={{ mt: 2, mb: 0 }} variant="h6" component="div">
                            Remaining Payments
                        </Typography>
                        <List>
                            {order.remainingPayments.map(payment =>
                                <ListItem key={`${order.id}-${payment.payDate}`} sx={{ paddingLeft: 0 }}>
                                    <Typography>{formatCancelledDate(payment.payDate)} @ {toDollars(payment.value)}</Typography>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                }

            </Grid>


            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6" className="no-margin">Order Date: {formatCancelledDate(order.orderDate)}</Typography>
                </Grid>
                <Grid item xs={6} justifyContent={"right"}>
                    <Typography variant="h6" className="align-text-right" color="primary">Total: {toDollars(order.total)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AccountOrderComponent