import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { CreateStoreRequest } from "../../../apis/ersys-data"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import FormInputText from "../../../components/forms/FormInputText"
import { useForm } from "react-hook-form"

interface StoreCreationFormInput {
  prefix: string
  fullName: string
  shortName: string
}

const defaults: StoreCreationFormInput = {
  prefix: "",
  fullName: "",
  shortName: "",
}

interface StoreCreationModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (req: CreateStoreRequest) => Promise<void>
}

const StoreCreationModal: React.FC<StoreCreationModalProps> = ({
  open,
  onCreate,
  onClose,
}) => {
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control } =
    useForm<StoreCreationFormInput>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })

  const handleCreate = (input: StoreCreationFormInput) => {
    onCreate({ fullName: input.fullName, prefix: input.prefix, shortName: input.shortName })
  }

  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Create a new store</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormInputText
                spacing={formSpacing}
                name={"prefix"}
                control={control}
                label={"Prefix"}
                rules={{ required: "Required Field", maxLength: 2 }}
              />

              <FormInputText
                spacing={formSpacing}
                name={"fullName"}
                control={control}
                label={"Full Name"}
                rules={{ required: "Required Field", maxLength: 50 }}
              />

              <FormInputText
                spacing={formSpacing}
                name={"shortName"}
                control={control}
                label={"Short Name"}
                rules={{ required: "Required Field", maxLength: 15 }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleCreate)} autoFocus>
          Ok
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StoreCreationModal
