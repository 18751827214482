import { ButtonGroup, Fab, FormControlLabel, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import { MinimalInventoryCategory } from "../../apis/ersys-data/api"
import { AddSharp, CancelSharp, CheckCircleSharp, RemoveSharp } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useDebounce } from "../useDebounce"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { useFirebase } from "../FirebaseProvider"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"

interface ICTabProps {
    icdls: number[],
    showSkeleton: () => boolean
    onChange: (icdls: number[]) => void
}

const ICTab: React.FC<ICTabProps> = ({ icdls, showSkeleton, onChange }) => {
    const [filter, setFilter] = useState<string>("")
    const [ics, setIcs] = useState<MinimalInventoryCategory[]>([])
    const [activeLookup, setActiveLookup] = useState<Record<number, boolean>>({})
    const [visibleIcs, setVisibleIcs] = useState<MinimalInventoryCategory[]>([])
    const debouncedFilter: string = useDebounce(filter, 500)
    const ersysDataApiServices = useErsysDataApi()
    const { isReady } = useFirebase()
    const dispatch = useDispatch()

    useEffect(() => {
        if (debouncedFilter === "") {
            setVisibleIcs(ics.slice(0, 50))
        }
        setVisibleIcs(ics.filter(i => i.name.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase())).slice(0, 50))
    }, [ics, debouncedFilter, setVisibleIcs])

    useEffect(() => {
        const set: Record<number, boolean> = {}
        for(const icdl of icdls) {
            set[icdl] = true
        }
        setActiveLookup(set)
    }, [icdls, setActiveLookup])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const resp = await ersysDataApiServices.inventoryCategoryService.inventoryCategoriesList()
                setIcs(resp.data.items)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting ic data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()

    }, [setIcs, ersysDataApiServices.inventoryCategoryService, dispatch, isReady])

    const localRenderSkeleton = () => {
        return visibleIcs.length < 1 && debouncedFilter !== ''
    }

    const onAddIc = (id: number) => {
        onChange([...icdls, id])
    }

    const onRemoveIc = (id: number) => {
        onChange([...icdls].filter(i => i !== id))
    }

    const addAll = () => {
        onChange([...ics.map(x => x.id)])
    }

    const removeAll = () => {
        onChange([])
    }

    const canRemoveAll = () => {
        return icdls.length === ics.length
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            { !canRemoveAll() && <Tooltip title="Add All">
                <Fab
                    onClick={() => addAll()}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip> }
            { canRemoveAll() && <Tooltip title="Remove All">
                <Fab
                    onClick={() => removeAll()}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <RemoveSharp />
                </Fab>
            </Tooltip> }
            <Grid xs={6} item>
                {showSkeleton() || localRenderSkeleton() ? renderSkeleton() :
                    (
                        <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                size="small"
                                                value={filter}
                                                placeholder='Search'
                                                onChange={(e) => setFilter(e.target.value)} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleIcs.map((ic) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={ic.id}>
                                            <TableCell>{ic.name}</TableCell>
                                            <TableCell align="right">
                                                <ButtonGroup>
                                                    {activeLookup[ic.id] &&
                                                        <Tooltip title="Is Included">
                                                            <IconButton onClick={() => onRemoveIc(ic.id)} color="success">
                                                                <CheckCircleSharp></CheckCircleSharp>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {!activeLookup[ic.id] &&
                                                        <Tooltip title="Is Not Included">
                                                            <IconButton onClick={() => onAddIc(ic.id)} color="error">
                                                                <CancelSharp></CancelSharp>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default ICTab