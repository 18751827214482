import { Card, CardContent, CardHeader, useTheme } from "@mui/material"
import { OrderPlanPaymentDisplay } from "../../../../apis/ersys-public"
import { CalendarMonth } from "@mui/icons-material"
import dayjs, { Dayjs } from "dayjs"

interface PaymentPlanSectionProps {
  paymentPlan: Array<OrderPlanPaymentDisplay>
}

const PaymentPlanSection: React.FC<PaymentPlanSectionProps> = ({
  paymentPlan,
}) => {
  const theme = useTheme()

  const toMoney = (value: number) => {
    if (value === undefined || isNaN(value)) {
      return "0"
    }
    if (value < 0) {
      return `-$${(value / -100).toFixed(2)}`
    }
    return `$${(value / 100).toFixed(2)}`
  }

  const toDateText = (s: string | null | undefined) => {
    if (!s) {
      return toDateTextInner(dayjs(new Date()))
    }

    return toDateTextInner(dayjs(s))
  }

  const toDateTextInner = (d: Dayjs) => {
    return d.format("MM/DD/YYYY")
  }

  return (
    <Card sx={{ marginBottom: theme.spacing(2) }}>
      <CardHeader
        title="Payment Schedule"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
        titleTypographyProps={{ fontWeight: theme.typography.fontWeightBold }}
        avatar={<CalendarMonth />}
      />
      <CardContent>
        <ul>
          {paymentPlan.map((d) => (
            <li key={`payment-plan-${d.futurePayDate}`}>
              <p>{`${toMoney(d.value ?? 0)} - on ${toDateText(d.futurePayDate)}`}</p>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  )
}

export default PaymentPlanSection
