import { ButtonGroup, Fab, FormControlLabel, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material"
import { CreateLocationRequest, Location } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { DeleteSharp, AddSharp } from "@mui/icons-material"
import { useDebounce } from "../useDebounce"
import NagModal from "../NagModal"
import LocationCreationModal from "./modals/LocationCreationModal"

interface StoreLocationsTabProps {
    formSpacing: number
}

const StoreLocationsTab: React.FC<StoreLocationsTabProps> = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [locations, setLocations] = useState<Location[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [filteredLocations, setFilteredLocations] = useState<Location[]>([])
    const [filter, setFilter] = useState<string>("")
    const [loaded, setLoaded] = useState<boolean>(false)
    const [nagIsVisible, setNagIsVisible] = useState<boolean>(false)
    const [nagOkFunc, setNagOkFunc] = useState<() => Promise<void>>(async () => { })
    const [showCreation, setShowCreation] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()
    const debouncedFilter: string = useDebounce(filter, 250)

    useEffect(() => {
        if (!debouncedFilter) {
            setFilteredLocations(locations.slice(0, 50))
        }
        setFilteredLocations(
            locations
                .filter(l => l.name.toLocaleLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                .slice(0, 50)
        )
    }, [locations, debouncedFilter])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.storesService.storesListLocations(parseInt(params.id!, 10))
                setLocations(resp.data.locations)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting location data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, ersysDataApiServices.storesService, params.id])

    const onDeleteLocation = (location: Location) => {
        setNagIsVisible(true)
        setNagOkFunc(() => deleteLocation(location))
    }

    const deleteLocation = (location: Location) => {
        return async () => {
            const storeId = parseInt(params.id!, 10)
            try {
                setLoading(true)
                setNagIsVisible(false)
                await ersysDataApiServices.storesService.storesDeleteLocation(storeId, location.id)
                const resp = await ersysDataApiServices.storesService.storesListLocations(storeId)
                setLocations(resp.data.locations)
            }
            catch (ex: any) {
                dispatch(showError({
                    title: "Unable to delete location",
                    message:
                        "Unable to delete location. Please make sure it is not referenced by any accounts.",
                }))
                console.log(ex)
            } finally {
                setLoading(false)
            }
        }
    }

    const showSkeleton = () => {
        return locations.length < 1 && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const truncate = (str: string, len: number): string => {
        return str.length > len ? str.substring(0, len - 3) + "..." : str;
    }
    
    const onCreate = async (req: CreateLocationRequest) => {
        setLoading(true)
        try {
            const storeId = parseInt(params.id!, 10)
            setShowCreation(false)
            await ersysDataApiServices.storesService.storesCreateLocation(storeId, req)
            const resp = await ersysDataApiServices.storesService.storesListLocations(storeId)
            setLocations(resp.data.locations)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while creating store plan. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Grid container alignItems="center" justifyContent="center">

            <Tooltip title="Add Location">
                <Fab
                    onClick={() => setShowCreation(true)}
                    color="primary"
                    aria-label="add"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                    <AddSharp />
                </Fab>
            </Tooltip>
            <LocationCreationModal
                open={showCreation}
                onClose={() => setShowCreation(false)}
                onCreate={onCreate}
            />
            <NagModal
                message='Are you sure?'
                title="Delete Location"
                onCancel={() => { setNagIsVisible(false) }}
                onOk={nagOkFunc!}
                open={nagIsVisible}
            />
            {showSkeleton() ? renderSkeleton() :
                <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        size="small"
                                        value={filter}
                                        placeholder='Search'
                                        onChange={(e) => setFilter(e.target.value)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredLocations.map((location) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={location.id}>
                                    <TableCell>{truncate(location.name, 100)}</TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => onDeleteLocation(location)}>
                                                    <DeleteSharp></DeleteSharp>
                                                </IconButton>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Grid>
    )
}

export default StoreLocationsTab