import './EditAccountPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { Account, Location, PatchAction, Proration, Store } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import NotificationModal from '../../../components/NotificationModal'
import { useForm, useFormState } from 'react-hook-form'
import { usePermissions } from '../../../components/usePermissions'
import AccountGeneralTab from '../../../components/accounts/AccountGeneralTab'
import { MinimalStore } from '../../../apis/ersys-public'
import AccountBillingTab from '../../../components/accounts/AccountBillingTab'
import AccountAccountingTab from '../../../components/accounts/AccountAccountingTab'
import AccountCommentsTab from '../../../components/accounts/AccountCommentsTab'
import AccountOrdersTab from '../../../components/accounts/AccountOrdersTab'
import AccountChecklistTab from '../../../components/accounts/AccountChecklistTab'
import { useFirebase } from '../../../components/FirebaseProvider'
import AccountPlansTab from '../../../components/accounts/AccountPlansTab'
import AccountBagsTab from '../../../components/accounts/AccountBagsTab'
import AccountUsersTab from '../../../components/accounts/AccountUsersTab'
import AccountCardOnFileTab from '../../../components/accounts/AccountCardOnFileTab'

const formDefaults: Account = {
    accountType: 'student',
    billingAddressLineOne: '',
    billingAddressLineTwo: '',
    billingCity: '',
    billingCountry: '',
    billingEmail: '',
    billingFirstName: '',
    billingLastName: '',
    billingPhone: '',
    billingPostal: '',
    billingState: '',
    classYear: 'freshman',
    createdDate: '',
    discount: 0,
    id: 0,
    primaryEmail: '',
    primaryFirstName: '',
    primaryLastName: '',
    primaryPhone: '',
    storeId: 0,
    comments: '',
    custom1: '',
    disableAutoChargePiecePrice: false,
    graduationYear: 0,
    isActive: false,
    isFlaggedForQuality: false,
    isFlaggedNoMarking: false,
    isHung: false,
    isSuspended: false,
    locationId: 0,
    room: '',
}

function EditAccountPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [selectedAccount, setSelectedAccount] = useState<Account>()
    const [store, setStore] = useState<Store>()
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [locations, setLocations] = useState<Location[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [prorationsDirty, setProrationsDirty] = useState<boolean>(false)
    const [prorations, setProrations] = useState<Proration[]>([])
    const [notificationIsVisible, setNotificationIsVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, trigger, } =
        useForm<Account>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { hasPermissions } = usePermissions()
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !selectedAccount || selectedAccount.id === 0
    }

    const allowSave = () => {
        return isDirty || prorationsDirty
    }

    const getTabNames = (): string[] => {
        const tabs = [
            "General",
            "Billing",
        ]
        
        if (hasPermissions("AP:Acct")) {
            tabs.push("Accounting")
        }

        tabs.push("Orders")
        tabs.push("Checklists")
        tabs.push("Plans")
        tabs.push("Bags")
        tabs.push("Users")
        tabs.push("Comments")
        tabs.push("Card on File")

        return tabs
    }

    const getTabComponents = (): JSX.Element[] => {
        const tabs = [
            <AccountGeneralTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} stores={stores} store={store} locations={locations}/>,
            <AccountBillingTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} />
        ]

        if (hasPermissions("AP:Acct")) {
            tabs.push(<AccountAccountingTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton}/>)
        }

        tabs.push(<AccountOrdersTab formSpacing={formSpacing}/>)
        tabs.push(<AccountChecklistTab formSpacing={formSpacing} />)
        tabs.push(<AccountPlansTab formSpacing={formSpacing} />)
        tabs.push(<AccountBagsTab formSpacing={formSpacing}/>)
        tabs.push(<AccountUsersTab formSpacing={formSpacing} />)
        tabs.push(<AccountCommentsTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton}/>)
        tabs.push(<AccountCardOnFileTab formSpacing={formSpacing} account={selectedAccount} />)

        return tabs
    }

    useEffect(() => {
        const resetFunc = async () => {
            if (selectedAccount == null) {
                return
            }
            reset(selectedAccount)
            await trigger()
        }

        resetFunc()
    }, [selectedAccount, reset, trigger])

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const accountId = parseInt(params.id!, 10)
                const accountResp = await ersysDataApiServices.accountsService.accountsGet(accountId)
                setSelectedAccount(accountResp.data)

                const storeResp = await ersysDataApiServices.storesService.storesGet(accountResp.data.storeId)
                setStore(storeResp.data)

                const storesResp = await ersysDataApiServices.storesService.storesList()
                storesResp.data.stores.sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0))
                setStores(storesResp.data.stores)

                const locationsResp = await ersysDataApiServices.storesService.storesListLocations(accountResp.data.storeId)
                locationsResp.data.locations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                setLocations(locationsResp.data.locations)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setSelectedAccount, ersysDataApiServices.storesService, ersysDataApiServices.accountsService, params.id, isReady])

    const Save = async (s: Account) => {
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof Account>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: s[fieldName],
                    })
                }
            }
        }

        try {
            // account update
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.accountsService.accountsUpdate(s.id, { actions: patchSet })
                setSelectedAccount(resp.data)
            }

            // prorations update
            if (prorationsDirty) {
                await ersysDataApiServices.storesService.storesOverwriteProrations(s.id, {
                    prorations: prorations.map(p => {
                        return {
                            cost: p.cost,
                            date: p.date
                        }
                    })
                })
                setProrationsDirty(false)
                const prorationResp = await ersysDataApiServices.storesService.storesListProrations(s.id)
                setProrations(prorationResp.data.prorations)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting store data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Account">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Account: ${selectedAccount?.primaryFirstName ?? ""} ${selectedAccount?.primaryLastName ?? ""} (${selectedAccount?.id ?? 0})`}>
            <NotificationModal
                message="User changes have been saved."
                open={notificationIsVisible}
                title="Save Successful"
                onClose={() => setNotificationIsVisible(false)}
            />
            <Container maxWidth="md">
                <TabViewComponent name='editStore' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditAccountPage