import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { useIntervalPromise } from "../useInterval"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { LongRunningProcessStatus } from "../../apis/ersys-data"

interface AdministrativeToolsWaitOnProcessModalProps {
  id: string;
  open: boolean;
  title: string;
  noCounts?: boolean;
  onCompleted?: () => Promise<void>;
  onClose: () => void;
}

const AdministrativeToolsWaitOnProcessModal: React.FC<AdministrativeToolsWaitOnProcessModalProps> = ({
  id,
  open,
  title,
  noCounts,
  onCompleted,
  onClose,
}) => {
  const ersysDataApiServices = useErsysDataApi()
  const [isPolling, setIsPolling] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<LongRunningProcessStatus>()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsLoading(loading))
  }, [loading, dispatch])

  useEffect(() => {
    if (open && id !== "") {
      setStatus(undefined)
      setLoading(false)
      setIsPolling(true)
    }
  }, [id, open])

  const tryPoll = async () => {
    if (!isPolling) {
      return
    }

    setLoading(true)
    try {
      const response = await ersysDataApiServices.bulkService.bulkCheckProcessStatus(id)
      setStatus(response.data)
      if (response.data.isComplete || response.data.isError) {
        setIsPolling(false)
        if(onCompleted) {
          onCompleted()
        }
      }
    }
    catch (ex: any) {
      dispatch(
        showError({
          title: "Unexpected Error occurred",
          message:
            "An unexpected error occurred while getting data. If problem persists please contact IT.",
        }),
      )
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }

  useIntervalPromise(tryPoll, 3000)

  const normalise = (value: number) => ((value - 0) * 100) / (status!.totalCount - 0);

  return (
    <Dialog
      open={open}      
      onClose={(e, r) => {
        if(r && r === "backdropClick") {
          return
        }
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              {isPolling && !status && <LinearProgress />}
              {isPolling && status && noCounts && <LinearProgress />}
              {isPolling && status && !noCounts && <LinearProgress variant="determinate" value={normalise(status!.completedCount + status!.failedCount)} />}
              {!isPolling && status &&
                <Grid>
                  <Typography>Status: {status!.isComplete ? 'Success' : 'Error'}</Typography>
                  { !noCounts && <Typography>Succeeded Updates: {status!.completedCount}</Typography> }
                  { !noCounts && <Typography>Failed Updates: {status!.failedCount}</Typography> }
                  {status.failedDetails && status.failedDetails.length > 0 &&
                    <TableContainer sx={{ maxHeight: 200, overflowY: "auto" }} component={Paper}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Error</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {status.failedDetails.map((detail) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={detail}>
                              <TableCell>
                                <Typography>{detail}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Grid>
              }
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {!isPolling && <Button onClick={() => onClose()}>
          Close
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

export default AdministrativeToolsWaitOnProcessModal
