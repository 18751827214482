// src/form-component/FormInputText.tsx
import { Controller, FieldValues, RegisterOptions } from "react-hook-form"
import { useTheme } from "@mui/material/styles"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

export interface FormInputDatePickerProps {
    name: string
    control: any
    label: string
    spacing: number
    customOnChange?: (event: any) => void | undefined
    rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >
    | undefined
}

const FormInputDatePicker: React.FC<FormInputDatePickerProps> = ({
    name,
    control,
    label,
    spacing,
    customOnChange,
    rules,
}) => {
    const theme = useTheme()

    return (
        <div
            style={{
                marginTop: theme.spacing(spacing),
                marginBottom: theme.spacing(spacing),
                maxWidth: "100%",
            }}
        >
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                        slotProps={{ textField: {size: 'small', fullWidth: true, variant: 'outlined'}}}
                        label={label}
                        value={dayjs(value)}
                            onChange={(e) => {
                              if (customOnChange) {
                                customOnChange(e?.format() ?? "")
                              }
                              onChange(e?.format() ?? "")
                            }}
                        defaultValue={dayjs(value)}
                    />
                    //   <TextField
                    //     helperText={error ? error.message : null}
                    //     size="small"
                    //     error={!!error}
                    //     onChange={(e) => {
                    //       if (customOnChange) {
                    //         customOnChange(e)
                    //       }
                    //       onChange(e)
                    //     }}
                    //     value={value}
                    //     fullWidth
                    //     label={label}
                    //     variant="outlined"
                    //   />
                )}
            />
        </div>
    )
}

export default FormInputDatePicker
