import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import FormInputText from "../../../components/forms/FormInputText"
import { useForm } from "react-hook-form"

export interface MemoRequest {
    memo: string
}

const defaults: MemoRequest = {
    memo: ""
}

interface GetMemoModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (req: MemoRequest) => Promise<void>
}

const GetMemoModal: React.FC<GetMemoModalProps> = ({
  open,
  onCreate,
  onClose,
}) => {
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control } =
    useForm<MemoRequest>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })

  const handleCreate = (input: MemoRequest) => {
    onCreate(input)
  }

  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Add Memo to Payment</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormInputText
                spacing={formSpacing}
                name={"memo"}
                control={control}
                label={"Memo"}
                rules={{ required: "Required Field", maxLength: 100, minLength: 3}}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleCreate)} autoFocus>
          Send Order
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GetMemoModal
