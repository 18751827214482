import './EditUsersPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { User } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import UserPermissionsTab from '../../../components/users/UserPermissionsTab'
import { SaveSharp } from '@mui/icons-material'
import NotificationModal from '../../../components/NotificationModal'
import { useFirebase } from '../../../components/FirebaseProvider'

function EditUsersPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [selectedUser, setSelectedUser] = useState<User>()
    const [newPerms, setNewPerms] = useState<string[]>()
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [notificationIsVisible, setNotificationIsVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { isReady } = useFirebase()

    const onPermissionChanged = (perms: string[]) => {
        setNewPerms(perms)
        setIsDirty(true)
    }

    const tabNames = ["Permissions"]
    const tabComponents = [
        <UserPermissionsTab user={selectedUser} onPermissionsChange={onPermissionChanged} />
    ]

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])
    
    useEffect(() => {
        if(!isReady) {
            return
        }
        
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.usersService.usersGet(params.id!)
                setSelectedUser(resp.data)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting user data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setSelectedUser, ersysDataApiServices.usersService, params.id])

    const Save = async () => {
        try {
            setLoading(true)
            await ersysDataApiServices.usersService.usersSetClaims(selectedUser!.id, {claims: newPerms ?? []})
            var resp = await ersysDataApiServices.usersService.usersGet(params.id!)
            setSelectedUser(resp.data)
            setNotificationIsVisible(true)
        }
        catch (ex: any) {
            dispatch(
            showError({
                title: "Unexpected Error occurred",
                message:
                    "An unexpected error occurred while saving changes. If problem persists please contact IT.",
            }))
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    return (
        <AppMenu title={`User: ${selectedUser?.displayName ?? ""}`}>
            <NotificationModal
                message="User changes have been saved."
                open={notificationIsVisible}
                title="Save Successful"
                onClose={() => setNotificationIsVisible(false)}
            />
            <Container maxWidth="md">
                <TabViewComponent name='edituser' tabNames={tabNames} tabs={tabComponents}/>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!isDirty}
                        onClick={Save}
                        endIcon={<SaveSharp />}
                    >
                    Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditUsersPage