import { configureStore } from '@reduxjs/toolkit'
import permissionsReducer from '../features/permissions/Permissions'
import appMenuReducer from '../features/menus/AppMenu'
import errorsReducer from '../features/errors/Errors'
import orderReducer from '../features/order-workflow/Order'

export const store = configureStore({
  reducer: { 
    permissionSet: permissionsReducer,
    appMenu: appMenuReducer,
    errors: errorsReducer,
    order: orderReducer,
   },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch