import jwtDecode from "jwt-decode"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setIsLoading } from "../features/menus/AppMenu"
import { setPermissions } from "../features/permissions/Permissions"
import { useFirebase } from "./FirebaseProvider"

function PermissionsGatherer() {
  const { isReady, user } = useFirebase()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await user!.getIdToken()
        const decoded = jwtDecode(accessToken) as any
        const claims: string[] = []
        for (const prop in decoded) {
          if(Object.hasOwn(decoded, prop)) {
            claims.push(prop)
          }
        }
        dispatch(setPermissions(claims ?? []))
        dispatch(setIsLoading(false))
      } catch (e: any) {
        console.log(e.message)
      }
    }
    if (!isReady) {
      dispatch(setIsLoading(true))
      // we're loading so we wait to do anything
      return
    }
    if (!user) {
      dispatch(setIsLoading(false))
      const returnTo = window.location.pathname
      navigate("/login", {
        state: {
          redirectUrl: returnTo,
        },
      })
      return
    }
    getAccessToken()
  }, [isReady, user, navigate, dispatch])

  return <></>
}

export default PermissionsGatherer
