import { QueryStatsSharp } from "@mui/icons-material"
import { Container, Grid, Typography } from "@mui/material"
import AppMenu from "../../components/AppMenu"


const Dashboard: React.FC = () => {

    return (
        <AppMenu title='Dashboard'>
            <Container maxWidth="sm" sx={{marginTop: 10}}>
                <Grid container justifyContent="center">
                    <QueryStatsSharp fontSize="large" />
                </Grid>
                <Grid container justifyContent="center" sx={{marginTop: 2}}>
                    <Typography>Select your page from the menu</Typography>
                </Grid>
            </Container>
        </AppMenu>
    )
}


export default Dashboard