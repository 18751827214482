import { Box, Tabs, Tab } from "@mui/material"
import { useState } from "react";
import TabComponent from "./TabComponent";

interface TabViewProps {
    tabNames: string[]
    tabs: React.ReactNode[]
    name: string
}

const TabViewComponent: React.FC<TabViewProps> = ({ tabs, tabNames, name }) => {
    const [tabValue, setTabValue] = useState<number>(0)


    const a11yProps = (index: number) => {
        return {
            id: `${name}-tab-${index}`,
            'aria-controls': `${name}-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} aria-label="basic tabs example" scrollButtons="auto" variant="scrollable">
                    {tabNames.map((n, i) => (<Tab label={n} {...a11yProps(i)} key={`${name}-tab-${i}`} />))}
                </Tabs>
            </Box>
            {tabs.map((child, i) => (
                <TabComponent value={tabValue} index={i} name={name} key={`${name}-tabpanel-${i}`}>
                    {child}
                </TabComponent>
            ))}
        </Box>
    )
}

export default TabViewComponent