import { Logout, ShoppingBasket, People, SchoolSharp, AccountCircleSharp, BackpackSharp, CategorySharp, WorkspacesSharp, LocalOfferSharp, RequestQuoteSharp, GroupWorkSharp, LocalShippingSharp, AdminPanelSettingsSharp } from "@mui/icons-material"

export interface MenuItem {
  title: string
  icon: React.ReactNode
  permissionRequired: string
  path: string
}

export interface MenuSet {
  userOperations: MenuItem[];
  administrationOperations: MenuItem[];
}

export const menuSet: MenuSet = {
  userOperations: [
    {
        title: 'Stores',
        icon: <SchoolSharp />,
        permissionRequired: 'AP:SP',
        path: '/stores'
    },
    {
        title: 'Accounts',
        icon: <AccountCircleSharp />,
        permissionRequired: 'AP:AP',
        path: '/accounts'
    },
    {
        title: 'Bag Queue',
        icon: <BackpackSharp />,
        permissionRequired: 'AP:BQP',
        path: '/bags/queue'
    },
    {
        title: 'Bag Reprint',
        icon: <BackpackSharp />,
        permissionRequired: 'AP:BRP',
        path: '/bags/reprint'
    },
    {
        title: 'Departments',
        icon: <WorkspacesSharp />,
        permissionRequired: 'AP:DP',
        path: '/depts'
    },
    {
        title: 'Inventory Categories',
        icon: <CategorySharp />,
        permissionRequired: 'AP:ICP',
        path: '/ics'
    },
    {
        title: 'Global Plans',
        icon: <GroupWorkSharp />,
        permissionRequired: 'AP:GP',
        path: '/globalPlans'
    },
    {
        title: 'Discounts',
        icon: <LocalOfferSharp />,
        permissionRequired: 'AP:DCP',
        path: '/discounts'
    },
    {
        title: 'Payment Plans',
        icon: <RequestQuoteSharp />,
        permissionRequired: 'AP:PPP',
        path: '/paymentPlans'
    },
    {
        title: 'Order Form',
        icon: <ShoppingBasket />,
        permissionRequired: 'AP:OF',
        path: '/orderForm'
    },
    {
        title: 'Drivers Dashboard',
        icon: <LocalShippingSharp />,
        permissionRequired: 'AP:DTP',
        path: '/drivers'
    },
    {
        title: 'Administrative Tools',
        icon: <AdminPanelSettingsSharp />,
        permissionRequired: 'AP:ATP',
        path: '/adminTools'
    },
  ],
  administrationOperations: [
    {
        title: 'Users',
        icon: <People />,
        permissionRequired: 'AP:U',
        path: '/users'
    },
    {
        title: 'Logout',
        icon: <Logout />,
        permissionRequired: '',
        path: '/logout'
    }
  ],
}
