import { Grid, Typography } from "@mui/material";


interface PlanInfoLineProps {
    label: string;
    value: number;
}

const PlanInfoLine: React.FC<PlanInfoLineProps> = ({ label, value }) => {

    const toMoney = () => {
        if (value === undefined || isNaN(value)) {
            return "0"
        }
        if(value < 0) {
            return `-$${(value / -100).toFixed(2)}`
        }
        return `$${(value / 100).toFixed(2)}`
    }

    return (
        <Grid container>
            <Grid item xs={6}>
                <Typography>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{ textAlign: "end" }}>{toMoney()}</Typography>
            </Grid>
        </Grid>
    )
}

export default PlanInfoLine