import { useEffect, useState } from "react"
import { OverrideType, StorePlanAddon } from "../../../../apis/ersys-data"
import { FormGroup, FormControlLabel, Skeleton, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Checkbox, darken, Box, MenuItem } from "@mui/material"

interface StorePlanAddonsTabProps {
    addons: StorePlanAddon[],
    onChange: (a: StorePlanAddon) => void
    formSpacing: number
}

const StorePlanAddonsTab: React.FC<StorePlanAddonsTabProps> = ({ addons, onChange }) => {
    const [selectedAddon, setSelectedAddon] = useState<StorePlanAddon>()

    useEffect(() => {
        if (!selectedAddon) {
            return
        }

        const addon = addons.find(a => a.planId === selectedAddon!.planId)
        setSelectedAddon(addon)
    }, [addons, selectedAddon])

    const onOverrideValueChange = (a: StorePlanAddon, v: number) => {
        if (isNaN(v)) {
            v = 0
        }

        a.overrideValue = Math.max(v, 0)
        onChange(a)
    }

    const onOverrideTypeChange = (a: StorePlanAddon, v: OverrideType) => {
        a.overrideType = v
        onChange(a)
    }

    const onStoreOverrideValueChange = (a: StorePlanAddon, v: number) => {
        if (isNaN(v)) {
            v = 0
        }

        a.storeOverrideValue = Math.max(v, 0)
        onChange(a)
    }

    const onStoreOverrideTypeChange = (a: StorePlanAddon, v: OverrideType) => {
        a.storeOverrideType = v
        onChange(a)
    }

    const onEnableChange = (a: StorePlanAddon, v: boolean) => {
        a.isEnabled = v
        onChange(a)
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={200} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={20} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    const showSkeleton = () => {
        return Boolean(!addons)
    }

    const calculateCost = () => {
        if (!selectedAddon) {
            return "$0.00"
        }
        const overrideValue = selectedAddon.overrideValue ?? 0
        const overrideType = selectedAddon.overrideType ?? OverrideType.Percentage
        const cost = selectedAddon.cost ?? 0
        if (overrideType === OverrideType.Fixed) { // fixed discount
            return `$${((cost - overrideValue) / 100).toFixed(2)}`
        }
        let value = cost - (cost * (overrideValue / 100))
        return `$${(value / 100).toFixed(2)}`
    }

    const calculateStoreCost = () => {
        if (!selectedAddon) {
            return "$0.00"
        }
        const overrideValue = selectedAddon.storeOverrideValue ?? 0
        const overrideType = selectedAddon.storeOverrideType ?? OverrideType.Percentage
        const cost = selectedAddon.storeCost ?? 0
        if (overrideType === OverrideType.Fixed) { // fixed discount
            return `$${((cost - overrideValue) / 100).toFixed(2)}`
        }
        let value = cost - (cost * (overrideValue / 100))
        return `$${(value / 100).toFixed(2)}`
    }

    return (
        <Grid container alignItems="center" justifyContent="center" sx={{ height: 385 }}>
            {showSkeleton() ? renderSkeleton() :
                <>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} sx={{ height: 400 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addons.map((a) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(selectedAddon && a.planId === selectedAddon!.planId) && { backgroundColor: darken("#FFF", .1) } }}
                                            key={a.planId}
                                            onClick={() => setSelectedAddon(a)}>
                                            <TableCell>
                                                {a.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Checkbox
                                                    checked={a.isEnabled}
                                                    onChange={(e) => onEnableChange(a, e.target.checked)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: 3 }}>
                        {selectedAddon &&
                            <Box>
                                <p>Cost : {calculateCost()}</p>
                                <p>Store Cost : {calculateStoreCost()}</p>
                                <FormGroup>
                                    <p>Bundle Discount</p>
                                    <TextField
                                        size="small"
                                        type="number"
                                        value={selectedAddon.overrideValue ?? 0}
                                        onChange={e => onOverrideValueChange(selectedAddon, parseInt(e.target.value, 10))}
                                    />
                                    <TextField
                                        select
                                        value={selectedAddon.overrideType ?? OverrideType.Percentage}
                                        onChange={e => onOverrideTypeChange(selectedAddon, e.target.value as OverrideType)}
                                        size="small"
                                    >
                                        <MenuItem key="percentage" value={OverrideType.Percentage}>Percentage</MenuItem>
                                        <MenuItem key="fixed" value={OverrideType.Fixed}>Fixed</MenuItem>
                                    </TextField>

                                    <p>Store Bundle Discount</p>
                                    <TextField
                                        size="small"
                                        type="number"
                                        value={selectedAddon.storeOverrideValue ?? 0}
                                        onChange={e => onStoreOverrideValueChange(selectedAddon, parseInt(e.target.value, 10))}
                                    />
                                    <TextField
                                        select
                                        value={selectedAddon.storeOverrideType ?? OverrideType.Percentage}
                                        onChange={e => onStoreOverrideTypeChange(selectedAddon, e.target.value as OverrideType)}
                                        size="small">
                                        <MenuItem key="percentage" value={OverrideType.Percentage}>Percentage</MenuItem>
                                        <MenuItem key="fixed" value={OverrideType.Fixed}>Fixed</MenuItem>
                                    </TextField>
                                </FormGroup>
                            </Box>
                        }
                    </Grid>
                </>
            }
        </Grid>)
}

export default StorePlanAddonsTab