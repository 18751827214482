import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { setIsLoading } from "../../../../features/menus/AppMenu"
import DialogTitle from "@mui/material/DialogTitle"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useDispatch } from "react-redux"

export interface CreditCardToken {
    token: string
    brand: string
    expMonth: number
    expYear: number
    lastFour: number
}

interface CreditCardComponentProps {
    open: boolean;
    onClose: (token: CreditCardToken | undefined, error: string) => Promise<void>;
}

const CreditCardComponent: React.FC<CreditCardComponentProps> = ({
    open,
    onClose,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const checkCard = async () => {
        dispatch(setIsLoading(true))
        try {
            const result = await stripe!.createToken(elements!.getElement("card")!)
            if(result.error) {
                dispatch(setIsLoading(false))
                onClose(undefined, result.error.message ?? "")
                return
            }
            elements!.getElement("card")!.clear()
            dispatch(setIsLoading(false))
            onClose({
                token: result.token!.id,
                brand: result.token!.card!.brand,
                expMonth: result.token!.card!.exp_month,
                expYear: result.token!.card!.exp_year,
                lastFour: parseInt(result.token!.card!.last4,10),
            }, "")
        } catch(ex: any) {
            dispatch(setIsLoading(false))
            onClose(undefined, "Unexpected error. If this continues please contact IT.")
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle id="alert-dialog-title">Enter Credit Card Information</DialogTitle>
            <DialogContent>
                <CardElement />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => checkCard()} disabled={!stripe || !elements} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreditCardComponent
