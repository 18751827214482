// auth-service.jsx
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app"
import { Auth, getAuth, signInWithEmailAndPassword, User } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { config } from "../config"

const { createContext, useContext } = React

interface FirebaseProviderProps {
  firebase: FirebaseOptions
  children?: React.ReactNode
}

interface FirebaseServices {
  app: FirebaseApp
  auth: Auth
  user?: User
  isReady: boolean
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const FirebaseContext = createContext<FirebaseServices>({} as any)

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ firebase,
  children
}) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isReady, setIsReady] = useState(false)

  const app = initializeApp(config.firebase)
  const auth = getAuth(app)

  const login = async (username: string, password: string) => {
    setIsReady(false)
    await signInWithEmailAndPassword(auth, username, password)
  }

  const logout = async () => {
    setIsReady(false)
    return auth.signOut()
  }

  useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(usr => {
    setIsReady(true) // when we hit here we've loaded
    setUser(usr ?? undefined)
  })
  return () => {
    unsubscribe()
  }
  }, [auth])


  const value: FirebaseServices = {
    app: app,
    auth:  auth,
    user: user,
    isReady: isReady,
    login: login,
    logout: logout
  }

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => {
  return useContext(FirebaseContext)
}