import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { UserCreationRequest } from "../../../apis/ersys-data"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import FormInputText from "../../../components/forms/FormInputText"
import { useForm } from "react-hook-form"

interface UserCreationFormInput {
  email: string
  displayName: string
}

const defaults: UserCreationFormInput = {
  email: "",
  displayName: "",
}

interface UserCreationModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (req: UserCreationRequest) => Promise<void>
}

const UserCreationModal: React.FC<UserCreationModalProps> = ({
  open,
  onCreate,
  onClose,
}) => {
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control } =
    useForm<UserCreationFormInput>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })

  const handleCreate = (input: UserCreationFormInput) => {
    onCreate({ displayName: input.displayName, email: input.email })
  }

  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Create a new User</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormInputText
                spacing={formSpacing}
                name={"displayName"}
                control={control}
                label={"Display Name"}
                rules={{ required: "Required Field", maxLength: 100 }}
              />

              <FormInputText
                spacing={formSpacing}
                name={"email"}
                control={control}
                label={"Email"}
                rules={{ required: "Required Field", maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleCreate)} autoFocus>
          Ok
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserCreationModal
