import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { DialogContentText } from "@mui/material"

interface OrderCompleteModalProps {
    orderId: string
    accountId: string
    open: boolean
    onClose: () => Promise<void>
}

const OrderCompleteModal: React.FC<OrderCompleteModalProps> = ({
    orderId,
    accountId,
    open,
    onClose,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle id="alert-dialog-title">Order #{orderId} Successfully Completed</DialogTitle>
            <DialogContent>
                <DialogContentText>Customer ({accountId}) should receive Order Confirmation email.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OrderCompleteModal
