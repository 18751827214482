import { Box, ButtonGroup, Fab, FormGroup, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { AccountBag } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { AddSharp, Print, PrintDisabled, ToggleOff, ToggleOn } from "@mui/icons-material"

interface AccountBagsTabProps {
    formSpacing: number
}

const AccountBagsTab: React.FC<AccountBagsTabProps> = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [accountBags, setAccountBags] = useState<AccountBag[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const accountId = parseInt(params.id!, 10)
                var accountPlansResp = await ersysDataApiServices.accountsService.accountsListBags(accountId)
                setAccountBags(accountPlansResp.data.bags)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting bags data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setAccountBags, ersysDataApiServices.accountsService, params.id])

    const showSkeleton = () => {
        return accountBags.length < 1 && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <Skeleton variant='rectangular' width={200} height={100} key={`skele-${i}`} sx={{ margin: 1 }} />
                ))}
            </FormGroup>
        )
    }

    const reloadBags = async () => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            var accountPlansResp = await ersysDataApiServices.accountsService.accountsListBags(accountId)
            setAccountBags(accountPlansResp.data.bags)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting bags. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }

    }

    const createBag = async () => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsAddBag(accountId)
            await reloadBags()
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while adding bag. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
            setLoading(false)
        } 
    }

    const toggleBag = async (id: number, current: boolean) => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsToggleBag(accountId, id, { isActive: !current })
            await reloadBags()
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while toggling bag. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
            setLoading(false)
        } 
    }

    const printBag = async (id: number) => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsAddToPrintQueue(accountId, id)
            await reloadBags()
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while adding bag to printer. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
            setLoading(false)
        } 
    }

    const removeBagFromPrintQueue = async (id: number) => {
        setLoading(true)
        try {
            const accountId = parseInt(params.id!, 10)
            await ersysDataApiServices.accountsService.accountsRemoveFromPrintQueue(accountId, id)
            await reloadBags()
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while removing bag from print queue. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
            setLoading(false)
        }
    }

    return (
        <Box>
            {showSkeleton() ? renderSkeleton() :
                <Grid container alignItems="center" justifyContent="center">
                    <Tooltip title="Add Bag">
                        <Fab
                            onClick={() => createBag()}
                            color="primary"
                            aria-label="add"
                            sx={{ position: 'absolute', bottom: 25, right: 25 }}>
                            <AddSharp />
                        </Fab>
                    </Tooltip>
                    <TableContainer sx={{ minWidth: 650, maxHeight: 550 }} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accountBags.map((bag) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={bag.id}>
                                        <TableCell>
                                            <Typography>{bag.bagNumber}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Tooltip title="Toggle Active">
                                                    <IconButton onClick={() => toggleBag(bag.id, bag.isActive)}>
                                                        { bag.isActive && <ToggleOn color="success"></ToggleOn> }
                                                        { !bag.isActive && <ToggleOff color="error"></ToggleOff> }
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Add to Print Queue">
                                                    <IconButton onClick={() => printBag(bag.id)}>
                                                        <Print></Print>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Remove from Print Queue">
                                                    <IconButton onClick={() => removeBagFromPrintQueue(bag.id)}>
                                                        <PrintDisabled></PrintDisabled>
                                                    </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Box>
    )
}

export default AccountBagsTab