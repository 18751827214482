import { Button, Container, FormGroup, Paper } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { CreditCardSharp, PreviewSharp, ReceiptSharp } from "@mui/icons-material"
import { OnAccountChargeRequest, PostTransactionRequest } from "../../apis/ersys-data"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"
import FormInputDatePicker from "../forms/FormInputDatePicker"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"

const formDefaults: OnAccountChargeRequest = {
    periodEnd: dayjs().format(),
    periodStart: dayjs().format()
}

const AdministrativeOnAccountChargingTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [id, setId] = useState<string>("")
    const [formSpacing] = useState(2)
    const { handleSubmit, control, reset } =
        useForm<OnAccountChargeRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const chargeCards = async (req: OnAccountChargeRequest) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkChargeAccounts(req)
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while rolling up accounts. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const downloadResults = async (id: string) => {
        setLoading(true)
        try {
            setShowWaitingModal(false)
            const response = await ersysDataApiServices.bulkService.bulkGetChargeResults(id, {
                responseType: 'blob'
            })

            const pdfBlob = new Blob([response.data], { type: "application/pdf" })
            const url = window.URL.createObjectURL(pdfBlob)

            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `charges_results`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting results. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    const previewTransactions = async (req: OnAccountChargeRequest) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkDownloadOnAccountChargePreview(req, {
                responseType: 'blob'
            })

            const pdfBlob = new Blob([response.data], { type: "application/pdf" })
            const url = window.URL.createObjectURL(pdfBlob)

            const tempLink = document.createElement("a")
            tempLink.href = url
            tempLink.setAttribute("download", `charges_preview_${dayjs(req.periodStart).unix()}_${dayjs(req.periodEnd).unix()}`)
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while getting preview. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal
                id={id}
                onCompleted={() => downloadResults(id)}
                onClose={() => setShowWaitingModal(false)}
                open={showWaitingModal}
                title="Charging Cards"
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
                <FormGroup>
                    <FormInputDatePicker
                        name="periodStart"
                        control={control}
                        label="Period Start"
                        spacing={formSpacing}
                        rules={{ required: true }}
                    />
                    <FormInputDatePicker
                        name="periodEnd"
                        control={control}
                        label="Period End"
                        spacing={formSpacing}
                        rules={{ required: true }}
                    />

                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        onClick={handleSubmit(previewTransactions)}
                        endIcon={<PreviewSharp />}
                    >
                        Preview
                    </Button>
                    <Button
                        sx={{ marginLeft: 3}}
                        variant="contained"
                        color="error"
                        onClick={handleSubmit(chargeCards)}
                        endIcon={<CreditCardSharp />}
                    >
                        Charge Cards
                    </Button>
                </div>
            </Paper>
        </Container>
    )
}

export default AdministrativeOnAccountChargingTab