import './EditDiscountPage.scss'
import AppMenu from '../../../components/AppMenu'
import { Button, Container, Skeleton } from '@mui/material'
import { useDispatch } from "react-redux"
import { useErsysDataApi } from '../../../components/ErsysDataApiProvider'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../../features/menus/AppMenu'
import { showError } from '../../../features/errors/Errors'
import { Discount, MinimalGlobalPlan, MinimalStore, PatchAction } from '../../../apis/ersys-data'
import { useParams } from 'react-router-dom'
import TabViewComponent from '../../../components/tabs/TabViewComponent'
import { SaveSharp } from '@mui/icons-material'
import { useFirebase } from '../../../components/FirebaseProvider'
import { useForm, useFormState } from 'react-hook-form'
import DiscountGeneralTab from '../../../components/discounts/DiscountGeneralTab'

const formDefaults: Discount = {
    code: '',
    description: '',
    isEnabled: false,
    isPercentage: false,
    isPercentOfTotalOrder: false,
    value: 0
}

function EditDiscountPage() {
    const ersysDataApiServices = useErsysDataApi()
    const params = useParams()
    const [discount, setDiscount] = useState<Discount>()
    const [stores, setStores] = useState<MinimalStore[]>([])
    const [plans, setPlans] = useState<MinimalGlobalPlan[]>([])
    const [isExtraDataLoaded, setIsExtraDataLoaded] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [formSpacing] = useState(2)
    const { handleSubmit, reset, control, } =
        useForm<Discount>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty, dirtyFields } = useFormState({ control })
    const { isReady } = useFirebase()

    const showSkeleton = (): boolean => {
        return !discount || discount.code === '' || !isExtraDataLoaded
    }

    const getTabNames = (): string[] => {
        return [
            "General"
        ]
    }

    const getTabComponents = (): JSX.Element[] => {
        return [
            <DiscountGeneralTab control={control} formSpacing={formSpacing} showSkeleton={showSkeleton} plans={plans} stores={stores}/>
        ]
    }

    const allowSave = () => {
        return isDirty 
    }

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        if(!isReady) {
            return
        }
        const fetch = async () => {
            try {
                const storesResp = await ersysDataApiServices.storesService.storesList()
                setStores(storesResp.data.stores)

                const plansResp = await ersysDataApiServices.globalPlansService.globalPlansList()
                setPlans(plansResp.data.plans)
                setIsExtraDataLoaded(true)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting store/plans data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            }
        }
        fetch()
    }, [isReady, dispatch, ersysDataApiServices.storesService, ersysDataApiServices.globalPlansService, setStores, setPlans])

    useEffect(() => {
        if(!isReady) {
            return
        }
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                const resp = await ersysDataApiServices.discountService.discountsGet(params.id!)
                setDiscount(resp.data)
                reset(resp.data)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting discount data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setDiscount, ersysDataApiServices.discountService, params.id, isReady, reset])

    
    const Save = async (s: Discount) => {
        console.log(s)
        const patchSet: PatchAction[] = []
        setLoading(true)
        if (isDirty) {
            const fieldKeys = Object.keys(dirtyFields) as Array<keyof Discount>
            for (const fieldName of fieldKeys) {
                if (dirtyFields[fieldName]) {
                    var val = s[fieldName]
                    if((fieldName === "restrictedToPlan" || fieldName === "restrictedToStore") && (!Boolean(val) || val === 0)) {
                        val = null
                    }
                    
                    patchSet.push({
                        operationType: "Replace",
                        to: fieldName,
                        value: val,
                    })
                }
            }
        }

        try {
            // discount update
            if (patchSet.length > 0) {
                const resp = await ersysDataApiServices.discountService.discountsUpdate(s.code, { actions: patchSet })
                setDiscount(resp.data)
                reset(resp.data)
            }
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while patching discount data. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }


    if (!isReady) {
        return <AppMenu title="Department">
            <Skeleton variant="rectangular" height={800} sx={{width: '100%'}}/>
        </AppMenu>
    }

    return (
        <AppMenu title={`Discount Code: ${discount?.code ?? ""}`}>
            <Container maxWidth="md">
                <TabViewComponent name='editDiscount' tabNames={getTabNames()} tabs={getTabComponents()} />
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!allowSave()}
                        onClick={handleSubmit(Save)}
                        endIcon={<SaveSharp />}
                    >
                        Save Changes
                    </Button>
                </div>
            </Container>
        </AppMenu>
    )
}

export default EditDiscountPage