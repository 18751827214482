import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Account } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"

interface AccountBillingTabProps {
    control: Control<Account, any>
    showSkeleton: () => boolean
    formSpacing: number
}

const AccountBillingTab: React.FC<AccountBillingTabProps> = ({ control, showSkeleton, formSpacing }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingFirstName"}
                            control={control}
                            label={"Primary First Name"}
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingLastName"}
                            control={control}
                            label={"Primary Last Name"}
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingEmail"}
                            control={control}
                            label={"Primary Email"}
                            rules={{ required: "Required Field", maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i  }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingPhone"}
                            control={control}
                            label={"Phone Number"}
                            rules={{ maxLength: 25 }}
                        />
                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingAddressLineOne"}
                            control={control}
                            label={"Address Line One"}
                            rules={{ maxLength: 90 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingAddressLineTwo"}
                            control={control}
                            label={"Address Line Two"}
                            rules={{ maxLength: 90 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingCountry"}
                            control={control}
                            label={"Country"}
                            rules={{ maxLength: 90 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingCity"}
                            control={control}
                            label={"City"}
                            rules={{ maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingState"}
                            control={control}
                            label={"State"}
                            rules={{ maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"billingPostal"}
                            control={control}
                            label={"Postal"}
                            rules={{ maxLength: 12 }}
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default AccountBillingTab
